import React from 'react';

export const DamagesAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            name: 'damage',
            path: [
                '/damages/:damageId/:sectionId',
                '/damages/:damageId'
            ],
            component: React.lazy(() => import('./damage/DamageApp'))
        },
        {
            path: '/damages',
            component: React.lazy(() => import('./DamagesApp'))
        },
    ]
};
