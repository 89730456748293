import * as Actions from '../actions';

const initialState = {
    loading: false,
    success: false,
    error: false,
    reset: {
        email: '',
        loading: false,
        success: false,
        error: false
    }
};

const login = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_START: {
            return {
                ...state,
                loading: true,
                success: false,
                error: false
            };
        }
        case Actions.LOGIN_END: {
            return {
                ...state,
                loading: false,
                success: action.success,
                error: action.error
            };
        }
        case Actions.RESET_EMAIL_SENT: {
            return {
                ...state,
                resetEmail: action.email
            };
        }
        case Actions.RESET_PASSWORD_START: {
            return {
                ...state,
                reset: {
                    ...state.reset,
                    loading: true,
                    success: false,
                    error: false
                },
            };
        }
        case Actions.RESET_PASSWORD_END: {
            return {
                ...state,
                reset: {
                    ...state.reset,
                    loading: false,
                    success: action.success,
                    error: action.error
                },
            };
        }
        default: {
            return state
        }
    }
};

export default login;
