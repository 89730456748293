import {handleCatch} from 'app/store/actions';
import axios from 'axios';
import {getProduction} from './production.actions';

export const PRODUCTION_GET_PROJECTS = '[PRODUCTION APP] GET PROJECTS';
export const PRODUCTION_GET_PROJECT = '[PRODUCTION APP] GET PROJECT';
export const PRODUCTION_GET_PRODUCTIONS = '[PRODUCTION APP] GET PROJECT PRODUCTIONS';
export const PRODUCTION_GET_TRACES = '[PRODUCTION APP] GET PRODUCTION TRACES';
export const PRODUCTION_GET_CREWS = '[PRODUCTION APP] GET CREWS';
export const PRODUCTION_GET_CREW = '[PRODUCTION APP] GET CREW';
export const PRODUCTION_GET_TASK = '[PRODUCTION APP] GET TASK';
export const PRODUCTION_GET_MEMBER = '[PRODUCTION APP] GET MEMBER';
export const PRODUCTION_ADD = '[PRODUCTION APP] ADD PRODUCTION';
export const PRODUCTION_ADD_ITEMS = '[PRODUCTION APP] ADD PRODUCTION ITEMS';
export const PRODUCTION_UPDATE_ITEM = '[PRODUCTION APP] UPDATE PRODUCTION ITEM';
export const PRODUCTION_UPDATE_ITEMS = '[PRODUCTION APP] UPDATE PRODUCTION ITEMS';
export const PRODUCTION_DELETE_ITEM = '[PRODUCTION APP] DELETE PRODUCTION ITEM';
export const PRODUCTION_PROJECT_SETUP_OPEN_DIALOG = '[PRODUCTION APP] OPEN PRODUCTION REJECT DIALOG';
export const PRODUCTION_PROJECT_SETUP_CLOSE_DIALOG = '[PRODUCTION APP] CLOSE PRODUCTION REJECT DIALOG';

export const getProjectProductions = (projectId) => (dispatch) =>
  axios.get(`/projects/${projectId}/productions`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_PRODUCTIONS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getSiblingProductions = (productionId) => (dispatch) =>
  axios.get(`/production/${productionId}/siblings`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_PRODUCTIONS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getProductionTraces = (productionId) => (dispatch) =>
  axios.get(`/production/${productionId}/traces`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_TRACES,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const addProduction = (projectId, crewId, taskId, date, items, texts) => (dispatch) =>
  axios.post(`production/project/${projectId}`, {crewId, date, items, texts, taskId}).then(
    (response) =>
      Promise.all([
        dispatch({
          type: PRODUCTION_ADD,
          payload: response.data,
        }),
        dispatch(getProduction(response.data.id)),
      ]).then(() => response.data), // return new production
    handleCatch(dispatch),
  );

export const addProductionItems = (productionId, items, fetchActivity = false) => (dispatch) =>
  axios.post(`/production/${productionId}/items`, items).then(
    (response) =>
      Promise.all([
        dispatch({
          type: PRODUCTION_ADD_ITEMS,
          payload: response.data,
        }),
        dispatch(getProduction(productionId)),
      ]).then(() => response),
    handleCatch(dispatch),
  );

export const updateProductionItems = (productionId, items) => (dispatch) =>
  axios.patch(`/production/${productionId}/items`, items).then(
    (response) =>
      Promise.all([
        dispatch({
          type: PRODUCTION_UPDATE_ITEMS,
          payload: response.data,
        }),
        dispatch(getProduction(productionId)),
      ]).then(() => response),
    handleCatch(dispatch),
  );

export const updateProductionItem = (itemId, item) => (dispatch) =>
  axios.patch(`/production/item/${itemId}`, item).then(
    (response) =>
      Promise.all([
        dispatch({
          type: PRODUCTION_UPDATE_ITEM,
          payload: response.data,
        }),
        dispatch(getProduction(response.data.productionId)),
      ]).then(() => response),
    handleCatch(dispatch),
  );

export const deleteProductionItem = (itemId, productionId) => (dispatch) =>
  axios.delete(`/production/item/${itemId}`).then(
    () =>
      Promise.all([
        dispatch({
          type: PRODUCTION_DELETE_ITEM,
          payload: itemId,
        }),
        dispatch(getProduction(productionId)),
      ]),
    handleCatch(dispatch),
  );

export const getProjects = (params) => (dispatch) =>
  axios.get('/projects', {params}).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_PROJECTS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getProject = (projectId) => (dispatch) =>
  axios.get(`/projects/${projectId}`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_PROJECT,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getCrews = () => (dispatch) =>
  axios.get(`/production/crews`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_CREWS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getCrew = (crewId) => (dispatch) =>
  axios.get(`/crews/${crewId}`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_CREW,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getMember = (memberId) => (dispatch) =>
  axios.get(`/memberships/${memberId}`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_MEMBER,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getTask = (taskId) => (dispatch) =>
  axios.get(`/tasks/${taskId}`).then(
    (response) =>
      dispatch({
        type: PRODUCTION_GET_TASK,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const openProductionProjectSetupDialog = (task = null) => ({
  type: PRODUCTION_PROJECT_SETUP_OPEN_DIALOG,
  payload: task,
});

export const closeProductionProjectSetupDialog = () => ({
  type: PRODUCTION_PROJECT_SETUP_CLOSE_DIALOG,
});
