import React from 'react';
import {User} from '../../../../@rubix/User';

export const ForgotPasswordPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [
    {
      require: () => !User.hasRole('Platform/Member'),
      denied: '/',
    },
  ],
  routes: [
    {
      path: '/forgot-password/requested',
      component: React.lazy(() => import('./ForgotPasswordRequestedPage')),
    },
    {
      path: '/forgot-password',
      component: React.lazy(() => import('./ForgotPasswordPage')),
    },
  ],
};
