import {GET_MEMBERS} from '../../../members/store/actions';
import {GET_ALL_ROLES, GET_ASSIGNABLE_ROLES} from '../../../roles/store/actions';
import * as Actions from '../actions';

const initialState = {
  entities: null,
  crew: null,
  members: [],
  roles: {
    all: null,
    assignable: null,
  },
  routeParams: {},
  crewDialog: {
    type: 'new',
    props: {
      open: false,
    },
    data: null,
  },
};

const crews = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CREWS: {
      return {
        ...state,
        entities: action.payload,
      };
    }
    case GET_ALL_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          all: Object.keys(action.payload).map(roleId => {
            return {id: roleId, ...action.payload[roleId]};
          }),
        },
      };
    }
    case GET_ASSIGNABLE_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          assignable: Object.keys(action.payload).map(roleId => {
            return {id: roleId, ...action.payload[roleId]};
          }),
        },
      };
    }
    case GET_MEMBERS: {
      return {
        ...state,
        members: action.payload,
      };
    }
    case Actions.OPEN_NEW_CREW_DIALOG: {
      return {
        ...state,
        crewDialog: {
          ...initialState.crewDialog,
          type: 'new',
          props: {
            open: true,
          },
        },
      };
    }
    case Actions.CLOSE_NEW_CREW_DIALOG: {
      return {
        ...state,
        crewDialog: {
          ...initialState.crewDialog,
        },
      };
    }
    case Actions.OPEN_EDIT_CREW_DIALOG: {
      return {
        ...state,
        crewDialog: {
          type: 'edit',
          props: {
            open: true,
          },
          data: action.data,
        },
      };
    }
    case Actions.CLOSE_EDIT_CREW_DIALOG: {
      return {
        ...state,
        crewDialog: {
          ...initialState.crewDialog,
        },
      };
    }
    case Actions.GET_CREW: {
      return {
        ...state,
        crew: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};


export default crews;
