import {TextField} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';

function Suggester(props) {
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOptions = props.getOptions;

  useEffect(() => {
    if (!inputValue.length) {
      setOptions([]);
      return undefined;
    }
    setLoading(true);

    getOptions(inputValue, (results) => {
      setOptions(results || []);
      setLoading(false);
    });
  }, [inputValue, getOptions]);

  const handleSelect = (event, option) => {
    if (!option) {
      setInputValue('');
    }
    if (props.onChange) {
      props.onChange(option);
    }
    setSelected(option);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Autocomplete
      id={props.id}
      value={props.value}
      onChange={handleSelect}
      options={options}
      disabled={props.disabled}
      includeInputInList
      freeSolo
      autoComplete={true}
      loading={loading}
      className={props.className}
      getOptionLabel={props.getOptionLabel}
      renderOption={props.renderOption}
      filterOptions={props.filterOptions}
      renderInput={(params) => (
        <TextField
          {...{...params, inputProps: {...params.inputProps, autoComplete: _.uniqueId('suggester')}}}
          label={props.label}
          variant="outlined"
          fullWidth
          autoFocus={props.autoFocus}
          required={props.required}
          onChange={handleChange}
          error={props.error || (props.errorOnInvalid && !loading && inputValue.length > 0 && !selected)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default React.memo(Suggester);
