import axios from 'axios';
import {handleCatch, showSuccessMessage} from '../../../../../store/actions';
import {getInvoices, getProductions, ADD_INVOICE} from './invoices.actions';

export const GET_INVOICE = '[INVOICE APP] GET INVOICE';
export const SEND_INVOICE = '[INVOICE APP] SEND INVOICE';
export const GET_INVOICE_ACTIVITY = '[INVOICE APP] GET INVOICE ACTIVITY';
export const APPROVE_INVOICE = '[INVOICE APP] APPROVE INVOICE';
export const REJECT_INVOICE = '[INVOICE APP] REJECT INVOICE';
export const PAID_INVOICE = '[INVOICE APP] PAID INVOICE';
export const CANCELLED_INVOICE = '[INVOICE APP] CANCELLED INVOICE';
export const OPEN_INVOICE_REJECT_DIALOG = '[INVOICE APP] OPEN INVOICE REJECT DIALOG';
export const CLOSE_INVOICE_REJECT_DIALOG = '[INVOICE APP] CLOSE INVOICE REJECT DIALOG';
export const CLEANUP_INVOICE = '[INVOICE APP] CLEANUP INVOICE';
export const OPEN_INVOICE_CANCEL_DIALOG = '[INVOICE APP] OPEN INVOICE CANCEL DIALOG';
export const CLOSE_INVOICE_CANCEL_DIALOG = '[INVOICE APP] CLOSE INVOICE CANCEL DIALOG';
export const OPEN_INVOICE_PAID_DIALOG = '[INVOICE APP] OPEN INVOICE PAID DIALOG';
export const CLOSE_INVOICE_PAID_DIALOG = '[INVOICE APP] CLOSE INVOICE PAID DIALOG';
export const SAVE_INVOICE_TRANSACTION = '[INVOICE APP] SAVE INVOICE TRANSACTION';
export const INVOICE_GET_ITEMS = '[INVOICE APP] GET INVOICE ITEMS';

export const getInvoice = (invoiceId) => (dispatch) =>
  axios.get(`/invoices/${invoiceId}`).then((response) =>
    Promise.all([
      dispatch({
        type: GET_INVOICE,
        payload: response.data,
      }),
      handleCatch(dispatch),
    ]).then(),
  );

export const generateInvoice =
  ({project, dueDate, retainage, billedProductions, weekEndingDate, notes}) =>
  (dispatch) =>
    axios
      .post(`/invoices/${project.companyId}`, {
        projectId: project.id,
        clientId: project.clientId,
        dueDate,
        status: 'draft',
        retainage,
        weekEndingDate,
        notes,
        productions: billedProductions.map((p) => ({
          id: p.id,
          items: p.items.map((i) => ({
            id: i.id,
            quantity: i.quantity,
          })),
        })),
      })
      .then((response) =>
        Promise.all([dispatch(showSuccessMessage({message: 'Invoice Created'})), dispatch(getInvoices())]).then(
          (resp) => {
            dispatch({
              type: ADD_INVOICE,
              payload: response.data,
            });
            return response.data.id;
          },
          handleCatch(dispatch),
        ),
      );

export const updateInvoice =
  ({id, dueDate, retainage, billedProductions, weekEndingDate, notes}) =>
  (dispatch) =>
    axios
      .patch(`/invoices/${id}`, {
        dueDate,
        retainage,
        weekEndingDate,
        notes,
        productions: billedProductions.map((p) => ({
          id: p.id,
          items: p.items.map((i) => ({
            id: i.id,
            quantity: i.quantity,
          })),
        })),
      })
      .then((response) => {
        dispatch(getInvoice(id));
        return response;
      }, handleCatch(dispatch));

const markInvoiceAsSent = (id) => (dispatch) =>
  axios.patch(`/invoices/${id}/send`).then((response) =>
    Promise.all([
      dispatch(getInvoices()),
      dispatch(showSuccessMessage({message: 'Invoice Sent'})),
      handleCatch(dispatch),
    ]).then(() =>
      dispatch({
        type: SEND_INVOICE,
        payload: response.data,
      }),
    ),
  );

export const sendInvoice =
  ({id, project, dueDate, retainage, billedProductions, weekEndingDate, notes}) =>
  (dispatch) => {
    if (id) {
      Promise.all([dispatch(updateInvoice({id, dueDate, retainage, billedProductions, weekEndingDate, notes}))]).then(
        (res) => dispatch(markInvoiceAsSent(id)),
      );
    } else {
      Promise.all([
        dispatch(generateInvoice({project, dueDate, retainage, billedProductions, weekEndingDate, notes})),
      ]).then((invoiceId) => dispatch(markInvoiceAsSent(invoiceId)));
    }
  };

export const removeProductionFromExistingInvoice = (production, invoiceId, projectId) => (dispatch) => {
  axios
    .patch(`/invoices/${invoiceId}/production/${production.id}/remove`)
    .then((response) =>
      Promise.all([
        dispatch(getProductions(projectId, invoiceId)),
        dispatch(showSuccessMessage({message: 'Production was removed from invoice'})),
        handleCatch(dispatch),
      ]),
    );
};

export const addProductionToExistingInvoice = (production, invoiceId, projectId) => (dispatch) => {
  axios
    .patch(`/invoices/${invoiceId}/production/${production.id}/add`)
    .then((response) =>
      Promise.all([
        dispatch(getProductions(projectId, invoiceId)),
        dispatch(showSuccessMessage({message: 'Production added to invoice'})),
        handleCatch(dispatch),
      ]),
    );
};

export const addInvoiceComment = (invoiceId, data) => (dispatch) =>
  axios.post(`/invoices/${invoiceId}/comments`, data).then((response) => {
    dispatch(getInvoiceActivity(invoiceId));
    return response;
  }, handleCatch(dispatch));

export const getInvoiceActivity = (invoiceId) => (dispatch) =>
  axios.get(`/invoices/${invoiceId}/activity`).then(
    (response) =>
      dispatch({
        type: GET_INVOICE_ACTIVITY,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const approveInvoice = (invoiceId) => (dispatch) =>
  axios.post(`/invoices/${invoiceId}/approve`).then((response) => {
    dispatch({
      type: APPROVE_INVOICE,
    });
    dispatch(getInvoice(invoiceId));
    dispatch(getInvoiceActivity(invoiceId));
    dispatch(showSuccessMessage({message: 'Invoice approved'}));
    return response;
  }, handleCatch(dispatch));

export const rejectInvoice = (invoiceId, reason) => (dispatch) =>
  axios.post(`/invoices/${invoiceId}/reject`, {reason}).then((response) => {
    dispatch({
      type: REJECT_INVOICE,
    });
    dispatch(showSuccessMessage({message: 'Invoice rejected'}));
    dispatch(getInvoice(invoiceId));
    dispatch(getInvoiceActivity(invoiceId));
    dispatch(closeInvoiceRejectDialog());
    return response;
  }, handleCatch(dispatch));

export const invoicePaid = (invoiceId, status) => (dispatch) =>
  axios.post(`/invoices/${invoiceId}/paid/${status}`).then((response) => {
    dispatch({
      type: PAID_INVOICE,
    });
    if (status === 'paid') {
      dispatch(showSuccessMessage({message: 'Invoice has been paid'}));
    } else if (status === 'partially_paid') {
      dispatch(showSuccessMessage({message: 'Invoice has been partially paid'}));
    }
    dispatch(getInvoice(invoiceId));
    dispatch(getInvoiceActivity(invoiceId));
    return response;
  }, handleCatch(dispatch));

export const invoiceCancelled = (invoiceId, reason) => (dispatch) =>
  axios.post(`/invoices/${invoiceId}/cancelled`, {reason}).then((response) => {
    dispatch({
      type: CANCELLED_INVOICE,
    });
    dispatch(showSuccessMessage({message: 'Invoice was marked as cancelled'}));
    dispatch(getInvoice(invoiceId));
    dispatch(getInvoiceActivity(invoiceId));
    dispatch(closeInvoiceCancelDialog());
    return response;
  }, handleCatch(dispatch));

export const closeInvoiceRejectDialog = () => ({
  type: CLOSE_INVOICE_REJECT_DIALOG,
});

export const openInvoiceRejectDialog = (invoice) => ({
  type: OPEN_INVOICE_REJECT_DIALOG,
  invoice,
});

export const cleanupInvoice = () => ({
  type: CLEANUP_INVOICE,
});

export const openInvoiceCancelDialog = (invoice) => ({
  type: OPEN_INVOICE_CANCEL_DIALOG,
  invoice,
});

export const closeInvoiceCancelDialog = () => ({
  type: CLOSE_INVOICE_CANCEL_DIALOG,
});

export const openInvoicePaidDialog = (invoice, filters = {}) => ({
  type: OPEN_INVOICE_PAID_DIALOG,
  invoice,
  filters,
});

export const closeInvoicePaidDialog = () => ({
  type: CLOSE_INVOICE_PAID_DIALOG,
});

export const saveInvoiceTransaction = (invoiceId, data) => (dispatch) =>
  axios.post(`/invoices/${invoiceId}/transaction`, data).then((response) => {
    dispatch({
      type: SAVE_INVOICE_TRANSACTION,
    });
    dispatch(closeInvoicePaidDialog());
    dispatch(getInvoice(invoiceId));
    dispatch(getInvoiceActivity(invoiceId));
    return response;
  }, handleCatch(dispatch));

export const getInvoiceItems = (projectId, productions) => (dispatch) => {
  axios.post(`/projects/${projectId}/invoice/items`, {productions}).then(
    (response) =>
      dispatch({
        type: INVOICE_GET_ITEMS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );
};
