import {combineReducers} from 'redux';
import logs from './logs.reducer';
import log from './log.reducer';
import map from './map.reducer';

const reducer = combineReducers({
    logs,
    log,
    map,
});

export default reducer;
