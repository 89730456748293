import React from "react";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    toolbarInput: {
        "& input": {
            padding: "10px 14px"
        }
    },
    listItem: {
        padding: "5px",
        "&:hover": {
            backgroundColor: "#e5e5e5"
        }
    }
}));

function getAddressType(type) {
    // change google types to match our naming format.
    const formatted = {
        administrative_area_level_1: "state",
        locality: "city",
        route: "address",
        postal_code: "zip"
    };

    // return formatted type OR just type (return type only if we are not formatting what google sends as type)
    return formatted[type] || type;
}

const placeProcessing = async data => {
    const place = data[0];
    let value;
    let adressForm = {
        state: "",
        country: "",
        city: "",
        zip: "",
        address: "",
        coordinates: [0, 0]
    };

    await place.address_components.forEach((component, index) => {
        const addressType = getAddressType(
            place.address_components[index].types[0]
        );

        // get short_name for country/ state
        ["country", "state"].indexOf(addressType) > -1
            ? (value = place.address_components[index].short_name)
            : (value = place.address_components[index].long_name);

        // concatenate street_number + route
        value =
            addressType === "address"
                ? place.address_components[0]["long_name"] +
                  " " +
                  place.address_components[1]["long_name"]
                : value;

        adressForm[addressType] = value;
    });

    // include lat/long
    adressForm.coordinates = await getLatLng(place);

    return adressForm;
};

function GoogleAddressAutocomplete(props) {
    const classes = useStyles();
    const [searchAddress, setSearchAddress] = React.useState("");

    const handleChange = value => {
        setSearchAddress(value);
    };

    const handlePlace = async place => {
        await geocodeByAddress(place).then(result => {
            return placeProcessing(result).then(processedPlace => {
                setSearchAddress(place);
                if (props.onSelectLocation) {
                    props.onSelectLocation(processedPlace);
                }
            });
        });
    };

    return (
        <PlacesAutocomplete
            value={searchAddress}
            onChange={handleChange}
            onSelect={handlePlace}
            debounce={700}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <div className="relative">
                    <TextField
                        {...getInputProps({
                            placeholder: "Enter Location",
                            className: `${
                                props.toolbar ? classes.toolbarInput : ""
                            } location-search-input`
                        })}
                        variant="outlined"
                        required
                        fullWidth
                        disabled={props.disabled}
                    />
                    <Grid
                        className={`absolute ${props.toolbar ? "z-999" : ""}`}
                        container
                        alignItems="center"
                    >
                        {loading && <div>Loading...</div>}
                        {suggestions.length ? (
                            <div
                                role="presentation"
                                className="MuiAutocomplete-popper w-full MuiPaper-root MuiAutocomplete-paper MuiPaper-elevation1 MuiPaper-rounded"
                                x-placement="bottom"
                            >
                                <ul className="MuiAutocomplete-listbox">
                                    {suggestions.map(suggestion => {
                                        return (
                                            <li
                                                {...getSuggestionItemProps(
                                                    suggestion
                                                )}
                                                className={`MuiAutocomplete-option cursor-pointer ${classes.listItem}`}
                                                key={suggestion.placeId}
                                            >
                                                <div className="MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-center">
                                                    <div className="MuiGrid-root MuiGrid-item">
                                                        <LocationOnIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                        />
                                                    </div>
                                                    <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true">
                                                        {
                                                            suggestion
                                                                .formattedSuggestion
                                                                .mainText
                                                        }
                                                        <p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorTextSecondary">
                                                            {
                                                                suggestion
                                                                    .formattedSuggestion
                                                                    .secondaryText
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ) : (
                            ""
                        )}
                    </Grid>
                </div>
            )}
        </PlacesAutocomplete>
    );
}

export default GoogleAddressAutocomplete;
