import React from "react";

export const MapAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        // {
        //     path: "/map/onboarding/:id",
        //     component: React.lazy(() => import("./MapBoxOnboarding"))
        // },
        {
            path: "/map",
            component: React.lazy(() => import("./MapApp"))
        }
    ]
};
