import {GET_ALL_ROLES, GET_ASSIGNABLE_ROLES} from '../../../roles/store/actions';
import * as Actions from '../actions';

const initialState = {
  entities: null,
  member: null,
  roles: {
    all: null,
    assignable: null,
  },
  routeParams: {},
  memberDialog: {
    type: 'new',
    props: {
      open: false,
    },
    data: null,
  },
};

const members = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MEMBERS: {
      return {
        ...state,
        entities: action.payload,
      };
    }
    case GET_ALL_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          all: action.payload,
        },
      };
    }
    case GET_ASSIGNABLE_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          assignable: action.payload,
        },
      };
    }
    case Actions.OPEN_NEW_MEMBER_DIALOG: {
      return {
        ...state,
        memberDialog: {
          type: 'new',
          props: {
            open: true,
          },
          data: null,
        },
      };
    }
    case Actions.CLOSE_NEW_MEMBER_DIALOG: {
      return {
        ...state,
        memberDialog: {
          type: 'new',
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case Actions.OPEN_EDIT_MEMBER_DIALOG: {
      return {
        ...state,
        memberDialog: {
          type: 'edit',
          props: {
            open: true,
          },
          data: action.data,
        },
      };
    }
    case Actions.CLOSE_EDIT_MEMBER_DIALOG: {
      return {
        ...state,
        memberDialog: {
          type: 'edit',
          props: {
            open: false,
          },
          data: null,
        },
      };
    }

    case Actions.GET_MEMBER: {
      return {
        ...state,
        member: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default members;
