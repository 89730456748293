import MomentUtils from '@date-io/moment';
import '@fake-db';
import history from '@history';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {createGenerateClassName, jssPreset, StylesProvider} from '@material-ui/styles';
import Interceptors from 'app/services/interceptors';
import {create} from 'jss';
import jssExtend from 'jss-plugin-extend';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import {Router} from 'react-router-dom';
import {FuseLayout, FuseTheme} from '../@fuse';
import Authorization from '../@rubix/components/Authorization/Authorization';
import AppContext from './AppContext';
import {Auth} from './auth';
import routes from './configs/routesConfig';
import store from './store';
import 'react-image-lightbox/style.css';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName();

const App = () => {
  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Interceptors>
              <Auth>
                <Router history={history}>
                  <Authorization>
                    <FuseTheme>
                      <FuseLayout/>
                    </FuseTheme>
                  </Authorization>
                </Router>
              </Auth>
            </Interceptors>
          </MuiPickersUtilsProvider>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  );
};

export default App;
