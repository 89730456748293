import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import React from "react";
import {useDispatch} from "react-redux";
import * as Actions from 'app/store/actions';

function ErrorDialog(props) {
    const dispatch = useDispatch();

    const error = props?.response?.data?.error;
    const url = props?.response?.config?.url;
    return (
        <React.Fragment>
            <DialogTitle id="alert-dialog-title">Error {error?.statusCode}</DialogTitle>
            <DialogContent>
                <Typography variant="h6" color="inherit" className="pt-8">
                    {error?.message}
                </Typography>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant="caption" color="inherit">
                        While trying to perform request "{url}"
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={() => dispatch(Actions.closeDialog())} color="primary">*/}
                {/*    Retry*/}
                {/*</Button>*/}
                <Button onClick={() => dispatch(Actions.closeDialog())} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </React.Fragment>
    );
}

export default ErrorDialog;
