import React from 'react';

export const RolesAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/roles',
            component: React.lazy(() => import('./RolesApp'))
        },
    ]
};
