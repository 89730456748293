import * as Actions from '../actions';

const initialState = {
  guest: true,
  name: 'Guest',
  email: 'guest@rubixinc.com',
  photoURL: null,
  company: {
    name: '[no company]',
    _permissions: [],
  },
  role: {
    name: '',
  },
  shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
  settings: {
    defaultCompanyId: null,
    layout: {},
    images: [],
  },
  memberships: [],
  notifications: [],
  roles: [],
  _permissions: [],
};

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      const memberships = action.payload.memberships ?? [];
      const membership =
        memberships.find((m) => m.companyId === action.payload.settings.defaultCompanyId) ?? (memberships ?? [])[0];
      return {
        ...action.payload,
        memberships: [...(action.payload.memberships ?? [])],
        company: membership?.company,
        role: {
          name: (membership?.company?._roles ?? {[membership?.roleId]: membership?.roleId})[membership?.roleId],
        },
      };
    }
    case Actions.SET_DEFAULT_COMPANY: {
      return {
        ...state,
        company: action.payload,
      };
    }
    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState,
      };
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState;
    }
    case Actions.CHANGE_PASSWORD: {
      return {
        ...state,
        password: action.payload,
      };
    }
    case Actions.UPDATE_PROFILE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case Actions.UPLOAD_PROFILE_PICTURE: {
      return {
        ...state,
        avatarId: action.payload.id,
        avatarUrl: action.payload.url,
        settings: {
          ...action.user.settings,
          images: [...(action.user.settings.images || []), action.payload],
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
