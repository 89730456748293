import {reloadUserData} from 'app/auth/store/actions';
import {handleCatch} from 'app/store/actions';
import axios from 'axios';

export const GET_LOG = '[LOG APP] GET LOG';
export const CLEANUP_LOG = '[LOG APP] CLEANUP LOG';

export const getLog = (logId) => dispatch =>
  axios.get(`/logs/${logId}`).then(
    (response) => dispatch({
      type: GET_LOG,
      payload: response.data,
    }),
    handleCatch(dispatch),
  );

export const addLogComment = (logId, data) => (dispatch) =>
  axios.post(`/log/${logId}/comments`, data).then(
    response => {
      dispatch(reloadUserData());
      return response;
    },
    handleCatch(dispatch),
  );

export const cleanupLog = () => ({
    type: CLEANUP_LOG
});
