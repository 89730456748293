import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        '& .logo-icon': {
            width: 70,
            height: 70,
            transition: theme.transitions.create(['width', 'height'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut
            })
        },
        '& .react-badge, & .logo-text': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut
            })
        }
    },
    reactBadge: {
        backgroundColor: '#121212',
        color: '#61DAFB'
    }
}));

function Logo() {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, "flex items-center")}>
            <img className="logo-icon" src={"assets/images/logos/logo-plain.png"} alt="logo"/>
            <Link className="text-24 ml-12 font-bold logo-text" color="inherit" to="/dashboard">
                RubixLink
            </Link>
        </div>
    );
}

export default Logo;
