export class User {
  static instance = {
    roles: [],
    _permissions: [],
    company: {
      _permissions: [],
    },
  };

  static setInstance(user) {
    User.instance = user;
  }

  static hasRole(role) {
    return User.instance.roles.includes(role);
  }

  static canAccess(route) {
    if (!route.auth) {
      return true;
    }
    return route.auth.every((auth) => !!auth.require(User.instance));
  }

  static can(permissions, resource = null) {
    if (typeof permissions === 'string') {
      permissions = [permissions];
    }
    return permissions.every((permission) => {
      if (!User.instance) {
        return false;
      }
      let res = resource;
      if (permission.startsWith('self:')) {
        res = User.instance;
      }
      if (!res) {
        res = User.instance.company ?? {};
      }
      return (res._permissions ?? []).includes(permission);
    });
  }

  static canAccessDashboard() {
    return User.can('projects:manage');
  }

  static isPlainMember(user) {
    return !!(user?.roles ?? []).filter((role) => role !== 'Platform/Member' && role.includes('Member') && !role.includes('Crew')).length;
  }

  static isCrewLeader(user) {
    return !!(user?.roles ?? []).filter((role) => role.includes('CrewLeader')).length;
  }
}
