import axios from 'axios';
import {openErrorDialog, showSuccessMessage} from "../../../../../store/actions";
import _ from '@lodash';
import {setCompany} from "../../../../../auth/store/actions";
import Utils from "../../../../../../@rubix/Utils";
import {reloadUserData} from "../../../../../auth/store/actions";

export const GET_COMPANIES = '[COMPANIES APP] GET COMPANIES';
export const OPEN_NEW_COMPANY_DIALOG = '[COMPANIES APP] OPEN NEW COMPANY DIALOG';
export const CLOSE_NEW_COMPANY_DIALOG = '[COMPANIES APP] CLOSE NEW COMPANY DIALOG';
export const OPEN_EDIT_COMPANY_DIALOG = '[COMPANIES APP] OPEN EDIT COMPANY DIALOG';
export const CLOSE_EDIT_COMPANY_DIALOG = '[COMPANIES APP] CLOSE EDIT COMPANY DIALOG';
export const GET_COMPANY = '[COMPANIES APP] GET COMPANY';
export const ADD_COMPANY = '[COMPANIES APP] ADD COMPANY';
export const UPDATE_COMPANY = '[COMPANIES APP] UPDATE COMPANY';
export const REMOVE_COMPANY = '[COMPANIES APP] REMOVE COMPANY';
export const UPLOAD_LOGO = '[COMPANIES APP] UPLOAD COMPANY LOGO';
export const CHANGE_COMPANY_INVOICE_SETTINGS = '[COMPANIES APP] CHANGE COMPANY INVOICE SETTINGS';

export const GET_PARTNERS = '[COMPANIES APP] GET PARTNERS';

export function getCompanies() {
    return (dispatch) => {
        const request = axios.get('/companies');
        request.then(response => {
            dispatch({
                type: GET_COMPANIES,
                payload: response.data
            })
        }).catch(err => {
            return dispatch(openErrorDialog(err.response));
        });
    }
}

export function openNewCompanyDialog() {
    return {
        type: OPEN_NEW_COMPANY_DIALOG
    }
}

export function closeNewCompanyDialog() {
    return {
        type: CLOSE_NEW_COMPANY_DIALOG
    }
}

export function openEditCompanyDialog(data) {
    return {
        type: OPEN_EDIT_COMPANY_DIALOG,
        data
    }
}

export function closeEditCompanyDialog() {
    return {
        type: CLOSE_EDIT_COMPANY_DIALOG
    }
}

export function getCompany(companyId) {
    return (dispatch) => {
        return axios.get(`/companies/${companyId}`)
            .then((response) =>
                dispatch({
                    type: GET_COMPANY,
                    payload: response.data
                })
            ).catch(err => {
                return dispatch(openErrorDialog(err.response));
            });
    };
}

export function addCompany(newCompany) {
    return (dispatch) => {
        const company = _.pick(newCompany, [
            'ein',
            'name',
            'address',
            'country',
            'state',
            'city',
            'zip',
            'contactName',
            'contactPhone',
            'contactEmail',
            'parentId',
        ]);
        const postData = {
            companyData: company
        };
        return axios.post('/companies', postData)
            .then(({data}) =>
                Promise.all([
                    dispatch({
                        type: ADD_COMPANY,
                        payload: data
                    }),
                    dispatch(closeNewCompanyDialog()),
                    dispatch(reloadUserData()),
                    dispatch(showSuccessMessage({message: 'Company Created'}))
                ]).then(() => {
                    if (!company.parentId) {
                        Utils.redirectTo(`/companies/${data.id}`);
                    }
                    return data;
                })
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

export function updateCompany(data) {
    const company = _.pick(data, [
        'ein',
        'name',
        'address',
        'country',
        'state',
        'city',
        'zip',
        'contactName',
        'contactPhone',
        'contactEmail',
        'logoUrl',
    ]);
    const request = axios.patch(`/companies/${data.id}`, company);

    return (dispatch, getState) =>
        request.then(response => {
                const currentCompany = getState().auth.user.company;
                if (currentCompany.id === response.data.id) {
                    dispatch(setCompany(response.data));
                }
                dispatch(showSuccessMessage({message: 'Company Saved'}));
                return dispatch({
                    type: UPDATE_COMPANY,
                    payload: data
                })
            }
        ).catch(err => {
            return dispatch(openErrorDialog(err.response));
        });
}

export function uploadLogo(company, file) {
    const form = new FormData();
    form.append('file', file);
    form.append('type', 'logo');
    const request = axios.post(`/companies/${company.id}/files`, form);

    return (dispatch) =>
        request.then(response => {
                dispatch(showSuccessMessage({message: 'Logo Added'}));
                return dispatch({
                    type: UPLOAD_LOGO,
                    payload: response.data,
                    company
                })
            }
        ).catch(err => {
            return dispatch(openErrorDialog(err.response));
        });
}

export function changeCompanyInvoiceSettings(company, data) {

    const request = axios.post(`/companies/${company.id}/invoice`, data);

    return (dispatch) =>
        request.then(response => {
                dispatch(showSuccessMessage({message: 'Invoice Settings Saved'}));
                dispatch(setCompany(company));
                return dispatch({
                    type: CHANGE_COMPANY_INVOICE_SETTINGS,
                    payload: response.data,
                    company
                })
            }
        ).catch(err => {
            return dispatch(openErrorDialog(err.response));
        });
}

export function removeCompany(companyId) {
    return (dispatch, getState) => {

        const {routeParams} = getState().companiesApp.companies;

        const request = axios.delete(`/companies/${companyId}`);

        return request.then(() =>
            Promise.all([
                dispatch({
                    type: REMOVE_COMPANY
                }),
                dispatch(showSuccessMessage({message: 'Company Deleted'}))
            ]).then(() => dispatch(getCompanies(routeParams)))
        ).catch(err => {
            return dispatch(openErrorDialog(err.response));
        });
    };
}

export function getPartners(type) {
    return (dispatch) => {
        return axios.get(`/partnerships/list/${type}`)
            .then((response) =>
                dispatch({
                    type: GET_PARTNERS,
                    payload: response.data.map(partnership => partnership[type])
                })
            ).catch(err => {
                return dispatch(openErrorDialog(err.response));
            });
    };
}
