import {handleCatch} from 'app/store/actions';
import axios from 'axios';

export const GET_LOGS = '[LOG APP] GET LOGS';
export const LOG_GET_PROJECTS = '[LOG APP] GET PROJECTS';
export const LOG_GET_PROJECT = '[LOG APP] GET PROJECT';
export const LOG_GET_CREWS = '[LOG APP] GET CREWS';
export const LOG_GET_CREW = '[LOG APP] GET CREW';
export const LOG_GET_MEMBER = '[LOG APP] GET MEMBER';
export const LOG_ADD = '[LOG APP] ADD LOG';
export const LOG_UPDATE = '[LOG APP] UPDATE LOG STATUS';
export const LOG_PROJECT_SETUP_OPEN_DIALOG = '[LOG APP] OPEN LOG REJECT DIALOG';
export const LOG_PROJECT_SETUP_CLOSE_DIALOG = '[LOG APP] CLOSE LOG REJECT DIALOG';

export const getLogs = () => (dispatch) =>
  axios.get(`/logs`).then(
    (response) =>
      dispatch({
        type: GET_LOGS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const addLog = (projectId, crewId, lat, lng, date, status) => (dispatch) =>
  axios.post(`logs`, {logData: {projectId, crewId, lat, lng, date, status}, dateString: date.format('YYYY-MM-DD HH:mm:ss')}).then(
    (response) =>
      Promise.all([
        dispatch({
          type: LOG_ADD,
        }),
        dispatch(closeLogProjectSetupDialog()),
      ]).then(() => dispatch(getLogs())), // return logs list
    handleCatch(dispatch),
  );

export const updateLogStatus = (logId, status) => (dispatch) =>
  axios.patch(`/logs/${logId}`, {status}).then(
    (response) =>
      Promise.all([
        dispatch({
          type: LOG_UPDATE,
          // payload: response.data,
        }),
        dispatch(closeLogProjectSetupDialog()),
      ]).then(() => dispatch(getLogs())), // return logs list
    handleCatch(dispatch),
  );

export const getProjects = (params) => (dispatch) =>
  axios.get('/projects', {params}).then(
    (response) =>
      dispatch({
        type: LOG_GET_PROJECTS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getProject = (projectId) => (dispatch) =>
  axios.get(`/projects/${projectId}`).then(
    (response) =>
      dispatch({
        type: LOG_GET_PROJECT,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getCrews = (companyId) => (dispatch) =>
  axios.get(`/production/crews`).then(
    (response) =>
      dispatch({
        type: LOG_GET_CREWS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getCrew = (crewId) => (dispatch) =>
  axios.get(`/crews/${crewId}`).then(
    (response) =>
      dispatch({
        type: LOG_GET_CREW,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getMember = (memberId) => (dispatch) =>
  axios.get(`/memberships/${memberId}`).then(
    (response) =>
      dispatch({
        type: LOG_GET_MEMBER,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const openLogProjectSetupDialog = (log = null) => ({
  type: LOG_PROJECT_SETUP_OPEN_DIALOG,
  payload: log,
});

export const closeLogProjectSetupDialog = () => ({
  type: LOG_PROJECT_SETUP_CLOSE_DIALOG,
});
