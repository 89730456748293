import React from 'react';
import Dropdown from './Dropdown';
import {timezones} from '@rubix/i18n/timezones';

export function TimezonePicker(props) {
    return (
        <Dropdown
            id="timezone"
            name="timezone"
            options={timezones}
            value={props.timezone}
            getOptionLabel={o => o.name}
            getOptionSelected={o => o.id === props.timezone}
            label="Timezone"
            required={props.required}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
}