import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/FuseUtils';

class jwtService extends FuseUtils.EventEmitter {

  init() {
    this.handleAuthentication();
  }

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin');
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'Session has expired. Please log in.', true);
    }
  };

  createUser = (data, token) => {
    const url = token ? `/users/register/${token}` : `/users`;
    return new Promise((resolve, reject) => {
      return axios.post(url, data)
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        }).catch(e => {
          console.log(e);
          reject(e.response.data.error);
        });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios.post('/users/login', {
        email,
        password,
      }).then(response => {
        if (response.data.user) {
          this.setSession(response.data.token);
          resolve(response.data.user);
        } else {
          reject();
        }
      }).catch(err => reject(err.response.data.error));
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios.get('/users/me', {
        data: {
          access_token: this.getAccessToken(),
        },
      })
        .then(response => {
          resolve(response.data.user);
        })
        .catch(error => {
          this.setSession(null);
          reject('Cannot recover session. Please log in.');
        });
    });
  };

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  logout = () => {
    this.setSession(null);
    // axios.get('/users/logout')
    //     .then(response => {
    //         if (response.data.expired) {
    //             this.setSession(null);
    //         }
    //     });
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('Session has expired. Please log in...');
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new jwtService();

export default instance;
