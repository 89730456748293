import * as Actions from '../actions';

const initialState = {
    entities: null,
    projects: [],
    project: null,
    productions: [],
    invoice: null,
    invoiceDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    invoiceReport: {},
};

const invoicesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_INVOICES: {
            return {
                ...state,
                entities: action.payload,
            };
        }
        case Actions.OPEN_NEW_INVOICE_DIALOG: {
            return {
                ...state,
                invoiceDialog: {
                    ...initialState.invoiceDialog,
                    type: 'new',
                    props: {
                        open: true
                    }
                }
            };
        }
        case Actions.CLOSE_NEW_INVOICE_DIALOG: {
            return {
                ...state,
                invoiceDialog: {
                    ...initialState.invoiceDialog,
                    props: {
                        open: false
                    }
                }
            };
        }
        case Actions.GET_PROJECTS: {
            return {
                ...state,
                projects: action.payload,
            };
        }
        case Actions.ADD_INVOICE: {
            return {
                ...state,
                invoice: action.payload,
            };
        }
        case Actions.INVOICE_GET_PROJECT: {
            return {
                ...state,
                project: action.payload,
            };
        }
        case Actions.INVOICE_GET_PRODUCTIONS: {
            return {
                ...state,
                productions: action.payload,
            };
        }
        case Actions.GENERATE_INVOICE_REPORT: {
            return {
                ...state,
                invoiceReport: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};


export default invoicesReducer;
