import React from 'react';
import {User} from '../../../../@rubix/User';

const defaultAuthentication = [
  {
    require: () => User.hasRole('Platform/Member'),
    denied: '/register/account',
  },
  {
    require: () => User.instance.memberships.length > 0,
    denied: '/register/company',
  },
];


export const InvitationsAppConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [],
  routes: [
    {
      path: '/partner-invitation/:token/:type/accept',
      auth: defaultAuthentication,
      component: React.lazy(() => import('./PartnerInvitationAcceptPage')),
    },
    {
      path: '/partner-invitation/:token/:type/decline',
      component: React.lazy(() => import('./PartnerInvitationDeclinePage')),
    },
    {
      path: '/member-invitation/:token/accept',
      auth: defaultAuthentication,
      component: React.lazy(() => import('./MemberInvitationAcceptPage')),
    },
    {
      path: '/member-invitation/:token/decline',
      component: React.lazy(() => import('./MemberInvitationDeclinePage')),
    },
  ],
};
