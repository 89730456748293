import React, {Fragment, useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";
import _ from '@lodash';
import Utils from "../Utils";

function Dropdown(props) {
    const [options, setOptions] = useState(null);
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (props.options && props.options.then) {
            props.options.then(opts => {
                setOptions(opts);
            });
        } else {
            setOptions(props.options);
        }
    }, [props.options]);

    useEffect(() => {
        const option = _.find(options, o => o.id === props.value);
        if (option?.id !== value?.id) {
            setValue(option);
        }
    }, [props.value, options, value])

    function handleChange(event, option) {
        setValue(option);
        let value = option ? option.id : '';
        if (props.onChange) {
            const result = props.onChange({
                persist: () => {
                },
                target: {
                    type: 'dropdown',
                    name: props.name,
                    value: value,
                    text: option
                }
            });
            if (result && result.then) {
                result.then(value => {
                    props.onChange({
                        persist: () => {
                        },
                        target: {
                            type: 'dropdown',
                            name: props.name,
                            value: value
                        }
                    });
                });
            }
        }
    }

    function getOptionLabel(option) {
        if (option && option.id && options && options.length) {
            return props.getOptionLabel(option);
        }
        return '';
    }

    return (
        <Fragment>
            {options ?
                <Autocomplete
                    {...props}
                    value={value}
                    onChange={handleChange}
                    getOptionLabel={getOptionLabel}
                    options={options}
                    renderOption={option => (
                        props.renderOption ? props.renderOption(option) :
                            <React.Fragment key={option.id}>
                                {getOptionLabel(option)}
                            </React.Fragment>
                    )}
                    renderInput={params => (
                        props.renderInput ? props.renderInput(params) :
                            <TextField
                                {...params}
                                label={props.label}
                                variant="outlined"
                                fullWidth
                                required={props.required}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled',
                                }}
                            />
                    )}
                /> :
                <TextField
                    id={props.id}
                    name={props.name}
                    value={Utils.capitalizeWords(props.value)}
                    label={props.label}
                    variant="outlined"
                    fullWidth
                    required={props.required}
                    disabled={props.disabled}
                    onChange={props.onChange}
                />
            }
        </Fragment>

    );
}

export default React.memo(Dropdown);
