import _ from '@lodash';
import {reloadUserData} from 'app/auth/store/actions';
// import history from '@history';
import {openErrorDialog, showSuccessMessage, handleCatch} from 'app/store/actions/fuse';
import axios from 'axios';
import moment from 'moment';

export const GET_PROJECT = '[PROJECTS APP] GET PROJECT';
export const GET_PROJECTS = '[PROJECTS APP] GET PROJECTS';
export const GET_CLIENT_PROJECTS = '[PROJECTS APP] GET CLIENT PROJECTS';
export const GET_PROJECT_MEMBERS = '[PROJECT APP] GET PROJECT MEMBERS';
export const GET_PROJECT_ROLES = '[PROJECT APP] GET PROJECT ROLES';
export const GET_PROJECT_STATIONS = '[PROJECTS APP] GET PROJECT STATIONS';
export const OPEN_PROJECT_WIZARD_DIALOG = '[PROJECTS APP] OPEN NEW PROJECT DIALOG';
export const CLOSE_PROJECT_WIZARD_DIALOG = '[PROJECTS APP] CLOSE NEW PROJECT DIALOG';
export const ADD_PROJECT = '[PROJECTS APP] ADD PROJECT';
export const UPLOAD_PROJECT_FILE = '[PROJECTS APP] UPLOAD PROJECT FILE';
export const DELETE_PROJECT_FILE = '[PROJECTS APP] DELETE PROJECT FILE';
export const OPEN_PROJECT_FILE_UPLOAD_DIALOG = '[PROJECTS APP] OPEN PROJECT FILE UPLOAD DIALOG';
export const CLOSE_PROJECT_FILE_UPLOAD_DIALOG = '[PROJECTS APP] CLOSE PROJECT FILE UPLOAD DIALOG';
export const UPDATE_PROJECT = '[PROJECTS APP] UPDATE PROJECT';
export const REMOVE_PROJECT = '[PROJECTS APP] REMOVE PROJECT';
export const OPEN_ADD_MEMBER_TO_PROJECT_DIALOG = '[PROJECTS APP] OPEN ADD MEMBER TO PROJECT DIALOG';
export const CLOSE_ADD_MEMBER_TO_PROJECT_DIALOG = '[PROJECTS APP] CLOSE ADD MEMBER TO PROJECT DIALOG';
export const SEND_PROJECT_INVITE = '[PROJECTS APP] SEND PROJECT INVITE';
export const REMOVE_PROJECT_MEMBER = '[PROJECTS APP] REMOVE PROJECT MEMBER';

export const GET_PROJECT_BUDGETS = '[PROJECT APP] GET PROJECT BUDGETS';
export const GET_PROJECT_BUDGET = '[PROJECT APP] GET PROJECT BUDGET';
export const OPEN_NEW_BUDGET_DIALOG = '[PROJECTS APP] OPEN NEW BUDGET DIALOG';
export const CLOSE_NEW_BUDGET_DIALOG = '[PROJECTS APP] CLOSE NEW BUDGET DIALOG';
export const OPEN_BUDGET_ITEM_DIALOG = '[PROJECTS APP] OPEN PROJECT BUDGET ITEM DIALOG';
export const CLOSE_BUDGET_ITEM_DIALOG = '[PROJECTS APP] CLOSE PROJECT BUDGET ITEM DIALOG';
export const GET_BUDGET_ITEMS = '[PROJECTS APP] GET PROJECT BUDGET ITEMS';
export const ADD_BUDGET_ITEM = '[PROJECTS APP] ADD PROJECT BUDGET ITEM';
export const UPDATE_BUDGET_ITEM = '[PROJECTS APP] UPDATE PROJECT BUDGET ITEM';
export const REMOVE_BUDGET_ITEM = '[PROJECTS APP] REMOVE PROJECT BUDGET ITEM';
export const SUBMIT_BUDGET = '[PROJECTS APP] SUBMIT BUDGET FOR REVIEW';
export const CANCEL_SUBMIT_BUDGET = '[PROJECTS APP] CANCEL BUDGET REVIEW';
export const GET_BUDGET_REVIEW = '[PROJECTS APP] GET BUDGET REVIEW';
export const SUBMIT_REVIEW = '[PROJECTS APP] SUBMIT BUDGET REVIEW';
export const BUDGET_REVIEW_APPROVE_START = '[PROJECTS APP] BUDGET REVIEW APPROVE START';
export const BUDGET_REVIEW_APPROVE_END = '[PROJECTS APP] BUDGET REVIEW APPROVE END';
export const BUDGET_REVIEW_REJECT_START = '[PROJECTS APP] BUDGET REVIEW REJECT START';
export const BUDGET_REVIEW_REJECT_END = '[PROJECTS APP] BUDGET REVIEW REJECT END';
export const OPEN_BUDGET_REVIEW_REJECT_DIALOG = '[PROJECTS APP] OPEN PROJECT BUDGET REJECT DIALOG';
export const CLOSE_BUDGET_REVIEW_REJECT_DIALOG = '[PROJECTS APP] CLOSE PROJECT BUDGET REJECT DIALOG';
export const OPEN_SIGN_BUDGET_DIALOG = '[PROJECTS APP] OPEN SIGN BUDGET DIALOG';
export const CLOSE_SIGN_BUDGET_DIALOG = '[PROJECTS APP] CLOSE SIGN BUDGET DIALOG';
export const UPLOAD_BUDGET_SIGNATURE = '[PROJECTS APP] UPLOAD BUDGET SIGNATURE';
export const SET_FILE_CONTRACT_UPLOAD_PROGRESS = '[PROJECTS APP] SET FILE CONTRACT UPLOAD PROGRESS';
export const SET_FILE_WORK_ORDER_UPLOAD_PROGRESS = '[PROJECTS APP] SET FILE WORK ORDER UPLOAD PROGRESS';
export const SET_FILE_OTHER_UPLOAD_PROGRESS = '[PROJECTS APP] SET FILE OTHER UPLOAD PROGRESS';
export const OPEN_BUDGET_CHANGE_ORDER_DIALOG = '[PROJECTS APP] OPEN BUDGET CHANGE ORDER DIALOG';
export const CLOSE_BUDGET_CHANGE_ORDER_DIALOG = '[PROJECTS APP] CLOSE BUDGET CHANGE ORDER DIALOG';
export const OPEN_ADD_BUDGET_CHANGE_ORDER_DIALOG = '[PROJECTS APP] OPEN ADD BUDGET CHANGE ORDER DIALOG';
export const CLOSE_ADD_BUDGET_CHANGE_ORDER_DIALOG = '[PROJECTS APP] CLOSE ADD BUDGET CHANGE ORDER DIALOG';
export const GET_BUDGET_CHANGE_ORDER_AVAILABLE_ITEMS = '[PROJECTS APP] GET BUDGET CHANGE ORDER AVAILABLE ITEMS';
export const GET_BUDGET_CHANGE_ORDERS = '[PROJECTS APP] GET BUDGET CHANGE ORDERS';
export const OPEN_SEND_BACK_CHANGE_ORDER_DIALOG = '[PROJECTS APP] OPEN SEND BACK CHANGE ORDER DIALOG';
export const CLOSE_SEND_BACK_CHANGE_ORDER_DIALOG = '[PROJECTS APP] CLOSE SEND BACK CHANGE ORDER DIALOG';
export const OPEN_ADD_PDF_DIALOG = '[PROJECTS APP] OPEN ADD PDF DIALOG';
export const CLOSE_ADD_PDF_DIALOG = '[PROJECTS APP] CLOSE ADD PDF DIALOG';

export const GET_PROJECT_REPORT_OVERVIEW = '[PROJECTS APP] GET PROJECT REPORT OVERVIEW';
export const GET_PROJECT_REPORT_PRODUCTION = '[PROJECTS APP] GET PROJECT REPORT PRODUCTION';
export const CLEANUP_PROJECT_REPORT_OVERVIEW = '[PROJECTS APP] CLEANUP PROJECT REPORT OVERVIEW';
export const CLEANUP_PROJECT_REPORT_PRODUCTION = '[PROJECTS APP] CLEANUP PROJECT REPORT PRODUCTION';

export const GET_COMPANY_PARENT_PROJECTS = '[PROJECTS APP] GET COMPANY PARENT PROJECTS';
export const CLEANUP_COMPANY_PARENT_PROJECTS = '[PROJECTS APP] CLEANUP COMPANY PARENT PROJECTS';
export const GET_PROJECT_ITEM_CATEGORIES = '[PROJECTS APP] GET PROJECT ITEM CATEGORIES';
export const CLEANUP_PROJECT_ITEM_CATEGORIES = '[PROJECTS APP] CLEANUP PROJECT ITEM CATEGORIES';
export const GET_PROJECT_CREW_COMPANIES = '[PROJECTS APP] GET PROJECT CREW COMPANIES';
export const CLEANUP_PROJECT_CREW_COMPANIES = '[PROJECTS APP] CLEANUP PROJECT CREW COMPANIES';
export const GET_PROJECT_SUBPROJECTS = '[PROJECTS APP] GET PROJECT SUBPROJECTS';
export const CLEANUP_PROJECT_SUBPROJECTS = '[PROJECTS APP] CLEANUP PROJECT SUBPROJECTS';

export const GET_CLIENT_SUBCONTRACTOR_PROJECTS = '[PROJECTS APP] GET CLIENT SUBCONTRACTOR PROJECTS';
export const GET_ALL_PROJECT_ITEMS_CATEGORIES = '[PROJECTS APP] GET ALL PROJECT ITEMS CATEGORIES';
export const GET_BUDGET_DISTRIBUTION_DATA = '[PROJECTS APP] GET BUDGET DISTRIBUTION DATA';
export const CLEANUP_BUDGET_DISTRIBUTION_DATA = '[PROJECTS APP] CLEANUP BUDGET DISTRIBUTION DATA';
export const GET_BUDGET_SPENT_DATA = '[PROJECTS APP] GET BUDGET SPENT DATA';
export const CLEANUP_BUDGET_SPENT_DATA = '[PROJECTS APP] CLEANUP BUDGET SPENT DATA';
export const GET_BUDGET_DETAILS_DATA = '[PROJECTS APP] GET BUDGET DETAILS DATA';
export const CLEANUP_BUDGET_DETAILS_DATA = '[PROJECTS APP] CLEANUP BUDGET DETAILS DATA';

export const GET_PROJECT_PRODUCTIONS = '[PROJECTS APP] GET PRODUCTIONS';

export function openProjectWizardDialog() {
  return {
    type: OPEN_PROJECT_WIZARD_DIALOG,
  };
}

export function closeProjectWizardDialog() {
  return {
    type: CLOSE_PROJECT_WIZARD_DIALOG,
  };
}

export function getProject(projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getProjects(companyId) {
  return (dispatch) => {
    return axios
      .get(`/companies/${companyId}/projects/all`)
      .then((response) =>
        dispatch({
          type: GET_PROJECTS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getClientProjects(companyId) {
  return (dispatch) => {
    return axios
      .get(`/companies/${companyId}/projects/client`)
      .then((response) =>
        dispatch({
          type: GET_CLIENT_PROJECTS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function addProject(companyId, newProject) {
  newProject.startDate = moment.utc(moment(newProject.startDate).format('YYYY-MM-DD'));
  newProject.endDate = moment.utc(moment(newProject.endDate).format('YYYY-MM-DD'));
  const project = _.pick(newProject, [
    'projectName',
    'code',
    'address',
    'country',
    'state',
    'city',
    'warrantyPeriod',
    'startDate',
    'endDate',
    'companyId',
    'clientId',
    'projectId',
    'powoNumber',
    'timezone',
  ]);

  project.warrantyPeriod = parseInt(newProject.warrantyPeriod);
  project.powoNumber = String(project.powoNumber);

  return (dispatch) => {
    return axios
      .post(`/companies/${companyId}/projects`, project)
      .then(({data}) =>
        Promise.all([
          dispatch({
            type: ADD_PROJECT,
            payload: data,
          }),
          dispatch(showSuccessMessage({message: 'Project Created'})),
          // dispatch(closeProjectWizardDialog()),
          dispatch(getProjects(companyId)),
        ]).then(() => {
          // history.push({
          //     pathname: `/projects/${data.id}`
          // });
          return data;
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function updateProject(companyId, newProject) {
  newProject.startDate = moment.utc(moment(newProject.startDate).format('YYYY-MM-DD'));
  newProject.endDate = moment.utc(moment(newProject.endDate).format('YYYY-MM-DD'));
  const project = _.pick(newProject, [
    'projectName',
    'code',
    'projectNumber',
    'address',
    'country',
    'state',
    'city',
    'warrantyPeriod',
    'startDate',
    'endDate',
    'status',
    'suspendedShutdownReason',
    'suspendStart',
    'suspendEnd',
    'suspendReason',
    'mapSettings',
    'powoNumber',
    'timezone',
    'taskBasedProduction',
  ]);

  project.warrantyPeriod = parseInt(newProject.warrantyPeriod);

  return (dispatch) =>
    axios
      .patch(`/projects/${newProject.id}`, project)
      .then(({data}) =>
        Promise.all([
          dispatch(showSuccessMessage({message: 'Project Saved'})),
          dispatch(getProject(newProject.id)),
        ]).then(() =>
          dispatch({
            type: UPDATE_PROJECT,
            payload: data,
          }),
        ),
      )
      .catch((err) => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
}

export function removeProject(companyId, projectId) {
  return (dispatch) => {
    return axios
      .delete(`/projects/${projectId}`)
      .then(() =>
        Promise.all([
          dispatch({
            type: REMOVE_PROJECT,
            payload: projectId,
          }),
          dispatch(showSuccessMessage({message: 'Project deleted'})),
        ]).then(() => dispatch(getProjects(companyId))),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const uploadProjectFile = (project, file, fileType) => (dispatch) => {
  const form = new FormData();
  form.append('file', file);
  form.append('type', fileType);

  let uploadType = '';

  switch (fileType) {
    case 'contract':
      uploadType = SET_FILE_CONTRACT_UPLOAD_PROGRESS;
      break;
    case 'workOrder':
      uploadType = SET_FILE_WORK_ORDER_UPLOAD_PROGRESS;
      break;
    case 'other':
      uploadType = SET_FILE_OTHER_UPLOAD_PROGRESS;
      break;
    default:
      uploadType = '';
      break;
  }

  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (percentCompleted < 100) {
        dispatch({
          type: uploadType,
          payload: percentCompleted,
        });
      }
    },
  };

  const request = axios.post(`/projects/${project.id}/files/${fileType}`, form, config);

  return request
    .then((response) => {
      dispatch(showSuccessMessage({message: 'File uploaded'}));
      dispatch({
        type: uploadType,
        payload: 100,
      });
      return dispatch({
        type: UPLOAD_PROJECT_FILE,
        payload: response.data,
        project,
        fileType,
      });
    })
    .catch((err) => {
      console.log(err);
      return dispatch(openErrorDialog(err.response));
    });
};

export function deleteProjectFile(project, file) {
  return (dispatch) => {
    return axios
      .delete(`/files/${file.id}`)
      .then(() => {
        dispatch(showSuccessMessage({message: 'File deleted'}));
        return dispatch({
          type: DELETE_PROJECT_FILE,
          payload: file,
          project,
        });
      })
      .catch((err) => {
        console.log(err);
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function openProjectFileUploadDialog() {
  return {
    type: OPEN_PROJECT_FILE_UPLOAD_DIALOG,
  };
}

export function closeProjectFileUploadDialog() {
  return {
    type: CLOSE_PROJECT_FILE_UPLOAD_DIALOG,
  };
}

export function openAddMemberToProjectDialog() {
  return {
    type: OPEN_ADD_MEMBER_TO_PROJECT_DIALOG,
  };
}

export function closeAddMemberToProjectDialog() {
  return {
    type: CLOSE_ADD_MEMBER_TO_PROJECT_DIALOG,
  };
}

export function getProjectMembers(projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/memberships`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_MEMBERS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function addProjectMembers(projectId, newMembers) {
  const members = newMembers.map((member) => _.pick(member, ['id', 'roleId']));
  return (dispatch) => {
    return axios
      .post(`/projects/${projectId}/memberships`, members)
      .then((data) =>
        Promise.all([
          dispatch({
            type: SEND_PROJECT_INVITE,
            payload: data,
          }),
        ]).then(dispatch(getProjectMembers(projectId))),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function removeProjectMember(projectId, membershipId) {
  return (dispatch) => {
    return axios
      .delete(`/memberships/${membershipId}`)
      .then(() =>
        Promise.all([
          dispatch({
            type: REMOVE_PROJECT_MEMBER,
          }),
          dispatch(showSuccessMessage({message: 'Project Member Deleted'})),
        ]).then(() => dispatch(getProjectMembers(projectId))),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getBudget(projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/budget`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_BUDGET,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function openBudgetItemDialog(itemType, data) {
  return {
    type: OPEN_BUDGET_ITEM_DIALOG,
    itemType,
    data,
  };
}

export function closeBudgetItemDialog() {
  return {
    type: CLOSE_BUDGET_ITEM_DIALOG,
  };
}

export function getBudgetItems(budget) {
  return (dispatch) => {
    return axios
      .get(`/projects/${budget.projectId}/budgets/${budget.id}/items/company`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_ITEMS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getParentBudgetItems(budget) {
  return (dispatch) => {
    return axios
      .get(`/projects/${budget.projectId}/budgets/${budget.id}/items/parent`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_ITEMS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function addBudgetItem(budget, item) {
  const itemData = {
    itemId: item.itemId ?? item.item.id,
    reportWeight: parseFloat(item.reportWeight ?? 0),
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    description: item.description,
  };
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/items`, itemData)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudget(budget.projectId)),
          dispatch(closeBudgetItemDialog()),
        ]).then(() =>
          dispatch({
            type: ADD_BUDGET_ITEM,
            item: response.data,
          }),
        ),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function updateBudgetItem(budget, itemData) {
  const item = _.pick(itemData, ['quantity', 'price', 'reportWeight', 'description']);
  item.reportWeight = parseFloat(item.reportWeight || 0);
  item.quantity = parseInt(item.quantity);
  item.price = parseFloat(item.price);
  return (dispatch) =>
    axios
      .patch(`/projects/${budget.projectId}/budgets/${budget.id}/items/${itemData.item.id}`, item)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudget(budget.projectId)),
          dispatch(closeBudgetItemDialog()),
        ]).then(() =>
          dispatch({
            type: UPDATE_BUDGET_ITEM,
            item: response.data,
          }),
        ),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function removeBudgetItem(budget, id) {
  return (dispatch) =>
    axios
      .delete(`/projects/${budget.projectId}/budgets/${budget.id}/items/${id}`)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudget(budget.projectId)),
          dispatch(closeBudgetItemDialog()),
        ]).then(() =>
          dispatch({
            type: REMOVE_BUDGET_ITEM,
            id,
          }),
        ),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function addBudgetReviewer(budget, memberId) {
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/reviews`, {memberId})
      .then((response) =>
        Promise.all([dispatch(showSuccessMessage(response.data)), dispatch(getBudget(budget.projectId))]).then(
          () => response,
        ),
      )
      .catch((err) => dispatch(openErrorDialog(err.response)));
}

export function removeBudgetReviewer(budget, reviewId) {
  return (dispatch) =>
    axios
      .delete(`/projects/${budget.projectId}/budgets/${budget.id}/reviews/${reviewId}`)
      .then((response) =>
        Promise.all([dispatch(showSuccessMessage(response.data)), dispatch(getBudget(budget.projectId))]).then(
          () => response,
        ),
      )
      .catch((err) => dispatch(openErrorDialog(err.response)));
}

export function submitBudget(budget, reviewRequest) {
  reviewRequest.retainagePercent = parseFloat(Number(reviewRequest.retainagePercent));
  reviewRequest.profitabilityPercent = parseFloat(Number(reviewRequest.profitabilityPercent));
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/submit`, reviewRequest)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudget(budget.projectId)),
          dispatch(reloadUserData()),
        ]).then(() => {
          dispatch({
            type: SUBMIT_BUDGET,
            payload: response.data,
          });
        }),
      )
      .catch((err) => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
}

export const deleteBudgetSubmit = (budget) => (dispatch) =>
  axios
    .delete(`/projects/${budget.projectId}/budgets/${budget.id}/submit`)
    .then((response) =>
      Promise.all([dispatch(showSuccessMessage(response.data)), dispatch(getBudget(budget.projectId))]).then(() => {
        dispatch({
          type: CANCEL_SUBMIT_BUDGET,
          payload: budget,
        });
      }),
    )
    .catch((err) => {
      return dispatch(openErrorDialog(err.response));
    });

export function getBudgetReviewByHash(projectId, budgetId, reviewHash) {
  return (dispatch) =>
    axios
      .get(`/projects/${projectId}/budgets/${budgetId}/reviews/${reviewHash}`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_REVIEW,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function reviewBudget(projectId, status, statusReason, budgetId, reviewHash) {
  return (dispatch) => {
    dispatch({
      type: status === 'approved' ? BUDGET_REVIEW_APPROVE_START : BUDGET_REVIEW_REJECT_START,
    });
    return axios
      .patch(`/projects/${projectId}/budgets/${budgetId}/reviews/${reviewHash}`, {
        status,
        statusReason,
      })
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getProject(projectId)),
          dispatch(reloadUserData()),
        ]).then(() => {
          dispatch({
            type: status === 'approved' ? BUDGET_REVIEW_APPROVE_END : BUDGET_REVIEW_REJECT_END,
            payload: response.data,
            success: true,
          });
          return dispatch({
            type: SUBMIT_REVIEW,
            payload: reviewHash,
          });
        }),
      )
      .catch((err) => {
        dispatch({
          type: status === 'approved' ? BUDGET_REVIEW_APPROVE_END : BUDGET_REVIEW_REJECT_END,
          error: err.response.data.error,
        });
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function addBudgetComment(budget, data) {
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/comments`, data)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudget(budget.projectId)),
          dispatch(reloadUserData()),
        ]).then(() => response),
      )
      .catch((err) => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
}

export function openBudgetReviewRejectDialog(review) {
  return {
    type: OPEN_BUDGET_REVIEW_REJECT_DIALOG,
    review,
  };
}

export function closeBudgetReviewRejectDialog() {
  return {
    type: CLOSE_BUDGET_REVIEW_REJECT_DIALOG,
  };
}

export function openSignBudgetDialog(review) {
  return {
    type: OPEN_SIGN_BUDGET_DIALOG,
    payload: review,
  };
}

export function closeSignBudgetDialog() {
  return {
    type: CLOSE_SIGN_BUDGET_DIALOG,
  };
}

export function getAvailableItems(companyId) {
  return (dispatch) => {
    return axios
      .get(`/companies/${companyId}/items`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_CHANGE_ORDER_AVAILABLE_ITEMS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function openBudgetChangeOrderDialog(data) {
  return {
    type: OPEN_BUDGET_CHANGE_ORDER_DIALOG,
    data,
  };
}

export function closeBudgetChangeOrderDialog() {
  return {
    type: CLOSE_BUDGET_CHANGE_ORDER_DIALOG,
  };
}

export function openSendBackChangeOrderDialog(data) {
  return {
    type: OPEN_SEND_BACK_CHANGE_ORDER_DIALOG,
    data,
  };
}

export function closeSendBackChangeOrderDialog() {
  return {
    type: CLOSE_SEND_BACK_CHANGE_ORDER_DIALOG,
  };
}

export function getBudgetChangeOrders(budget) {
  return (dispatch) =>
    axios
      .get(`/projects/${budget.projectId}/budgets/${budget.id}/change-orders`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_CHANGE_ORDERS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function openAddBudgetChangeOrderDialog() {
  return {
    type: OPEN_ADD_BUDGET_CHANGE_ORDER_DIALOG,
  };
}

export function closeAddBudgetChangeOrderDialog() {
  return {
    type: CLOSE_ADD_BUDGET_CHANGE_ORDER_DIALOG,
  };
}

//
export function addBudgetChangeOrder(budget, itemId) {
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/change-orders`, {itemId})
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudgetChangeOrders(budget)),
          dispatch(closeAddBudgetChangeOrderDialog()),
        ]),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function updateBudgetChangeOrder(budget, changeOrder) {
  const data = _.pick(changeOrder, ['quantity', 'price', 'reportWeight', 'description', 'requestFromParent']);
  data.reportWeight = parseFloat(data.reportWeight || 0);
  data.quantity = parseInt(data.quantity);
  data.price = parseFloat(data.price);
  data.requestFromParent = !!data.requestFromParent;
  return (dispatch) =>
    axios
      .patch(`/projects/${budget.projectId}/budgets/${budget.id}/change-orders/${changeOrder.id}`, data)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudgetChangeOrders(budget)),
          dispatch(closeBudgetChangeOrderDialog()),
        ]),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function sendBudgetChangeOrder(budget, changeOrder) {
  const data = _.pick(changeOrder, ['price']);
  data.price = parseFloat(data.price);
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/change-orders/${changeOrder.id}/send`, data)
      .then((response) =>
        Promise.all([
          dispatch(showSuccessMessage(response.data)),
          dispatch(getBudget(budget.projectId)),
          dispatch(closeBudgetChangeOrderDialog()),
        ]),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function acceptBudgetChangeOrder(budget, changeOrder) {
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/change-orders/${changeOrder.id}/accept`)
      .then((response) =>
        Promise.all([dispatch(showSuccessMessage(response.data)), dispatch(getBudget(budget.projectId))]),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function rejectBudgetChangeOrder(budget, changeOrder, data) {
  let payload = _.pick(data, ['message']);
  return (dispatch) =>
    axios
      .post(`/projects/${budget.projectId}/budgets/${budget.id}/change-orders/${changeOrder.id}/reject`, payload)
      .then((response) =>
        Promise.all([dispatch(showSuccessMessage(response.data)), dispatch(getBudget(budget.projectId))]),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function uploadSignature(budget, project, reviewHash, file) {
  const form = new FormData();
  form.append('file', file, file.name);
  form.append('type', 'signature');
  const request = axios.post(`/projects/${project.id}/budgets/${budget.id}/reviews/${reviewHash}/sign`, form);

  return (dispatch) =>
    request
      .then((response) => {
        Promise.all([
          dispatch({
            type: UPLOAD_BUDGET_SIGNATURE,
            payload: response.data,
            budget,
          }),
          dispatch(reviewBudget(budget.projectId, 'approved', 'Approved budget', budget.id, reviewHash)),
        ]);
      })
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function getProjectStations(projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/stations`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_STATIONS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getProjectReportOverview(projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/report/overview`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_REPORT_OVERVIEW,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getProjectReportProduction(projectId, data) {
  const params = {
    ...data,
    range: {
      from: data.range.from ? data.range.from.format('YYYY-MM-DD') : '',
      to: data.range.to ? data.range.to.format('YYYY-MM-DD') : '',
      type: data.range.type,
    }
  };
  return (dispatch) => {
    return axios
      .post(`/projects/${projectId}/report/production`, params)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_REPORT_PRODUCTION,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupProjectReportOverview = () => ({
  type: CLEANUP_PROJECT_REPORT_OVERVIEW,
});

export const cleanupProjectReportProduction = () => ({
  type: CLEANUP_PROJECT_REPORT_PRODUCTION,
});

export function getCompanyParentProjects(companyId) {
  return (dispatch) => {
    return axios
      .get(`/companies/${companyId}/projects/parent`)
      .then((response) =>
        dispatch({
          type: GET_COMPANY_PARENT_PROJECTS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupCompanyParentProjects = () => ({
  type: CLEANUP_COMPANY_PARENT_PROJECTS,
});

export function getProjectItemCategories(companyId, projectId, type = '') {
  const endpoint =
    type === 'subproject'
      ? `/projects/${projectId}/item-categories/${companyId}/${projectId}`
      : `/projects/${projectId}/item-categories/${companyId}/all`;
  return (dispatch) => {
    return axios
      .get(endpoint)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_ITEM_CATEGORIES,
          payload: [{id: 'all', name: 'All'}, ...response.data],
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupProjectItemCategories = () => ({
  type: CLEANUP_PROJECT_ITEM_CATEGORIES,
});

export function getProjectCrewCompanies(projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/crew-companies`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_CREW_COMPANIES,
          payload: [{id: 'all', name: 'All'}, ...response.data],
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupProjectCrewCompanies = () => ({
  type: CLEANUP_PROJECT_CREW_COMPANIES,
});

export function getProjectSubprojects(companyId, projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/sub-projects/${companyId}`)
      .then((response) =>
        dispatch({
          type: GET_PROJECT_SUBPROJECTS,
          payload: [{id: 'all', name: 'All'}, ...response.data],
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupProjectSubprojects = () => ({
  type: CLEANUP_PROJECT_SUBPROJECTS,
});

export const uploadMapPdf = (projectId, file) => (dispatch) => {
  const form = new FormData();
  form.append('file', file, file.name);
  form.append('type', 'pdf');
  return axios
    .post(`/projects/${projectId}/map-pdf`, form)
    .then((response) => dispatch(getProject(projectId)).then((pRes) => response.data))
    .catch((err) => {
      return dispatch(openErrorDialog(err.response));
    });
};

export const deleteMapPdf = (projectId, fileId) => (dispatch) =>
  axios
    .delete(`/projects/${projectId}/map-pdf/${fileId}`)
    .then((response) => dispatch(getProject(projectId)).then((res) => res.data))
    .catch((err) => dispatch(openErrorDialog(err.response)));

export const updateMapOverlay = (projectId, pageNumber, overlay) => (dispatch) =>
  axios
    .patch(`/projects/${projectId}/map-overlay/${pageNumber}`, overlay)
    .then((response) => dispatch(getProject(projectId)).then((pRes) => response.data))
    .catch((err) => dispatch(openErrorDialog(err.response)));

export const deleteMapOverlay = (projectId, pageNumber) => (dispatch) =>
  axios
    .delete(`/projects/${projectId}/map-overlay/${pageNumber}`)
    .then((response) => dispatch(getProject(projectId)).then((res) => res.data))
    .catch((err) => dispatch(openErrorDialog(err.response)));

export function getClientSubcontractorProjects(companyId, partnerId, type) {
  return (dispatch) => {
    return axios
      .get(`/companies/${companyId}/projects/${type}/${partnerId}`)
      .then((response) =>
        dispatch({
          type: GET_CLIENT_SUBCONTRACTOR_PROJECTS,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getAllProjectItemsCategories(companyId, projectId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/item-categories/${companyId}/skip`)
      .then((response) =>
        dispatch({
          type: GET_ALL_PROJECT_ITEMS_CATEGORIES,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getBudgetDistributionData(companyId, projectId, categoryId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/budget-distribution/${companyId}/${categoryId}`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_DISTRIBUTION_DATA,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupBudgetDistributionData = () => ({
  type: CLEANUP_BUDGET_DISTRIBUTION_DATA,
});

export function getBudgetSpentData(companyId, projectId, categoryId, range) {
  return (dispatch) => {
    return axios
      .post(`/projects/${projectId}/budget-spent`, {
        companyId,
        categoryId,
        range,
      })
      .then((response) =>
        dispatch({
          type: GET_BUDGET_SPENT_DATA,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupBudgetSpentData = () => ({
  type: CLEANUP_BUDGET_SPENT_DATA,
});

export function getBudgetDetailsData(companyId, projectId, categoryId) {
  return (dispatch) => {
    return axios
      .get(`/projects/${projectId}/budget-details/${companyId}/${categoryId}`)
      .then((response) =>
        dispatch({
          type: GET_BUDGET_DETAILS_DATA,
          payload: response.data,
        }),
      )
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export const cleanupBudgetDetailsData = () => ({
  type: CLEANUP_BUDGET_DETAILS_DATA,
});

export const getProjectProductions = (projectId) => (dispatch) =>
  axios.get(`/projects/${projectId}/productions`).then(
    (response) =>
      dispatch({
        type: GET_PROJECT_PRODUCTIONS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );
