import React from 'react';

export const ProfilePageConfig = {
  settings: {
    layout: {
      config: {
        settings: {
          display: true,
        },
      },
    },
  },
  routes: [
    {
      path: '/profile',
      component: React.lazy(() => import('./ProfilePage')),
    },
  ],
};
