import * as Actions from '../actions';

const initialState = {
  rejectDialog: {
    props: {
      open: false,
    },
    production: null,
  },
  addItemsDialog: {
    props: {
      open: false
    },
    fetchActivity: false,
  },
  production: null,
  fileUploadProgress: 0,
  dailyProductionSheetDownloadState: false
};

const production = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PRODUCTION: {
      return {
        ...state,
        production: action.payload,
      };
    }
    case Actions.GET_PRODUCTION_ACTIVITY: {
      return {
        ...state,
        production: {
          ...state.production,
          activity: action.payload,
        },
      };
    }
    case Actions.OPEN_PRODUCTION_REVIEW_REJECT_DIALOG: {
      return {
        ...state,
        rejectDialog: {
          props: {
            open: true,
          },
          production: action.production,
        },
      };
    }
    case Actions.CLOSE_PRODUCTION_REVIEW_REJECT_DIALOG: {
      return {
        ...state,
        rejectDialog: initialState.rejectDialog,
      };
    }
    case Actions.SET_FILE_UPLOAD_PROGRESS: {
      return {
        ...state,
        fileUploadProgress: action.payload,
      };
    }
    case Actions.OPEN_PRODUCTION_ADD_ITEM_DIALOG: {
      return {
        ...state,
        addItemsDialog: {
          props: {
            open: true
          },
          fetchActivity: action.fetchActivity
        }
      }
    }
    case Actions.CLOSE_PRODUCTION_ADD_ITEM_DIALOG: {
      return {
        ...state,
        addItemsDialog: initialState.addItemsDialog
      }
    }
    case Actions.CLEANUP_PRODUCTION: {
      return initialState
    }
    case Actions.SET_DAILY_PRODUCTION_SHEET_DOWNLOAD_STATE: {
      return {
        ...state,
        dailyProductionSheetDownloadState: action.payload,
      }
    }

    default: {
      return state;
    }
  }
};

export default production;
