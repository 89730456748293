import * as Actions from '../actions';

const initialState = {
    invoice: null,
    activity: [],
    rejectDialog: {
        type: 'reject',
        props: {
            open: false,
        },
    },
    paidDialog: {
        props: {
            open: false,
        },
    },
    invoiceItems: [],
    invoiceReportFilters: {},
};

const invoiceReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_INVOICE: {
            return {
                ...state,
                invoice: action.payload,
            };
        }
        case Actions.GET_INVOICE_ACTIVITY: {
            return {
                ...state,
                activity: action.payload,
            };
        }
        case Actions.OPEN_INVOICE_REJECT_DIALOG: {
            return {
                ...state,
                rejectDialog: {
                    type: 'reject',
                    props: {
                        open: true,
                    },
                    invoice: action.invoice,
                },
            };
        }
        case Actions.CLOSE_INVOICE_REJECT_DIALOG: {
            return {
                ...state,
                rejectDialog: initialState.rejectDialog,
            };
        }
        case Actions.CLEANUP_INVOICE: {
            return {
                ...initialState
            };
        }
        case Actions.OPEN_INVOICE_CANCEL_DIALOG: {
            return {
                ...state,
                rejectDialog: {
                    type: 'cancel',
                    props: {
                        open: true,
                    },
                    invoice: action.invoice,
                },
            };
        }
        case Actions.CLOSE_INVOICE_CANCEL_DIALOG: {
            return {
                ...state,
                rejectDialog: initialState.rejectDialog,
            };
        }
        case Actions.OPEN_INVOICE_PAID_DIALOG: {
            return {
                ...state,
                paidDialog: {
                    props: {
                        open: true,
                    },
                },
                invoice: action.invoice,
                invoiceReportFilters: action.filters,
            };
        }
        case Actions.CLOSE_INVOICE_PAID_DIALOG: {
            return {
                ...state,
                paidDialog: initialState.paidDialog,
            };
        }
        case Actions.INVOICE_GET_ITEMS: {
            return {
                ...state,
                invoiceItems: action.payload,
            }
        }
        default: {
            return state;
        }
    }
};


export default invoiceReducer;
