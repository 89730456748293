import mock from "../mock";

const mapDb = [
    {
        id: 1,
        title: "Best songs to listen while working",
        type: "pdf",
        file: "",
        features: {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [-2.247137215315531, 53.48204585981742]
                    },
                    properties: {
                        type: "boxType-1",
                        name: "k8hcplra the name - 86t3f",
                        desc: "desc text here - 0.oz1yh8dztfs",
                        coords: [-2.247137215315531, 53.48204585981742],
                        prettyCoords: "53° 28′ 56″ N 2° 14′ 50″ W",
                        image_url: "assets/images/map/UG20.png"
                    },
                    id: "k8hcplra"
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [-2.2467278324793134, 53.481943846604764]
                    },
                    properties: {
                        type: "boxType-1",
                        name: "k8hcpmb7 the name - fmosr",
                        desc: "desc text here - 0.a5bgbv8t4h7",
                        coords: [-2.2467278324793134, 53.481943846604764],
                        prettyCoords: "53° 28′ 55″ N 2° 14′ 49″ W",
                        image_url: "assets/images/map/UG20.png"
                    },
                    id: "k8hcpmb7"
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [-2.246993931322855, 53.48133023956839]
                    },
                    properties: {
                        type: "boxType-1",
                        name: "k8hcpnan the name - abya5d",
                        desc: "desc text here - 0.e8xp25zf85m",
                        coords: [-2.246993931322855, 53.48133023956839],
                        prettyCoords: "53° 28′ 53″ N 2° 14′ 50″ W",
                        image_url: "assets/images/map/UG20.png"
                    },
                    id: "k8hcpnan"
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "LineString",
                        coordinates: [
                            [-2.247137215315531, 53.48204585981742],
                            [-2.2467278324793134, 53.481943846604764],
                            [-2.246993931322855, 53.48132871695927]
                        ]
                    },
                    properties: { type: "lineType-1", color: "purple" }
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [-2.2481043822660967, 53.48225749838673]
                    },
                    properties: {
                        type: "boxType-1",
                        name: "k8hcr4pw the name - m8sxwn",
                        desc: "desc text here - 0.4fsh67v3aio",
                        coords: [-2.2481043822660967, 53.48225749838673],
                        prettyCoords: "53° 28′ 57″ N 2° 14′ 54″ W",
                        image_url: "assets/images/map/UG20.png"
                    },
                    id: "k8hcr4pw"
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [-2.2484651508905014, 53.4815738563274]
                    },
                    properties: {
                        type: "boxType-1",
                        name: "k8hcr5xf the name - tj6a9n",
                        desc: "desc text here - 0.2mziqcupkmm",
                        coords: [-2.2484651508905014, 53.4815738563274],
                        prettyCoords: "53° 28′ 54″ N 2° 14′ 55″ W",
                        image_url: "assets/images/map/UG20.png"
                    },
                    id: "k8hcr5xf"
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [-2.2475286876526526, 53.48140180213633]
                    },
                    properties: {
                        type: "boxType-1",
                        name: "k8hcr7nb the name - guzlyd",
                        desc: "desc text here - 0.k8cy2nu3abb",
                        coords: [-2.2475286876526526, 53.48140180213633],
                        prettyCoords: "53° 28′ 54″ N 2° 14′ 52″ W",
                        image_url: "assets/images/map/UG20.png"
                    },
                    id: "k8hcr7nb"
                },
                {
                    type: "Feature",
                    geometry: {
                        type: "LineString",
                        coordinates: [
                            [-2.2484651508905014, 53.4815738563274],
                            [-2.2475286876526526, 53.48140180213633]
                        ]
                    },
                    properties: { type: "lineType-1", color: "yellow" }
                }
            ]
        }
    }
];

mock.onGet("/api/map/getmap").reply(config => {
    return [200, mapDb];
});
