import axios from "axios";
import {openErrorDialog} from "../../../../../store/actions/fuse";
import {reloadUserData} from 'app/auth/store/actions';

export const INVITATION_ACCEPT_START = '[CLIENTS APP] INVITATION ACCEPT START';
export const INVITATION_ACCEPT_END = '[CLIENTS APP] INVITATION ACCEPT END';
export const INVITATION_DECLINE_START = '[CLIENTS APP] INVITATION DECLINE START';
export const INVITATION_DECLINE_END = '[CLIENTS APP] INVITATION DECLINE END';
export const INVITATION_GET = '[INVITATION APP] CLIENT INVITATION GET';

export function getMemberInvitation(token) {
    return (dispatch) => {
        return axios.get(`/memberships/invitations/${token}`)
            .then(response => {
                return dispatch({
                    type: INVITATION_GET,
                    payload: response.data,
                });
            })
            .catch(err => {
                return dispatch(openErrorDialog(err.response));
            });
    }
}

export function acceptMemberInvitation(token) {
    return (dispatch) => {
        dispatch({
            type: INVITATION_ACCEPT_START
        });
        return axios.patch(`/memberships/invitations/${token}/accept`, {})
            .then(response => {
                return Promise.all([
                    dispatch({
                        type: INVITATION_ACCEPT_END,
                        payload: response.data,
                        success: true,
                    }),
                    dispatch(reloadUserData())
                ]);
            })
            .catch(error => {
                return dispatch({
                    type: INVITATION_ACCEPT_END,
                    error: error.response.data.error
                });
            });
    }
}

export function declineMemberInvitation(token, declineReason) {
    return (dispatch, getState) => {
        dispatch({
            type: INVITATION_DECLINE_START
        });
        // const defaultCompanyId = getState().auth.user.settings.defaultCompanyId;
        return axios.patch(`/memberships/invitations/${token}/decline`, {reason: declineReason})
            .then(response => {
                return dispatch({
                    type: INVITATION_DECLINE_END,
                    payload: response.data,
                    success: true
                });
            })
            .catch(error => {
                return dispatch({
                    type: INVITATION_DECLINE_END,
                    error: error.response.data.error
                });
            });
    }
}
