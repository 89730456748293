import React from 'react';
import {User} from '@rubix/User';

export const ReportsAppConfig = {
  settings: {
    layout: {}
  },
  auth: [
    {
      require: () => User.hasRole('Platform/Member'),
      denied: '/login',
    },
  ],
  routes: [
    {
      path: '/reports/flight-board',
      component: React.lazy(() => import('./flight-board/FlightBoard')),
    },
    {
      path: '/reports/project-production',
      auth: [
        {
          require: () => !User.isPlainMember(User.instance),
          denied: '/productions',
        },
      ],
      component: React.lazy(() => import('./project-inspection/ProjectInspection')),
    },
    {
      path: '/reports/invoices',
      auth: [
        {
          require: () => !User.isPlainMember(User.instance),
          denied: '/productions',
        },
      ],
      component: React.lazy(() => import('./invoices/InvoicesReport')),
    },
    {
      path: '/reports/budget',
      auth: [
        {
          require: () => !User.isPlainMember(User.instance),
          denied: '/productions',
        },
      ],
      component: React.lazy(() => import('./budget/BudgetReport')),
    },
  ],
};
