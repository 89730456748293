import * as Actions from '../actions';

const initialState = {
    invitation: null,
    accept: {
        loading: false,
        success: false,
        error: null,
    },
    decline: {
        loading: false,
        success: false,
        error: null,
    },
};

const invitation = function (state = initialState, action) {
    switch (action.type) {
        case Actions.INVITATION_GET: {
            return {
                ...state,
                invitation: action.payload
            };
        }
        case Actions.INVITATION_ACCEPT_START: {
            return {
                ...state,
                accept: {
                    loading: true
                }
            };
        }
        case Actions.INVITATION_ACCEPT_END: {
            return {
                ...state,
                invitation: action.payload,
                accept: {
                    loading: false,
                    success: action.success,
                    error: action.error,
                }
            };
        }
        case Actions.INVITATION_DECLINE_START: {
            return {
                ...state,
                decline: {
                    loading: true
                }
            };
        }
        case Actions.INVITATION_DECLINE_END: {
            return {
                ...state,
                invitation: action.payload,
                decline: {
                    loading: false,
                    success: action.success,
                    error: action.error
                }
            };
        }
        default: {
            return state
        }
    }
};

export default invitation;
