import React from 'react';

export const DamageTypesAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/damage-types',
      component: React.lazy(() => import('./DamageTypesApp')),
    },
  ],
};
