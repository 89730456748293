import React from 'react';

export const ItemsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/catalog/items',
      component: React.lazy(() => import('./ItemsApp')),
    },
    {
      path: [
        '/catalog/items/:itemId/:sectionId',
        '/catalog/items/:itemId',
      ],
      component: React.lazy(() => import('./item/ItemApp')),
    },
  ],
};
