import axios from 'axios';
import {handleCatch} from 'app/store/actions';

export const GET_LOGS = '[LOG APP] GET LOGS';

export const getLogs = (payload) => (dispatch) =>
    axios.get('/logs', payload.filter && Object.keys(payload.filter.where).length ? {
    	params: {
    		filter: {...payload.filter}
    	}
    } : null
    ).then(
        (response) => dispatch({
            type: GET_LOGS,
            payload: response.data,
        }),
        handleCatch(dispatch),
    );
