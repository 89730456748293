import jwtService from 'app/services/jwtService';
import {reloadUserData, setUserData} from './user.actions';
import axios from "axios";
import {openErrorDialog, showSuccessMessage} from "../../../store/actions/fuse";
import Utils from "../../../../@rubix/Utils";

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_END = 'LOGIN_END';
export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_END = 'RESET_PASSWORD_END';

export function submitLogin({email, password}, token, type, companyId) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_START
        });
        jwtService.signInWithEmailAndPassword(email, password)
            .then((user) =>
                Promise.all([
                    dispatch(reloadUserData()),
                ]).then(() => {
                    if (token) {
                        if (type === 'members') {
                            Utils.redirectToMiddleware(`/${type}-invitation/${companyId}/${token}/accept`);
                        } else {
                            Utils.redirectToMiddleware(`/${type}-invitation/${token}/accept`);
                        }
                    }
                    return dispatch({
                        type: LOGIN_END,
                        success: true
                    });
                })
            )
            .catch(error => {
                return dispatch({
                    type: LOGIN_END,
                    error
                });
            });
    }
}

export function forgotPassword({email}) {
    return (dispatch) => {
        return axios.post(`/users/forgot-password`, {email})
            .then((response) =>
                Promise.all([
                    dispatch({
                        type: RESET_EMAIL_SENT,
                        email
                    })
                ]).then(res =>
                    Utils.redirectTo('/forgot-password/requested')
                )
            ).catch(err => {
                return dispatch(openErrorDialog(err.response));
            });
    }
}

export function resetPassword({passwordResetToken, password}) {
    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD_START
        });
        return axios.post(`/users/reset-password`, {token: passwordResetToken, password})
            .then((response) => {
                if (response.data.token) {
                        dispatch(showSuccessMessage({message: 'Password was changed.'}));
                        jwtService.setSession(response.data.token);
                        return jwtService.signInWithToken()
                            .then(user => {
                                dispatch(setUserData(user));
                                return dispatch({
                                    type: RESET_PASSWORD_END,
                                    success: true
                                });
                            })
                            .catch(error => {
                                return dispatch({
                                    type: RESET_PASSWORD_END,
                                    error
                                });
                            });
                    }
                }
            ).catch(error => {
                return dispatch({
                    type: RESET_PASSWORD_END,
                    error
                });
            });
    }
}
