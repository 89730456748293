import React from 'react';

export const ProjectsAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            settings: {
                layout: {
                    config: {
                        navbar: {
                            display: false
                        },
                        toolbar: {
                            display: false
                        },
                        leftSidePanel: {
                            display: false
                        },
                        rightSidePanel: {
                            display: false
                        }
                    }
                }
            },
            path: '/projects/:projectId/budget/:budgetHash/review/:reviewHash/:action',
            component: React.lazy(() => import('./project/budgets/budget/summary/reviews/review/BudgetReviewEmailPage'))
        },
    	{
            path: [
                '/projects/:projectId/:sectionId/:entityId',
                '/projects/:projectId/:sectionId',
                '/projects/:projectId'
            ],
            component: React.lazy(() => import('./project/ProjectApp'))
        },
        {
            path: [
                '/projects/:projectId/:sectionId',
                '/projects/:projectId'
            ],
            component: React.lazy(() => import('./project/ProjectApp'))
        },
        {
            path: '/projects/:projectId',
            component: React.lazy(() => import('./project/ProjectApp'))
        },
        {
            path: '/projects',
            component: React.lazy(() => import('./ProjectsApp'))
        },
    ]
};
