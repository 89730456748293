import AppContext from 'app/AppContext';
import queryString from 'query-string';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import {withRouter} from 'react-router-dom';
import {User} from '../../User';

class Authorization extends Component {
  constructor(props, context) {
    super(props);
    const {routes} = context;
    this.state = {
      authorized: false,
      currentRoute: null,
      routes,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {location, history, user} = props;

    User.setInstance(user);

    const currentRoute = matchRoutes(state.routes, location.pathname)[0];
    // check if we can return to last route
    let returnTo = null;
    if (window.location.search) {
      const query = queryString.parse(window.location.search);
      returnTo = query?.returnTo;
      if (returnTo) {
        const returnToRoute = matchRoutes(state.routes, returnTo)[0];
        if (returnToRoute && User.canAccess(returnToRoute.route) && !['/login'].includes(returnTo)) {
          history.push(query.returnTo);
          return {
            authorized: false,
            currentRoute,
          };
        }
      }
    }
    // check for permissions
    const exclude = ['/pages/errors/error-404', '/login'];
    if (currentRoute && !User.canAccess(currentRoute.route)) {
      if (!returnTo) {
        returnTo = location.pathname;
      }
      if (User.hasRole('Platform/Member') && User.isPlainMember(User.instance)) {
        returnTo = '';
      }
      if (returnTo === '/dashboard' && User.hasRole('Platform/Member') && !User.canAccessDashboard()) {
        returnTo = '';
      }
      if (exclude.includes(returnTo)) {
        returnTo = '';
      }
      const failedAuth = currentRoute.route.auth.find((auth) => !auth.require(currentRoute));
      history.push(failedAuth.denied + (returnTo ? `?returnTo=${returnTo}` : ''));
      return {
        authorized: false,
        currentRoute,
      };
    }
    // update state
    return {
      authorized: true,
      currentRoute,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.authorized !== this.state.authorized;
  }

  render() {
    if (!this.state.authorized) {
      return <div>Unauthorized</div>;
    }
    return <React.Fragment children={this.props.children} />;
  }
}

const mapStateToProps = ({auth}) => ({user: auth.user});

Authorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(Authorization));
