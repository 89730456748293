import React from 'react';

export const CompaniesAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            name: 'company',
            path: [
                '/companies/:companyId/:sectionId',
                '/companies/:companyId'
            ],
            component: React.lazy(() => import('./company/CompanyApp'))
        },
        {
            name: 'companies',
            path: '/companies',
            component: React.lazy(() => import('./CompaniesApp'))
        },
    ]
};
