import {combineReducers} from 'redux';
import projects from './projects.reducer';
import crews from 'app/modules/company/crews/store/reducers/crews.reducer';

const projectsReducer = combineReducers({
    projects,
    crews
});

export default projectsReducer;
