import React from 'react';
import {User} from '@rubix/User';

export const DashboardAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/dashboard',
            auth: [
                {
                    require: () => !User.isPlainMember(User.instance),
                    denied: '/productions',
                },
            ],
            component: React.lazy(() => import('./DashboardApp'))
        }
    ]
};
