import ErrorDialog from 'app/layouts/shared-components/ErrorDialog';
import ImageDialog from 'app/layouts/shared-components/ImageDialog';
import React from 'react';

export const OPEN_DIALOG = '[DIALOG] OPEN';
export const CLOSE_DIALOG = '[DIALOG] CLOSE';

export function closeDialog () {
  return {
    type: CLOSE_DIALOG,
  };
}

export function openDialog (content, options) {
  return {
    type: OPEN_DIALOG,
    options: {
      ...options,
      children: content,
    },
  };
}

export function openConfirmDialog (content, options) {
  return {
    type: OPEN_DIALOG,
    options: {
      ...options,
      children: content,
    },
  };
}

export function openErrorDialog (response, options) {
  return {
    type: OPEN_DIALOG,
    options: {
      ...options,
      children: <ErrorDialog response={response} />,
      PaperProps: {
        style: {
          backgroundColor: 'rgba(255,175,175,0.9)',
        },
      },
    },
  };
}

export const handleCatch = dispatch => err => {
  dispatch(openErrorDialog(err.response));
  throw err;
}

export function openImageDialog (image, options) {
  return {
    type: OPEN_DIALOG,
    options: {
      ...options,
      children: <ImageDialog image={image} />,
    },
  };
}
