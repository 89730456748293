import {combineReducers} from 'redux';
import members from './members.reducer';
import invitation from './invitation.reducer';

const reducer = combineReducers({
    members,
    invitation,
});

export default reducer;
