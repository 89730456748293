import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
  entities: null,
};

const productions = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PRODUCTIONS: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
      };
    }
    default: {
      return state;
    }
  }
};

export default productions;
