import {User} from '../../../../@rubix/User';
import RegisterCompany from './RegisterCompany';
import RegisterUser from './RegisterUser';

export const RegisterConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [],
  routes: [
    {
      auth: [
        {
          require: () => !User.hasRole('Platform/Member'),
          denied: '/register/company',
        },
      ],
      path: '/register/account',
      component: RegisterUser,
    },
    {
      auth: [
        {
          require: () => User.hasRole('Platform/Member'),
          denied: '/register/account',
        },
        {
          require: () => User.can('self:companies:create'),
          denied: '/dashboard',
        },
      ],
      path: '/register/company',
      component: RegisterCompany,
    },
  ],
};

