import * as Actions from '../actions';

const initialState = {
    user: {
        loading: false,
        success: false,
        error: false
    },
    company: {
        loading: false,
        success: false,
        error: false
    }
};

const register = function (state = initialState, action) {
    switch (action.type) {
        case Actions.USER_REGISTER_START: {
            return {
                ...state,
                user: {
                    loading: true
                }
            };
        }
        case Actions.USER_REGISTER_END: {
            return {
                ...state,
                user: {
                    loading: false,
                    success: action.success,
                    error: action.error
                }
            };
        }
        case Actions.COMPANY_REGISTER_START: {
            return {
                ...state,
                company: {
                    loading: true
                }
            };
        }
        case Actions.COMPANY_REGISTER_END: {
            return {
                ...state,
                company: {
                    loading: false,
                    success: action.success,
                    error: action.error
                }
            };
        }
        default: {
            return state
        }
    }
};

export default register;
