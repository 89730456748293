import React from 'react';

export const CrewsAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            name: 'crew',
            path: [
                '/crews/:crewId/:sectionId',
                '/crews/:crewId'
            ],
            component: React.lazy(() => import('./crew/CrewApp'))
        },
        {
            path: '/crews',
            component: React.lazy(() => import('./CrewsApp'))
        },
    ]
};
