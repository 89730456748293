export const HIDE_MESSAGE = '[MESSAGE] CLOSE';
export const SHOW_MESSAGE = '[MESSAGE] SHOW';

export function hideMessage()
{
    return {
        type: HIDE_MESSAGE
    }
}

export function showMessage(options)
{
    return {
        type: SHOW_MESSAGE,
        options: {
            ...options,
            variant: 'info'
        }
    }
}

export function showSuccessMessage(options)
{
    return {
        type: SHOW_MESSAGE,
        options: {
            ...options,
            variant: 'success'
        }
    }
}

export function showErrorMessage(options)
{
    return {
        type: SHOW_MESSAGE,
        options: {
            ...options,
            variant: 'error'
        }
    }
}

