import axios from "axios";
import {openErrorDialog, showSuccessMessage} from "app/store/actions/fuse";
import _ from '@lodash';
import {reloadUserData} from "app/auth/store/actions";

export const GET_MEMBERS = '[MEMBERS APP] GET MEMBERS';
export const GET_MEMBER = '[MEMBERS APP] GET MEMBER';
export const OPEN_NEW_MEMBER_DIALOG = '[MEMBERS APP] OPEN NEW MEMBER DIALOG';
export const CLOSE_NEW_MEMBER_DIALOG = '[MEMBERS APP] CLOSE NEW MEMBER DIALOG';
export const OPEN_EDIT_MEMBER_DIALOG = '[MEMBERS APP] OPEN EDIT MEMBER DIALOG';
export const CLOSE_EDIT_MEMBER_DIALOG = '[MEMBERS APP] CLOSE EDIT MEMBER DIALOG';
export const ADD_MEMBER = '[MEMBERS APP] ADD MEMBER';
export const UPDATE_MEMBER = '[MEMVERS APP] UPDATE MEMBER';
export const REMOVE_MEMBER = '[MEMBERS APP] REMOVE MEMBER';
export const UPDATE_NOTIFICATION = '[MEMBERS APP] UPDATE NOTIFICATION';
export const UPDATE_NOTIFICATIONS = '[MEMBERS APP] UPDATE NOTIFICATIONS';

export function openNewMemberDialog() {
    return {
        type: OPEN_NEW_MEMBER_DIALOG
    }
}

export function closeNewMemberDialog() {
    return {
        type: CLOSE_NEW_MEMBER_DIALOG
    }
}

export function openEditMemberDialog(data) {
    return {
        type: OPEN_EDIT_MEMBER_DIALOG,
        data
    }
}

export function closeEditMemberDialog() {
    return {
        type: CLOSE_EDIT_MEMBER_DIALOG
    }
}

export function getMembers(companyId) {
    return (dispatch) => {
        return axios.get(`/companies/${companyId}/memberships`)
            .then((response) =>
                dispatch({
                    type: GET_MEMBERS,
                    payload: response.data,
                })
            ).catch(err =>
                dispatch(openErrorDialog(err.response))
            );
    }
}

export function addMember(companyId, data, onUpdate) {
    return (dispatch) => {
        const newMember = _.pick(data, ['name', 'phone', 'email', 'country', 'state', 'roleId']);
        return axios.post(`/companies/${companyId}/memberships`, newMember)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: ADD_MEMBER
                    }),
                    dispatch(closeNewMemberDialog()),
                    dispatch(showSuccessMessage({message: 'Member Added'})),
                ]).then(() =>
                  onUpdate()
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

export function updateMember(companyId, memberData, onUpdate) {
    return (dispatch) => {
        const member = _.pick(memberData, ['name', 'phone', 'email', 'country', 'state', 'roleId']);
        return axios.patch(`/memberships/${memberData.id}`, member)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: UPDATE_MEMBER
                    }),
                    dispatch(closeEditMemberDialog()),
                    dispatch(showSuccessMessage({message: 'Member Saved'})),
                ]).then(() =>
                    onUpdate()
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

export function removeMember(companyId, memberId, onUpdate) {
    return (dispatch) => {
        return axios.delete(`/memberships/${memberId}`)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: REMOVE_MEMBER
                    }),
                    dispatch(showSuccessMessage({message: 'Member Removed'})),
                ]).then(() =>
                  onUpdate()
                )
            ).catch(err =>
                dispatch(openErrorDialog(err.response))
            );
    };
}

export function getMember(memberId) {
    return (dispatch) => {
        return axios.get(`/memberships/${memberId}`)
            .then((response) =>
                dispatch({
                    type: GET_MEMBER,
                    payload: response.data,
                })
            ).catch(err =>
                dispatch(openErrorDialog(err.response))
            );
    }
}

export function markNotificationAsRead(notificationData) {
    return (dispatch) => {
        const notification = {
            ...notificationData,
            read: true
        };
        return axios.patch(`/notifications/${notification.id}`, notification)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: UPDATE_NOTIFICATION
                    }),
                ]).then(() =>
                    dispatch(reloadUserData())
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

export function markAllNotificationsAsRead(memberId) {
    return (dispatch) => {
        return axios.get(`/members/${memberId}/notifications`)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: UPDATE_NOTIFICATIONS
                    }),
                ]).then(() =>
                    dispatch(reloadUserData())
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

