import React from 'react';

export const ProductionAppConfig = {
  routes: [
    {
      path: [
        '/productions/inspect/:productionId/:sectionId/:itemId',
        '/productions/inspect/:productionId/:sectionId',
        '/productions/inspect/:productionId',
      ],
      component: React.lazy(() => import('./production/inspect/ProductionInspectApp')),
    },
    {
      path: ['/productions/preview/:productionId'],
      settings: {
        layout: {
          config: {
            navbar: {
              display: false,
            },
            toolbar: {
              display: false,
            },
            leftSidePanel: {
              display: false,
            },
            rightSidePanel: {
              display: false,
            },
            settings: {
              display: false,
            },
          },
        },
        customScrollbars: false,
      },
      component: React.lazy(() => import('./production/inspect/ProductionPreviewApp')),
    },
    {
      path: '/productions',
      component: React.lazy(() => import('./ProductionsApp')),
    },
  ],
};
