import {combineReducers} from 'redux';
import fuse from './fuse';
import auth from '../../auth/store/reducers';
import invitationApp from '../../modules/company/invitations/store/reducers';
import membersApp from '../../modules/company/members/store/reducers';
import projectsApp from 'app/modules/company/projects/store/reducers';
import tasksApp from 'app/modules/company/tasks/store/reducers';
import invoiceApp from 'app/modules/company/invoices/store/reducers';
import companiesApp from 'app/modules/company/companies/store/reducers';
import productionApp from 'app/modules/company/productions/store/reducers';
import invoicesApp from 'app/modules/company/invoices/store/reducers';
import logsApp from 'app/modules/company/logs/store/reducers';

const createReducer = (asyncReducers) =>
  combineReducers({
    invoicesApp,
    productionApp,
    companiesApp,
    invoiceApp,
    tasksApp,
    projectsApp,
    membersApp,
    invitationApp,
    logsApp,
    auth,
    fuse,
    ...asyncReducers,
  });

export default createReducer;
