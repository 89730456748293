import {reloadUserData} from 'app/auth/store/actions';
import {openErrorDialog, showSuccessMessage} from 'app/store/actions/fuse';
import axios from 'axios';
import _ from 'lodash';

export const GET_SUBCONTRACTORS = '[SUBCONTRACTORS APP] GET SUBCONTRACTORS';
export const GET_SUBCONTRACTOR = '[SUBCONTRACTORS APP] GET SUBCONTRACTOR';
export const OPEN_NEW_SUBCONTRACTOR_DIALOG = '[SUBCONTRACTORS APP] OPEN NEW SUBCONTRACTOR DIALOG';
export const CLOSE_NEW_SUBCONTRACTOR_DIALOG = '[SUBCONTRACTORS APP] CLOSE NEW SUBCONTRACTOR DIALOG';
export const OPEN_EDIT_SUBCONTRACTOR_DIALOG = '[SUBCONTRACTORS APP] OPEN EDIT SUBCONTRACTOR DIALOG';
export const CLOSE_EDIT_SUBCONTRACTOR_DIALOG = '[SUBCONTRACTORS APP] CLOSE EDIT SUBCONTRACTOR DIALOG';
export const ADD_SUBCONTRACTOR = '[SUBCONTRACTORS APP] ADD SUBCONTRACTOR';
export const REMOVE_SUBCONTRACTOR = '[SUBCONTRACTORS APP] REMOVE SUBCONTRACTOR';

export function openNewSubcontractorDialog() {
  return {
    type: OPEN_NEW_SUBCONTRACTOR_DIALOG,
  };
}

export function closeNewSubcontractorDialog() {
  return {
    type: CLOSE_NEW_SUBCONTRACTOR_DIALOG,
  };
}

export function openEditSubcontractorDialog(data) {
  return {
    type: OPEN_EDIT_SUBCONTRACTOR_DIALOG,
    data,
  };
}

export function closeEditSubcontractorDialog() {
  return {
    type: CLOSE_EDIT_SUBCONTRACTOR_DIALOG,
  };
}

export function getSubcontractors() {
  return (dispatch) => {
    return axios.get(`/partnerships/list/subcontractor`)
      .then((response) =>
        dispatch({
          type: GET_SUBCONTRACTORS,
          payload: response.data,
        }),
      ).catch(err => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function getSubcontractor(subcontractorId) {
  return (dispatch) => {
    return axios.get(`/partnerships/get/${subcontractorId}`)
      .then((response) =>
        dispatch({
          type: GET_SUBCONTRACTOR,
          payload: response.data,
        }),
      ).catch(err => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

export function addSubcontractor(guestCompanyId) {
  return (dispatch) => {
    return axios.post(`/partnerships/add/subcontractor`, {guestCompanyId})
      .then(partnership => {
        dispatch({type: ADD_SUBCONTRACTOR, payload: partnership});
        dispatch(closeNewSubcontractorDialog());
        dispatch(getSubcontractors());
        dispatch(reloadUserData());
        dispatch(showSuccessMessage({message: 'Subcontractor Added'}));
      })
      .catch(err => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });

  };
}

export function inviteSubcontractor(guestCompanyData) {
  const invitationData = _.pick(guestCompanyData, [
    'name',
    'contactName',
    'contactEmail'
  ]);
  return (dispatch) => {
    return axios.post(`/partnerships/invite/subcontractor`, invitationData)
      .then(partnership => {
        dispatch({type: ADD_SUBCONTRACTOR, payload: partnership});
        dispatch(closeNewSubcontractorDialog());
        dispatch(getSubcontractors());
        dispatch(showSuccessMessage({message: 'Subcontractor Added'}));
      })
      .catch(err => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
  };
}

export function createSubcontractor(newCompany) {
  const guestCompanyData = _.pick(newCompany, [
    'ein',
    'name',
    'address',
    'country',
    'state',
    'city',
    'zip',
    'contactName',
    'contactPhone',
    'contactEmail',
  ]);
  return (dispatch) =>
    axios.post(`/partnerships/create/subcontractor`, guestCompanyData)
      .then(partnership => {
        dispatch({type: ADD_SUBCONTRACTOR, payload: partnership});
        dispatch(closeNewSubcontractorDialog());
        dispatch(getSubcontractors());
        dispatch(showSuccessMessage({message: 'Subcontractor Added'}));
      })
      .catch(err => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
}

export function removeSubcontractor(subcontractorId) {
  return (dispatch) => {
    return axios.delete(`/partnerships/delete/${subcontractorId}`)
      .then(() =>
        Promise.all([
          dispatch({
            type: REMOVE_SUBCONTRACTOR,
          }),
          dispatch(showSuccessMessage({message: 'Subcontractor Deleted'})),
        ]).then(() => dispatch(getSubcontractors())),
      ).catch(err => {
        return dispatch(openErrorDialog(err.response));
      });
  };
}

