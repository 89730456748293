import React from 'react';
import {User} from '../../../../@rubix/User';

export const InvoicesAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: [
        '/invoices/preview/:invoiceId',
      ],
      auth: [
        {
          require: () => User.hasRole('Platform/Member'),
          denied: '/login',
        },
      ],
      settings: {
        layout: {
          config: {
            navbar: {
              display: false,
            },
            toolbar: {
              display: false,
            },
            leftSidePanel: {
              display: false,
            },
            rightSidePanel: {
              display: false,
            },
            settings: {
              display: false,
            },
          },
        },
        customScrollbars: false,
      },
      component: React.lazy(() => import('./invoice/view/InvoicePreviewApp')),
    },
    {
      path: '/invoices/new/:projectId/:invoiceId?',
      component: React.lazy(() => import('./invoice/InvoiceNewApp')),
    },
    {
      path: '/invoices/:invoiceId',
      component: React.lazy(() => import('./invoice/view/InvoiceApp')),
    },

    {
      path: '/invoices',
      component: React.lazy(() => import('./InvoicesApp')),
    },
  ],
};
