import Utils from '@rubix/Utils';
import {logoutUser} from '../auth/store/actions';
import jwtService from 'app/services/jwtService';
import {showErrorMessage} from 'app/store/actions/fuse';
import axios from 'axios';
import React from 'react';
import {useDispatch} from 'react-redux';
import {User} from '../../@rubix/User';

const Interceptors = (props) => {
  const dispatch = useDispatch();

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (err.response) {
        if (err.response.config.url.indexOf('openweathermap') >= 0) {
          return <React.Fragment children={props.children} />;
        }
        if (err.response.status === 401) {
          if (!User.instance.guest && jwtService.isAuthTokenValid(jwtService.getAccessToken())) {
            dispatch(showErrorMessage({message: 'You do not have the necessary permissions to perform this action.'}));
          } else {
            dispatch(logoutUser());
            Utils.redirectTo(`/login?returnTo=${window.location.pathname}`);
            return false;
          }
        } else {
          dispatch(showErrorMessage({message: err.response.data.error.message}));
        }
        throw err;
      }
      dispatch(showErrorMessage({message: 'Network error. Check the internet connection and try again.'}));
      throw err;
    },
  );

  return <React.Fragment children={props.children} />;
};

export default Interceptors;
