import store from '../../../store';
import {logoutUser} from '../../../auth/store/actions'

export const LogoutConfig = {
    routes: [
        {
            path: '/logout',
            component: () => {
                store.dispatch(logoutUser());
                return 'Logging out..'
            }
        }
    ]
};

