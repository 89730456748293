import { FuseAnimate } from '@fuse';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FuseSplashScreen from '../../../../@fuse/components/FuseSplashScreen/FuseSplashScreen';
import { useForm } from '../../../../@fuse/hooks';

import * as Actions from '../../../auth/store/actions';


const useStyles = makeStyles(theme => ({
  root: {
    background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
    color: theme.palette.primary.contrastText,
  },
}));

function Login(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  const { form, handleChange } = useForm({
    email: '',
    password: '',
    remember: true,
  });

  function isFormValid() {
    return (
      form.email.length > 0 &&
      form.password.length > 0
    );
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    dispatch(Actions.submitLogin(form));
  }

  if (login.loading && !login.error) {
    return <FuseSplashScreen/>;
  }

  return (
    <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>

      <div className="flex flex-col items-center justify-center w-full">

        <FuseAnimate animation="transition.expandIn">

          <Card className="w-full max-w-384">

            <CardContent className="flex flex-col items-center justify-center p-32">

              <img className="w-128 m-32" src="assets/images/logos/logo-plain.png" alt="logo"/>

              <Typography variant="subtitle1" color="inherit" className="font-light">
                Rubix Data Management
              </Typography>

              <Typography variant="subtitle2" color="inherit" className="max-w-512 font-light">
                Focus on the Job. Rubix will do the rest.
              </Typography>

              <Typography variant="h6" className="mt-32 mb-16">LOGIN TO YOUR ACCOUNT</Typography>

              <form
                name="loginForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit}
              >

                <TextField
                  id="email"
                  className="mb-16"
                  label="Email"
                  autoFocus
                  type="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  variant="outlined"
                  required
                  fullWidth
                />

                <TextField
                  id="password"
                  className="mb-16"
                  label="Password"
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  variant="outlined"
                  required
                  fullWidth
                />

                {login.error ? (
                  <Typography variant="subtitle2" color="error" className="mb-16 text-center">
                    Error: {login.error.message}
                  </Typography>
                ) : null}

                <div className="flex items-center justify-between">

                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="remember"
                          checked={form.remember}
                          onChange={handleChange}/>
                      }
                      label="Remember Me"
                    />
                  </FormControl>

                  <Link className="font-medium" to="/forgot-password">
                    Forgot Password?
                  </Link>
                </div>

                <Button
                  id="submit"
                  variant="contained" color="primary"
                  className="w-224 mx-auto mt-64 mb-64"
                  aria-label="LOG IN"
                  disabled={!isFormValid()}
                  type="submit"
                >
                  LOGIN
                </Button>

              </form>

            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default Login;
