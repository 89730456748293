import React from 'react';
import clsx from 'clsx';
import {ThemeProvider} from '@material-ui/styles';
import {useSelector} from 'react-redux';

function FusePageCardedHeader(props)
{
    const mainThemeDark = useSelector(({fuse}) => fuse.settings.mainThemeDark);

    return (
        <div className={clsx(props.classes.header, props.compact && props.classes.headerCompact)}>
            {props.header && (
                <ThemeProvider theme={mainThemeDark}>
                    {props.header}
                </ThemeProvider>
            )}
        </div>
    )
}

export default FusePageCardedHeader;
