import React from 'react';

export const SafetyAuthoritiesAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/safety-authorities',
      component: React.lazy(() => import('./SafetyAuthorityApp')),
    },
  ],
};
