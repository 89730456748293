import React from 'react';

export const CategoriesAppConfig = {
  routes: [
    {
      path: '/catalog/categories',
      component: React.lazy(() => import('./CategoriesApp')),
    },
  ],
};
