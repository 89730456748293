import React from 'react';

export const TaskTypesAppConfig = {
  routes: [
    {
      path: '/task-types',
      component: React.lazy(() => import('./TaskTypesApp')),
    },
  ],
};
