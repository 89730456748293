import React from "react";
import InputMask from "react-input-mask";

export function PhoneInput(props) {
    return (
        <InputMask mask="\+9 999 999 9999" value={props.value} onChange={props.onChange}>
            {(inputProps) => props.renderInput(inputProps)}
        </InputMask>
    );
}
