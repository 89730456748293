import React, {useState} from 'react';
import {Button, ListItemText, MenuItem, Popover, Badge, Typography, IconButton, Menu} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import * as Actions from 'app/modules/company/members/store/actions';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    read: {
        color: 'grey',
        '& span': {
            fontWeight: 'normal',
        }
    },
    unread: {
        color: 'black',
        '& span': {
            fontWeight: 'bold',
        }
    },
}));

function Notifications() {
    const dispatch = useDispatch();
    const user = useSelector(({auth}) => auth.user);
    const [notificationsMenu, setNotificationsMenu] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    let notifications = [];

    user.memberships.map(membership => membership.notifications?.map(notification => notifications.push(notification)));
    notifications = _.orderBy(notifications, ['dateCreated'], ['desc']);

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const notificationsMenuClick = event => {
        if (notifications.length > 0) {
            setNotificationsMenu(event.currentTarget);
        }
    };

    const notificationsMenuClose = () => {
        setNotificationsMenu(null);
    };

    const getLink = (notification) => {
        switch (notification.type) {
            case 'budget': return `/projects/${notification.options?.projectId}/budget`;
            case 'pdf-converted-to-jpeg': return `/projects/${notification.options?.projectId}/settings`;
            case 'budget-comment': return `/projects/${notification.options?.projectId}/budget#${notification.options?.eventId}`;
            case 'task': return '/tasks';
            case 'task-comment': return '/tasks';
            case 'task-file': return '/tasks';
            case 'production': return `/productions/inspect/${notification.entityId}`;
            case 'production-comment': return `/productions/inspect/${notification.entityId}#${notification.options?.eventId}`;
            case 'invoice-comment': return `/invoices/${notification.entityId}#${notification.options?.eventId}`;
            default: return '';
        }
    };

    const markAsRead = (notification) => {
        if (!notification.read) {
            dispatch(Actions.markNotificationAsRead(notification));
        }
        notificationsMenuClose();
    }

    const markAllAsRead = () => {
        const unreadNotifications = notifications.filter(notification => !notification.read);
        const membershipIds = unreadNotifications.map(notification => notification.memberId);
        if (unreadNotifications.length > 0) {
            (_.uniq(membershipIds)).forEach(membershipId => {
                dispatch(Actions.markAllNotificationsAsRead(membershipId));
            });
        }
        handleClose();
    }

    return (
        <React.Fragment>

            <Button onClick={notificationsMenuClick} className="rounded-none">
                <Badge badgeContent={notifications.filter(notification => !notification.read).length} color="error">
                    <NotificationsIcon />
                </Badge>
            </Button>

            <Popover
                open={Boolean(notificationsMenu)}
                anchorEl={notificationsMenu}
                onClose={notificationsMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: "py-8"
                }}
            >
                <React.Fragment>
                    <div className="flex pl-8">
                        <Typography color="inherit" className="text-16 font-bold flex-1">
                            Notifications
                        </Typography>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            size="small"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={() => markAllAsRead()}>
                                Mark all as read
                            </MenuItem>
                        </Menu>
                    </div>
                    {notifications ? notifications.map(notification => (
                        <MenuItem component={Link} to={getLink(notification)} key={notification.id} onClick={() => markAsRead(notification)}>
                            <ListItemText
                                classes={{ secondary: "text-12 flex justify-end"}}
                                className={clsx(notification.read ? classes.read : classes.unread, "pl-0")}
                                primary={notification.description}
                                secondary={moment(notification.dateCreated).format('MMMM DD YYYY h:mm:ss a')}
                            />
                        </MenuItem>
                    )) : null}
                </React.Fragment>
            </Popover>
        </React.Fragment>
    );
}

export default Notifications;
