import axios from "axios";
import {openErrorDialog, showSuccessMessage} from "app/store/actions/fuse";

export const GET_ALL_ROLES = '[ROLES APP] GET ALL ROLES';
export const GET_ASSIGNABLE_ROLES = '[ROLES APP] GET ASSIGNABLE ROLES';
export const OPEN_NEW_ROLE_DIALOG = '[ROLES APP] OPEN NEW ROLE DIALOG';
export const CLOSE_NEW_ROLE_DIALOG = '[ROLES APP] CLOSE NEW ROLE DIALOG';
export const OPEN_EDIT_ROLE_DIALOG = '[ROLES APP] OPEN EDIT ROLE DIALOG';
export const CLOSE_EDIT_ROLE_DIALOG = '[ROLES APP] CLOSE EDIT ROLE DIALOG';
export const ADD_ROLE = '[ROLES APP] ADD ROLE';
export const UPDATE_ROLE = '[ROLES APP] UPDATE ROLE';
export const REMOVE_ROLE = '[ROLES APP] REMOVE ROLE';

export function openNewRoleDialog() {
    return {
        type: OPEN_NEW_ROLE_DIALOG
    }
}

export function closeNewRoleDialog() {
    return {
        type: CLOSE_NEW_ROLE_DIALOG
    }
}

export function openEditRoleDialog(data) {
    return {
        type: OPEN_EDIT_ROLE_DIALOG,
        data
    }
}

export function closeEditRoleDialog() {
    return {
        type: CLOSE_EDIT_ROLE_DIALOG
    }
}

export function getAssignableRoles(entityType, entityId) {
    return (dispatch) => {
        return axios.get(`/roles/${entityType}/${entityId}/assignable`)
            .then((response) =>
                dispatch({
                    type: GET_ASSIGNABLE_ROLES,
                    payload: response.data,
                })
            ).catch(err =>
                dispatch(openErrorDialog(err.response))
            );
    }
}

export function getAllRoles(entityType, entityId) {
  return (dispatch) => {
    return axios.get(`/roles/${entityType}/${entityId}/all`)
      .then((response) =>
        dispatch({
          type: GET_ALL_ROLES,
          payload: response.data,
        })
      ).catch(err =>
        dispatch(openErrorDialog(err.response))
      );
  }
}

export function addRole(entityType, entityId, newRole) {
    return (dispatch) => {
        return axios.post(`/roles/${entityType}/${entityId}`, newRole)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: ADD_ROLE
                    }),
                    dispatch(closeNewRoleDialog()),
                    dispatch(showSuccessMessage({message: 'Role Created'})),
                ]).then(() =>
                    dispatch(getAssignableRoles(entityType, entityId))
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

export function updateRole(entityType, entityId, roleData) {
    return (dispatch) => {
        return axios.patch(`/roles/${entityType}/${entityId}/${roleData.id}`, roleData)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: UPDATE_ROLE
                    }),
                    dispatch(closeEditRoleDialog()),
                    dispatch(showSuccessMessage({message: 'Role Saved'})),
                ]).then(() =>
                    dispatch(getAssignableRoles(entityType, entityId))
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw err;
            });
    };
}

export function removeRole(entityType, entityId, roleId) {
    return (dispatch) => {
        return axios.delete(`/roles/${entityType}/${entityId}/${roleId}`)
            .then(() =>
                Promise.all([
                    dispatch({
                        type: REMOVE_ROLE
                    }),
                    dispatch(showSuccessMessage({message: 'Role Deleted'})),
                ]).then(() =>
                    dispatch(getAssignableRoles(entityType, entityId))
                )
            ).catch(err =>
                dispatch(openErrorDialog(err.response))
            );
    };
}

