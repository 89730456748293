import {Button, DialogActions, DialogContent} from "@material-ui/core";
import React from "react";
import {useDispatch} from "react-redux";
import * as Actions from 'app/store/actions';

function ImageDialog(props) {
    const dispatch = useDispatch();

    const file = props?.image;
    return (
        <React.Fragment>
            <DialogContent>
                <img src={file.url} alt={file.filename} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(Actions.closeDialog())} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </React.Fragment>
    );
}

export default ImageDialog;
