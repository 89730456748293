import mock from './../mock';
import {FuseUtils} from '@fuse';
import _ from '@lodash';

const projectsDB = {
    projects: [
    	{
            'id'      		  : '5725a680b3249760ea21de52',
            'code'    		  : '1234567',
            'projectNumber'   : '789',
            'country' 		  : 'US',
            'state'	  		  : 'AL',
            'city'    		  : 'City1',
            'start'   		  : '2020-01-01',
            'end'     		  : '2020-01-05',
            'status'  		  : 'pending'
        },
        {
            'id'      		  : '5725a680b3249760ea21de53',
            'code'    		  : '9876543',
            'projectNumber'   : '789',
            'country' 		  : 'US',
            'state'	  		  : 'AL',
            'city'    		  : 'City2',
            'start'   		  : '2020-01-03',
            'end'     		  : '2020-01-08',
            'status'  		  : 'pending'
        },
    ]
}

mock.onGet('/api/projects').reply((config) => {
    return [200, projectsDB.projects];
});

mock.onGet('/api/projects/5725a680b3249760ea21de52').reply((config) => {
    return [200, projectsDB.projects[0]];
});

mock.onPost('/api/projects/add').reply((request) => {
    const data = JSON.parse(request.data);
    const id = FuseUtils.generateGUID();

    projectsDB.projects = [
        ...projectsDB.projects, {
            ...data,
            id: id
        }
    ];
    const project = _.find(projectsDB.projects, { 'id': id });

    return [200, project];
});

mock.onPost('/api/projects/delete').reply((request) => {
    const id = request.data;

    projectsDB.projects = projectsDB.projects.filter((project) => project.id !== id);

    return [200];
});

mock.onPost('/api/projects/5725a680b3249760ea21de52').reply((request) => {
    const data = JSON.parse(request.data);
    projectsDB.projects = projectsDB.projects.map(_project => {
        if ( _project.id === data.id )
        {
            return _.merge(_project, data);
        }
        return _project;
    });

    return [200, data];
});
