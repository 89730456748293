import axios from 'axios';
import {handleCatch} from 'app/store/actions';

export const GET_PRODUCTIONS = '[PRODUCTION APP] GET PRODUCTIONS';

export const getProductions = (payload) => (dispatch) =>
    axios.get(payload.filter ? `/production?filter=${payload.filter}` : '/production').then(
        (response) => dispatch({
            type: GET_PRODUCTIONS,
            payload: payload.companyId ? response.data.map(p => ({
                    ...p,
                    status: p.reviews.find(r => r.companyId === payload.companyId).status
                })) : response.data,
        }),
        handleCatch(dispatch),
    );
