import mock from "./mock";
import "./db/contacts-db";
import "./db/search-db";
import "./db/analytics-dashboard-db";
import "./db/project-dashboard-db";
import "./db/project-notifications";
import "./db/quick-panel-db";
import "./db/chat-db";
import "./db/projects-db";
import "./db/map-db";

mock.onAny().passThrough();
