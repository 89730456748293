import _ from '@lodash';
import jwtService from 'app/services/jwtService';
import axios from 'axios';
import history from '../../../../@history';
import * as Actions from './user.actions';

export const USER_REGISTER_START = 'USER_REGISTER_START';
export const USER_REGISTER_END = 'USER_REGISTER_END';
export const COMPANY_REGISTER_START = 'COMPANY_REGISTER_START';
export const COMPANY_REGISTER_END = 'COMPANY_REGISTER_END';

export function registerUser({name, password, email, phone}, token) {
  return (dispatch) => {
    dispatch({
      type: USER_REGISTER_START,
    });
    return jwtService.createUser({
      name: name,
      phone: phone,
      email: email,
      password: password,
    }, token)
      .then(user => {
        dispatch(Actions.setUserData(user));
        return dispatch({
          type: USER_REGISTER_END,
          success: true,
        });
      })
      .catch(error => {
        return dispatch({
          type: USER_REGISTER_END,
          error,
        });
      });
  };
}

export function registerCompany(newCompany, redirect = true) {
  const company = _.pick(newCompany, [
    'ein',
    'name',
    'address',
    'country',
    'state',
    'city',
    'zip',
    'contactName',
    'contactPhone',
    'contactEmail',
  ]);

  const registerData = {
    companyData: company,
  };
  return (dispatch) => {
    dispatch({
      type: COMPANY_REGISTER_START,
    });
    return axios.post('/companies', registerData)
      .then(({data}) => {
        dispatch(Actions.setCompany(data));
        if (redirect) {
          history.replace({pathname: `/companies/${data.id}`});
        }
        return dispatch({
          type: COMPANY_REGISTER_END,
          success: true,
        });
      })
      .catch(error => {
        console.log(error);
        return dispatch({
          type: COMPANY_REGISTER_END,
          error: error.response.data.error,
        });
      });
  };
}
