import * as Actions from '../actions';

const initialState = {
  project: null,
  crew: null,
  task: null,
  productions: null,
  traces: [],
  projects: [],
  crews: [],
  pdfImages: [],
  memberNames: [],
  projectDialog: {
    props: {
      open: false,
      task: null,
    },
  },
  mapBox: {},
};

const map = function (state = initialState, action) {
  switch (action.type) {
    case Actions.PRODUCTION_ADD: {
      return {
        ...state,
        productions: [...(state.productions ?? []), action.payload],
      };
    }
    case Actions.PRODUCTION_GET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case Actions.PRODUCTION_GET_PRODUCTIONS: {
      return {
        ...state,
        productions: action.payload,
      };
    }
    case Actions.PRODUCTION_GET_TRACES: {
      return {
        ...state,
        traces: action.payload,
      };
    }
    case Actions.PRODUCTION_GET_CREW: {
      return {
        ...state,
        crew: action.payload,
      };
    }
    case Actions.PRODUCTION_GET_TASK: {
      return {
        ...state,
        task: action.payload,
      };
    }
    case Actions.PRODUCTION_GET_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case Actions.PRODUCTION_GET_CREWS: {
      return {
        ...state,
        crews: action.payload,
      };
    }
    case Actions.PRODUCTION_PROJECT_SETUP_OPEN_DIALOG: {
      return {
        ...state,
        projectDialog: {
          props: {
            open: true,
            task: action.payload,
          },
        },
      };
    }
    case Actions.PRODUCTION_PROJECT_SETUP_CLOSE_DIALOG: {
      return {
        ...state,
        projectDialog: {
          ...initialState.projectDialog,
        },
      };
    }
    case Actions.PRODUCTION_GET_MEMBER: {
      return {
        ...state,
        memberIds: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default map;
