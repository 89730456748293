import React from "react";
import InputMask from "react-input-mask";

export function EinInput(props) {
    return (
        <InputMask mask="99-9999999" value={props.value} onChange={props.onChange}>
            {(inputProps) => props.renderInput(inputProps)}
        </InputMask>
    );
}
