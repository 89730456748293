import mock from './../mock';

const projectNotifications = [{
    membership: {
        "id": "5e8cc8085c58dc2ce04a84e6",
        "name": "Client",
        "email": "baciu.aalexandru@gmail.com",
        "phone": "+1 215 125 2152",
        "country": "US",
        "state": "DC",
        "status": "accepted",
        "dateCreated": "2020-04-07T18:35:52.429Z",
        "type": "project",
        "roleId": "5e8b5025b5d149102e128bcd",
        "companyId": "5e8b5025b5d149102e128bcc",
        "projectId": "5e8cc7de5c58dc2ce04a84df",
        "role": {
            "id": "5e8b5025b5d149102e128bcd",
            "name": "Owner",
            "permissions": [{
                "resourceType": "company",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "roles",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "members",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "clients",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "subcontractors",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "crews",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "items",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {
                "resourceType": "projects",
                "action": "_any",
                "resourceId": "5e8b5025b5d149102e128bcc"
            }, {"resourceType": "tasks", "action": "_any", "resourceId": "5e8b5025b5d149102e128bcc"}],
            "companyId": "5e8b5025b5d149102e128bcc"
        },
        "company": {
            "id": "5e8b5025b5d149102e128bcc",
            "ein": "99-9999999",
            "name": "Client Inc",
            "address": "101 Independence Avenue Southeast",
            "city": "Washington",
            "state": "DC",
            "zip": "20540",
            "country": "US",
            "contactName": "Client",
            "contactPhone": "+1 521 251 5215",
            "contactEmail": "client@client.com",
            "settings": {}
        }
    },
    settings: {'Reports': true, 'Delays': false, 'Others': false}
}, {
    membership: {
        "id": "5e8cc7de5c58dc2ce04a84e0",
        "name": "Alexandru Baciu",
        "email": "alexandru.baciu@intellcoreinc.com",
        "phone": "+1 521 521 5251",
        "country": "US",
        "state": "MI",
        "status": "accepted",
        "type": "project",
        "roleId": "5e8b4f23b5d149102e128bbb",
        "companyId": "5e8b4f23b5d149102e128bba",
        "projectId": "5e8cc7de5c58dc2ce04a84df",
        "role": {
            "id": "5e8b4f23b5d149102e128bbb",
            "name": "Owner",
            "permissions": [{
                "resourceType": "company",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "roles",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "members",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "clients",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "subcontractors",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "crews",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "items",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {
                "resourceType": "projects",
                "action": "_any",
                "resourceId": "5e8b4f23b5d149102e128bba"
            }, {"resourceType": "tasks", "action": "_any", "resourceId": "5e8b4f23b5d149102e128bba"}],
            "companyId": "5e8b4f23b5d149102e128bba"
        },
        "company": {
            "id": "5e8b4f23b5d149102e128bba",
            "ein": "51-5215151",
            "name": "Intellcore Inc Data Management",
            "address": "5591 Cogswell Road",
            "city": "Wayne",
            "state": "MI",
            "zip": "48184",
            "country": "US",
            "contactName": "Alexandru Baciu",
            "contactPhone": "+1 521 521 5215",
            "contactEmail": "alexandru.baciu@intellcoreinc.com",
            "logoUrl": "http://localhost:3200/api/v1/files/5e8caa64cc8eb225caf29452",
            "settings": {
                "images": [{
                    "id": "5e8caa64cc8eb225caf29452",
                    "length": 9252,
                    "chunkSize": 261120,
                    "uploadDate": "2020-04-07T16:29:24.542Z",
                    "filename": "image (1) (2).png",
                    "md5": "b884efb933ce530c7415de58fc193a75",
                    "contentType": "image/png",
                    "metadata": {"entityType": "Company", "entityId": "5e8b4f23b5d149102e128bba"},
                    "url": "http://localhost:3200/api/v1/files/5e8caa64cc8eb225caf29452",
                    "entityType": "Company",
                    "entityId": "5e8b4f23b5d149102e128bba"
                }, {
                    "id": "5e8cf8de451a63390b0441f9",
                    "length": 1406,
                    "chunkSize": 261120,
                    "uploadDate": "2020-04-07T22:04:14.209Z",
                    "filename": "UG20.png",
                    "md5": "c4adaa90ed78d4cee8f0335b40457da3",
                    "contentType": "image/png",
                    "metadata": {"entityType": "Company", "entityId": "5e8b4f23b5d149102e128bba"},
                    "url": "http://localhost:3200/api/v1/files/5e8cf8de451a63390b0441f9",
                    "entityType": "Company",
                    "entityId": "5e8b4f23b5d149102e128bba"
                }, {
                    "id": "5e8cf8ea451a63390b0441fb",
                    "length": 143212,
                    "chunkSize": 261120,
                    "uploadDate": "2020-04-07T22:04:26.691Z",
                    "filename": "unnamed.png",
                    "md5": "35d46b1dea8b0eb532b80c73448f6d24",
                    "contentType": "image/png",
                    "metadata": {"entityType": "Company", "entityId": "5e8b4f23b5d149102e128bba"},
                    "url": "http://localhost:3200/api/v1/files/5e8cf8ea451a63390b0441fb",
                    "entityType": "Company",
                    "entityId": "5e8b4f23b5d149102e128bba"
                }]
            }
        }
    },
    settings: {'Reports': true, 'Delays': false, 'Others': true}
}];

mock.onGet(/^\/projects\/([^/]+)\/notifications$/).reply((config) => {
    return [200, projectNotifications];
});

mock.onPatch(/^\/projects\/([^/]+)\/notifications$/).reply((config) => {
    return [200];
});