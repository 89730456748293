import React from 'react';

export const TasksAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        // {
        //     path: [
        //         '/tasks/:taskId/:sectionId',
        //         '/tasks/:taskId'
        //     ],
        //     component: React.lazy(() => import('./task/TaskApp'))
        // },
        // {
        //     path: '/tasks/:taskId',
        //     component: React.lazy(() => import('./task/TaskApp'))
        // },
        {
            path: '/tasks',
            component: React.lazy(() => import('./TasksApp'))
        },
    ]
};
