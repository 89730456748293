import {reloadUserData} from 'app/auth/store/actions';
import {handleCatch, showSuccessMessage} from 'app/store/actions';
import axios from 'axios';
import {saveAs} from 'file-saver';
import moment from 'moment';

export const GET_PRODUCTION = '[PRODUCTION APP] GET PRODUCTION';
export const GET_PRODUCTION_ACTIVITY = '[PRODUCTION APP] GET PRODUCTION ACTIVITY';
export const GET_PRODUCTION_ITEM_FILES = '[PRODUCTION APP] GET PRODUCTION ITEM FILES';
export const UPLOAD_PRODUCTION_ITEM_FILE = '[PRODUCTION APP] UPLOAD PRODUCTION ITEM FILE';
export const DELETE_PRODUCTION_ITEM_FILE = '[PRODUCTION APP] DELETE PRODUCTION ITEM FILE';
export const OPEN_PRODUCTION_REVIEW_REJECT_DIALOG = '[PRODUCTION APP] OPEN PRODUCTION REJECT DIALOG';
export const CLOSE_PRODUCTION_REVIEW_REJECT_DIALOG = '[PRODUCTION APP] CLOSE PRODUCTION REJECT DIALOG';
export const SET_FILE_UPLOAD_PROGRESS = '[PRODUCTION APP] SET FILE UPLOAD PROGRESS';
export const OPEN_PRODUCTION_ADD_ITEM_DIALOG = '[PRODUCTION APP] OPEN PRODUCTION ADD ITEM DIALOG';
export const CLOSE_PRODUCTION_ADD_ITEM_DIALOG = '[PRODUCTION APP] CLOSE PRODUCTION ADD ITEM DIALOG';
export const CLEANUP_PRODUCTION = '[PRODUCTION APP] CLEANUP PRODUCTION';
export const SET_DAILY_PRODUCTION_SHEET_DOWNLOAD_STATE = '[PRODUCTION APP] SET DAILY PRODUCTION SHEET DOWNLOAD STATE';

export const getProduction = (productionId) => (dispatch) =>
  axios.get(`/production/${productionId}`).then(
    (response) =>
      dispatch({
        type: GET_PRODUCTION,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const approveProduction = (productionId, sendEmail) => (dispatch) =>
  axios.post(`/production/${productionId}/approve`, {
    sendEmail
  }).then((response) => {
    dispatch(getProduction(productionId));
    dispatch(reloadUserData());
    return response;
  }, handleCatch(dispatch));

export const rejectProduction = (productionId, reason) => (dispatch) =>
  axios.post(`/production/${productionId}/reject`, {reason}).then((response) => {
    dispatch(getProduction(productionId));
    dispatch(closeProductionReviewRejectDialog());
    dispatch(reloadUserData());
    return response;
  }, handleCatch(dispatch));

export const addProductionComment = (productionId, data) => (dispatch) =>
  axios.post(`/production/${productionId}/comments`, data).then((response) => {
    dispatch(getProductionActivity(productionId));
    dispatch(reloadUserData());
    return response;
  }, handleCatch(dispatch));

export const getProductionActivity = (productionId) => (dispatch) =>
  axios.get(`/production/${productionId}/activity`).then(
    (response) =>
      dispatch({
        type: GET_PRODUCTION_ACTIVITY,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const uploadProductionItemFile = (itemId, files, toItem = true) => (dispatch) => {
  const form = new FormData();

  for (const file of files) {
    form.append('file[]', file);
  }

  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (percentCompleted < 100) {
        dispatch({
          type: SET_FILE_UPLOAD_PROGRESS,
          payload: percentCompleted,
        });
      }
    },
  };
  const url = toItem ? `/production/item/${itemId}/files` : `/production/${itemId}/files`;
  return axios.post(url, form, config).then((response) => {
    dispatch(showSuccessMessage({message: 'File uploaded'}));
    dispatch({
      type: SET_FILE_UPLOAD_PROGRESS,
      payload: 100,
    });
    return response.data;
  }, handleCatch(dispatch));
};

export const deleteProductionItemFile = (itemId, file) => (dispatch) =>
  axios.delete(`/files/${file.id}`).then(
    () =>
      Promise.all([dispatch(showSuccessMessage({message: 'File deleted'}))]).then(
        dispatch({
          type: DELETE_PRODUCTION_ITEM_FILE,
          payload: file,
        }),
      ),
    handleCatch(dispatch),
  );

export const openProductionReviewRejectDialog = (production) => ({
  type: OPEN_PRODUCTION_REVIEW_REJECT_DIALOG,
  production,
});

export const closeProductionReviewRejectDialog = () => ({
  type: CLOSE_PRODUCTION_REVIEW_REJECT_DIALOG,
});

export const updateStations = (production, stations) => (dispatch) =>
  axios.patch(`production/${production.id}/stations`, stations).then((response) => {
    dispatch(showSuccessMessage({message: 'Stations updated'}));
    return dispatch({
      type: GET_PRODUCTION,
      payload: response.data,
    });
  }, handleCatch(dispatch));

export const openAddItemDialog = (production, fetchActivity = false) => ({
  type: OPEN_PRODUCTION_ADD_ITEM_DIALOG,
  production,
  fetchActivity
});

export const closeAddItemDialog = () => ({
  type: CLOSE_PRODUCTION_ADD_ITEM_DIALOG,
});

export const cleanupProduction = () => ({
  type: CLEANUP_PRODUCTION,
});

export const downloadDailyProductionSheet = (production) => (dispatch) => {
  const baseURL = axios.defaults.baseURL;
  return Promise.all([
    dispatch({
      type: SET_DAILY_PRODUCTION_SHEET_DOWNLOAD_STATE,
      payload: true,
    }),
  ]).then(
    axios
      .post(`${baseURL}/productions/${production.id}/report/download`, {}, {responseType: 'blob'})
      .then((response) => {
        const filename =
          'Daily_Production_Sheet_' +
          production.project.code +
          '_' +
          moment(production.date).format('MMDDYYYY') +
          '.xlsx';
        saveAs(
          new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
          filename,
        );
        dispatch({
          type: SET_DAILY_PRODUCTION_SHEET_DOWNLOAD_STATE,
          payload: false,
        });
      }, handleCatch(dispatch))
      .catch(() =>
        dispatch({
          type: SET_DAILY_PRODUCTION_SHEET_DOWNLOAD_STATE,
          payload: false,
        }),
      ),
  );
};
