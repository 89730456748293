import {red} from '@material-ui/core/colors';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import {fuseDark} from '@fuse/fuse-colors';
import rubixColors from "../../@rubix/rubixColors";

const themesConfig = {
    default: {
        palette: {
            type: 'light',
            primary: {
                ...rubixColors,
                lightBlue: lighten('#4984B7', .25),
                blue: '#4984B7',
                darkBlue: darken('#4984B7', .25),
            },
            secondary: {
                light: lighten('#F57F20', .25), // #c66ec6 rgb(198, 110, 198)
                main: '#F57F20',
                dark: darken('#FFFFFF', .25), // #862e87 rgb(134, 46, 135)
                contrastText: '#FFFFFF'
            },
            error: red
        },
        status: {
            danger: 'orange'
        }
    },
    sunset: {
        palette: {
            type: 'light',
            primary: {
                light: '#7e5dff',
                main: '#4eb2d0',
                dark: '#593e9b'
            },
            secondary: {
                light: '#b40fc7',
                main: '#997eff',
                dark: '#7d8fff',
                contrastText: '#d5d5d5'
            },
            error: red
        },
        status: {
            danger: 'orange'
        }
    },
    greeny: {
        palette: {
            type: 'light',
            primary: {
                light: '#6cabd4',
                main: '#387CA3',
                dark: 'rgba(111,64,180,0.88)'
            },
            secondary: {
                light: '#a3f6f1',
                main: '#7ba7c3',
                dark: '#77b4ff',
                contrastText: '#FFF'
            },
            error: red
        },
        status: {
            danger: 'orange'
        }
    },
    beach: {
        palette: {
            type: 'light',
            primary: {
                light: '#C4D8DD',
                main: '#93A7AB',
                dark: '#65787C',
                contrastText: '#FFF'
            },
            secondary: {
                light: 'rgba(126,7,168,0.81)',
                main: '#9671f1',
                dark: '#4a4a4b',
                contrastText: '#b33eb4'
            }
        }
    },
    tech: {
        palette: {
            type: 'light',
            primary: {
                light: '#87EFFF',
                main: '#4DBCE9',
                dark: '#008CB7',
                contrastText: '#FFF'
            },
            secondary: {
                light: '#3b3e44',
                main: '#e706d8',
                dark: '#39373b'
            }
        }
    },
    sweetHues: {
        palette: {
            type: 'light',
            primary: {
                light: '#D5C1EB',
                main: '#A391B9',
                dark: '#746389',
                contrastText: '#FFF'
            },
            secondary: {
                light: '#90AFD4',
                main: '#6080A3',
                dark: '#325474'
            }
        }
    },
    defaultDark: {
        palette: {
            type: 'dark',
            primary: fuseDark,
            secondary: {
                light: '#b33eb4',
                main: '#b33eb4',
                dark: '#b33eb4',
            },
            error: red
        },
        status: {
            danger: 'orange'
        }
    },
    deepOcean: {
        palette: {
            type: 'dark',
            primary: {
                light: 'rgba(20,18,20,0.83)',
                main: '#b33eb4',
                dark: '#363437'
            },
            secondary: {
                light: '#58e9ff',
                main: '#686cfe',
                dark: 'rgba(27,26,28,0.87)',
                contrastText: '#ffffff'
            }
        }
    },
    slate: {
        palette: {
            type: 'dark',
            primary: {
                light: '#86FFF7',
                main: '#4ECDC4',
                dark: 'rgba(20,20,21,0.89)'
            },
            secondary: {
                light: '#5aa8ff',
                main: '#8179ff',
                dark: '#232024',
                contrastText: '#ff15f4'
            }
        }
    }
};

export default themesConfig;
