import _ from '@lodash';
import { reloadUserData } from 'app/auth/store/actions';
import { handleCatch, showSuccessMessage } from 'app/store/actions/fuse';
import axios from 'axios';

export const GET_PROJECT = '[TASKS APP] GET PROJECT';
export const GET_PROJECTS = '[TASKS APP] GET PROJECTS';
export const GET_PROJECT_BUDGET = '[PROJECT APP] GET PROJECT BUDGET';
export const GET_TASKS = '[TASKS APP] GET TASKS';
export const GET_TASK_ACTIVITY = '[TASKS APP] GET TASK ACTIVITY';
export const GET_TASK_FILES = '[TASKS APP] GET TASK FILES';
export const ADD_TASK = '[TASKS APP] ADD TASK';
export const UPDATE_TASK = '[TASKS APP] UPDATE TASK';
export const REMOVE_TASK = '[TASKS APP] REMOVE TASK';
export const OPEN_ADD_TASK_DIALOG = '[TASKS APP] OPEN ADD TASK DIALOG';
export const CLOSE_ADD_TASK_DIALOG = '[TASKS APP] CLOSE ADD TASK DIALOG';
export const OPEN_EDIT_TASK_DIALOG = '[TASKS APP] OPEN EDIT TASK DIALOG';
export const CLOSE_EDIT_TASK_DIALOG = '[TASKS APP] CLOSE EDIT TASK DIALOG';
export const OPEN_ADD_TASK_PRODUCTION_DIALOG = '[TASKS APP] OPEN ADD TASK PRODUCTION DIALOG';
export const CLOSE_ADD_TASK_PRODUCTION_DIALOG = '[TASKS APP] CLOSE ADD TASK PRODUCTION DIALOG';
export const GET_TASK = '[TASKS APP] GET TASK';
export const SET_FILE_UPLOAD_PROGRESS = '[TASKS APP] SET FILE UPLOAD PROGRESS';
export const CLEANUP_TASK_DIALOG = '[TASKS APP] CLEANUP TASK DIALOG';

export const getProject = (projectId) => (dispatch) =>
  axios.get(`/projects/${projectId}`).then(
    (response) => dispatch(setProject(response.data)),
    handleCatch(dispatch),
  );

export const setProject = (data) => ({
  type: GET_PROJECT,
  payload: data,
});

export const getProjects = (companyId) => (dispatch) =>
  axios.get(`/companies/${companyId}/projects/all`).then(
    (response) =>
      dispatch({
        type: GET_PROJECTS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getTasks = () => (dispatch, getState) => {
  const projectId = getState().tasksApp.tasks.projectId;
  const url = projectId ? `/projects/${projectId}/tasks` : `/tasks`;
  return axios.get(url).then(
    (response) =>
      dispatch({
        type: GET_TASKS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );
};

export const addTask = (project, newTask) => (dispatch) => {
  const task = _.pick(newTask, [
    'typeId',
    'crewId',
    'summary',
    'description',
    'address',
    'city',
    'state',
    'country',
    'startDate',
    'endDate',
    'items',
    'status',
    'priority'
  ]);
  task.items = task.items.map(item => ({ ...item, quantity: parseFloat(item.quantity) }));

  return axios.post(`/projects/${project.id}/tasks`, task).then(
    ({ data }) =>
      Promise.all([
        dispatch({
          type: ADD_TASK,
          payload: data,
        }),
        // upload files
        ...newTask.files.map(file => dispatch(uploadTaskFile(data.id, file.file, 'new'))),
        // show message
        dispatch(showSuccessMessage({ message: 'Task Created' })),
        // close dialog
        dispatch(closeAddTaskDialog()),
      ]).then(() =>
          dispatch(getTasks()),
        dispatch(reloadUserData()),
      ),
    handleCatch(dispatch),
  );
};

export const updateTask = (project, taskData) => (dispatch) => {
  const task = _.pick(taskData, [
    'typeId',
    'crewId',
    'summary',
    'description',
    'address',
    'city',
    'state',
    'country',
    'startDate',
    'endDate',
    'items',
    'status',
    'priority'
  ]);
  task.items = task.items.map(item => ({ ...item, quantity: parseFloat(item.quantity) }));

  return axios.patch(`/tasks/${taskData.id}`, task).then(
    response =>
      Promise.all([
        dispatch(showSuccessMessage({ message: 'Task Saved' })),
        dispatch(closeEditTaskDialog()),
        dispatch({
          type: UPDATE_TASK,
          payload: response.data,
        }),
      ]).then(() =>
          dispatch(getTasks()),
        dispatch(reloadUserData()),
      ),
    handleCatch(dispatch),
  );
};

export const removeTask = (projectId, taskId) => dispatch =>
  axios.delete(`/tasks/${taskId}`).then(
    () =>
      Promise.all([
        dispatch({
          type: REMOVE_TASK,
          payload: taskId,
        }),
        dispatch(showSuccessMessage({ message: 'Task deleted' })),
      ]).then(() => dispatch(getTasks(projectId))),
    handleCatch(dispatch),
  );

export const openAddTaskDialog = () => ({
  type: OPEN_ADD_TASK_DIALOG,
});

export const closeAddTaskDialog = () => ({
  type: CLOSE_ADD_TASK_DIALOG,
});

export const openEditTaskDialog = (data) => ({
  type: OPEN_EDIT_TASK_DIALOG,
  payload: data,
});

export const closeEditTaskDialog = () => ({
  type: CLOSE_EDIT_TASK_DIALOG,
});

export const addTaskComment = (taskId, data) => (dispatch) =>
  axios.post(`/tasks/${taskId}/comments`, data).then(
    response => {
      dispatch(getTaskActivity(taskId)).then(() => {
        dispatch(reloadUserData());
      });
      return response;
    },
    handleCatch(dispatch),
  );

export const getTaskActivity = (taskId) => (dispatch) =>
  axios.get(`/tasks/${taskId}/activity`).then(
    (response) =>
      dispatch({
        type: GET_TASK_ACTIVITY,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const uploadTaskFile = (taskId, file, type = 'edit') => (dispatch) => {
  const form = new FormData();
  form.append('file', file);
  form.append('type', type);
  const config = {
    onUploadProgress: function(progressEvent) {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (percentCompleted < 100) {
        dispatch({
          type: SET_FILE_UPLOAD_PROGRESS,
          payload: percentCompleted,
        });
      }
    }
  }
  return axios.post(`/tasks/${taskId}/files`, form, config).then(
      response => {
        dispatch(showSuccessMessage({ message: 'File uploaded' }));
        dispatch(getTaskFiles(taskId));
        dispatch({
          type: SET_FILE_UPLOAD_PROGRESS,
          payload: 100,
        });
        return response.data;
      },
      handleCatch(dispatch),
    );
};

export const deleteTaskFile = (taskId, file) => dispatch =>
  axios.delete(`/files/${file.id}`).then(
    () => Promise.all([
      dispatch(showSuccessMessage({ message: 'File deleted' })),
    ]).then(
      dispatch(getTaskFiles(taskId)),
    ),
    handleCatch(dispatch),
  );

export const getTaskFiles = (taskId) => (dispatch) =>
  axios.get(`/tasks/${taskId}/files`).then(
    (response) =>
      dispatch({
        type: GET_TASK_FILES,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getTask = (taskId) => (dispatch) =>
  axios.get(`/tasks/${taskId}`).then(
    (response) => dispatch(setTask(response.data)),
    handleCatch(dispatch),
  );

export const setTask = (data) => ({
  type: GET_TASK,
  payload: data,
});

export const cleanupTaskDialog = () => ({
  type: CLEANUP_TASK_DIALOG,
});
