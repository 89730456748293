import React from 'react';

export const FileManagerAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/files/preview/:fileId/:projectId',
            component: React.lazy(() => import('./FilePreviewer'))
        },
        {
            path     : '/files/:projectId?/:type?/:entityId?',
            component: React.lazy(() => import('./FileManagerApp'))
        },
    ]
};
