import {default as routesConfig} from '../../../configs/routesConfig';

const initialState = routesConfig;

const routes = function (state = initialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default routes;
