import * as Actions from '../actions';

const initialState = {
  log: null,
};

const log = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LOG: {
      return {
        ...state,
        log: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default log;
