import * as Actions from '../actions';
import _ from '@lodash';

const initialState = {
    entities: null,
    company: null,
    routeParams: {},
    companyDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null,
    },
    lastAdded: null,
    partners: null,
};

const companiesReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_COMPANIES: {
            return {
                ...state,
                entities: _.keyBy(action.payload, 'id')
            };
        }
        case Actions.GET_COMPANY: {
            return {
                ...state,
                company: action.payload
            };
        }
        case Actions.UPDATE_COMPANY: {
            return {
                ...state,
                company: action.payload
            };
        }
        case Actions.ADD_COMPANY: {
            return {
                ...state,
                lastAdded: action.payload
            };
        }
        case Actions.UPLOAD_LOGO: {
            return {
                ...state,
                company: {
                    ...action.company,
                    settings: {
                        ...action.company.settings,
                        images: [
                            ...(action.company.settings.images || []),
                            action.payload
                        ]
                    }
                },
            };
        }
        case Actions.OPEN_NEW_COMPANY_DIALOG: {
            return {
                ...state,
                companyDialog: {
                    type: 'new',
                    props: {
                        open: true
                    },
                    data: null
                }
            };
        }
        case Actions.CLOSE_NEW_COMPANY_DIALOG: {
            return {
                ...state,
                companyDialog: {
                    type: 'new',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.OPEN_EDIT_COMPANY_DIALOG: {
            return {
                ...state,
                companyDialog: {
                    type: 'edit',
                    props: {
                        open: true
                    },
                    data: {
                        ...action.data
                    }
                }
            };
        }
        case Actions.CLOSE_EDIT_COMPANY_DIALOG: {
            return {
                ...state,
                companyDialog: {
                    type: 'edit',
                    props: {
                        open: false
                    },
                    data: null
                }
            };
        }
        case Actions.GET_PARTNERS: {
            return {
                ...state,
                partners: action.payload ? [
                    {id: 'all', name: 'All'},
                    ...action.payload,
                ] : [],
            };
        }
        default: {
            return state;
        }
    }
};

export default companiesReducer;
