import {combineReducers} from 'redux';
import invoices from './invoices.reducer';
import invoice from './invoice.reducer';

const reducer = combineReducers({
    invoices,
    invoice
});

export default reducer;
