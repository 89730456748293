import {FuseAnimate} from '@fuse';
import FuseSplashScreen from '@fuse/components/FuseSplashScreen/FuseSplashScreen';
import useForm from '@fuse/hooks/useForm';
import {Button, Card, CardContent, Divider, Icon, InputAdornment, TextField, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {darken} from '@material-ui/core/styles/colorManipulator';
import {makeStyles} from '@material-ui/styles';
import {PhoneInput} from '@rubix/form';
import * as Actions from 'app/auth/store/actions';
import {getPartnershipInvitation} from 'app/modules/company/invitations/store/actions';
import {getMemberInvitation} from 'app/modules/company/members/store/actions';
import clsx from 'clsx';
import queryString from 'query-string';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'radial-gradient(' + darken(theme.palette.primary.dark, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
    color: theme.palette.primary.contrastText,
  },
}));

const defaultFormState = {
  name: '',
  phone: '1',
  email: '',
  password: '',
  passwordConfirm: '',
};

function RegisterUser() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({auth}) => auth.register.user);
  const {form, handleChange, setForm, handleCapitalizeChange} = useForm(defaultFormState);
  const loginUrl = `/login${window.location.search}`;
  const partnerInvitation = useSelector(({invitationApp}) => invitationApp.partnership.invitation);
  const memberInvitation = useSelector(({membersApp}) => membersApp.invitation.invitation);
  const [invited, setInvited] = useState(false);
  const [token, setToken] = useState(null);
  // parse returnTo path to find out if we're dealing with an invitation
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.returnTo) {
      const parts = query.returnTo.split('/');
      switch (parts[1]) {
        case 'partner-invitation':
          setInvited(true);
          dispatch(getPartnershipInvitation(parts[2]));
          break;
        case 'member-invitation':
          setInvited(true);
          setToken(parts[2]);
          dispatch(getMemberInvitation(parts[2]));
          break;
        default:
          setInvited(false);
          break;
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (partnerInvitation) {
      if (partnerInvitation.clientId && partnerInvitation.subcontractorId) {
        window.location.replace(loginUrl);
      }
      setForm({
        ...defaultFormState,
        name: partnerInvitation[`${partnerInvitation.guestType}ContactName`],
        email: partnerInvitation[`${partnerInvitation.guestType}ContactEmail`],
      });
    }
    if (memberInvitation) {
      setForm({
        ...defaultFormState,
        name: memberInvitation.name,
        email: memberInvitation.email,
        phone: memberInvitation.phone,
      });
    }
  }, [partnerInvitation, memberInvitation, setForm, loginUrl]);

  const canSubmit = () => {
    return (
      form.name.length > 5 &&
      form.phone.length > 0 &&
      form.email.length > 0 &&
      form.password.length >= 6 &&
      form.password === form.passwordConfirm
    );
  };

  function handleSubmit(ev) {
    ev.preventDefault();
    dispatch(Actions.registerUser(form, token));
  }

  if ((user.loading && !user.error) || (invited && !(partnerInvitation || memberInvitation))) {
    return <FuseSplashScreen />;
  }

  return (
    <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>

      <div className="flex flex-col items-center justify-center w-full">

        <FuseAnimate animation="transition.expandIn">

          <Card className="w-full max-w-512">

            <CardContent className="flex flex-col items-center justify-center p-32">

              <img className="w-128 m-16" src="assets/images/logos/logo-plain.png" alt="logo" />

              <Typography variant="overline" className="mb-16 font-light">
                Focus on the Job. Rubix will do the rest.
              </Typography>

              <Typography variant="h6" className="mb-16">CREATE ACCOUNT</Typography>

              <form
                name="registerUser"
                onSubmit={handleSubmit}
                className="flex flex-col justify-center w-full"
              >
                <Grid container spacing={2}>

                  <Grid item sm={12} xs={12}>
                    <TextField
                      type="text"
                      name="name"
                      label="Display name"
                      value={form.name}
                      onChange={handleCapitalizeChange}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>


                  <Grid item sm={12} xs={12}>
                    <PhoneInput
                      value={form.phone}
                      onChange={handleChange}
                      renderInput={() =>
                        <TextField
                          name="phone"
                          label="Phone"
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <Icon className="text-20" color="action">phone</Icon>
                              </InputAdornment>,
                          }}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      }
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <TextField
                      disabled={invited}
                      value={form.email}
                      onChange={handleChange}
                      type="text"
                      name="email"
                      label="Email"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                           color="action">email</Icon></InputAdornment>,
                      }}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="password"
                      name="password"
                      onChange={handleChange}
                      label="Password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                           color="action">vpn_key</Icon></InputAdornment>,
                      }}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="password"
                      name="passwordConfirm"
                      onChange={handleChange}
                      label="Confirm Password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20"
                                                                           color="action">vpn_key</Icon></InputAdornment>,
                      }}
                      variant="outlined"
                      required
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="w-full mx-auto mt-32 normal-case"
                  aria-label="REGISTER"
                  disabled={!canSubmit()}
                  value="legacy"
                >
                  Register
                </Button>

                {user.error ? (
                  <Typography variant="subtitle2" color="error" className="mt-16 text-center">
                    Error: {user.error.message}
                  </Typography>
                ) : null}

              </form>


              <div className="mt-24 flex items-center justify-center">
                <Divider className="w-32" />
                <span className="mx-8 font-bold">OR</span>
                <Divider className="w-32" />
              </div>

              <div className="flex flex-col items-center justify-center pt-16 pb-24">
                <span className="font-medium">Already have an account?</span>
                <Link className="font-medium" to={loginUrl}>Login</Link>
              </div>

            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default RegisterUser;
