import history from '@history';
import React from 'react';
import {Redirect} from 'react-router-dom';

class Utils {
  static redirectTo = (newPath) => {
    return history.push({pathname: newPath});
  };

  static unixTimeToDate = (unix) => {
    return new Date(unix * 1000);
  };

  /**
   * Capitalize first letter of each word which is delimited either by a space or a hyphen.
   * Thinking about "Catherine Zeta-Jones" or "Jean-Claude Van Damme", as you do.
   * @param words
   * @returns {string}
   */
  static capitalizeWords = (words) => {
    return (words || '').toLowerCase().replace(/^(\w)|([\s-]\w)/g, (m) => m.toUpperCase());
  };

  static redirectToMiddleware = (middlewarePath) => {
    // const nextPath = Utils.isInMiddleware() || history.location.pathname;
    console.log('redirect to middle', middlewarePath ?? history.location.pathname);
    return history.replace({pathname: middlewarePath ?? history.location.pathname});
  };

  static redirectFromMiddleware = (newPath = null) => {
    // if (history.location.state?.nextPath) {
    //     return history.replace({pathname: history.location.state.nextPath});
    // }
    console.log('redirect from middle', newPath ?? history.location.pathname);
    return history.replace({pathname: newPath ?? history.location.pathname});
  };

  static isInMiddleware = () => history.location.state?.nextPath;

  static returnFromMiddleware = (newPath = null) => {
    console.log('return from middle', newPath ?? history.location.pathname);
    // if (history.location.state?.nextPath) {
    //     return <Redirect to={{pathname: history.location.state.nextPath}}/>;
    // }
    return <Redirect to={{pathname: newPath ?? history.location.pathname}} />;
  };

  static filterStatusesMethod(filter, row) {
    if (filter.value === 'all') {
      return true;
    }

    if (filter.value) {
      return row[filter.id] === filter.value;
    }
  }

  /**
   * Convert DataURL to File
   * @param dataUrl
   * @param name
   * @returns {File}
   */
  static dataURLtoFile(dataUrl, name) {
    const arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([new Blob([u8arr], {type: mime})], name, {lastModified: new Date()});
  }

  static roundUp(value, decimals = 2) {
    return Math.ceil(+(value * Math.pow(10, decimals)).toFixed(decimals)) / Math.pow(10, decimals);
  }
}

export default Utils;
