import _ from '@lodash';
import {GET_CREWS} from 'app/modules/company/crews/store/actions';
import {GET_TYPES} from 'app/modules/company/task-types/store/actions';
import * as Actions from '../actions';

const initialState = {
  entities: null,
  projects: [],
  project: null,
  crews: [],
  taskTypes: [],
  taskDialog: {
    type: 'new',
    props: {
      open: false,
    },
    data: null,
  },
  productionDialog: {
    props: {
      open: false,
    },
    task: null,
  },
  taskActivity: [],
  taskFiles: [],
  task: null,
  fileUploadProgress: 0,
};

const tasks = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_TASKS: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
      };
    }
    case GET_TYPES: {
      return {
        ...state,
        taskTypes: action.payload,
      };
    }
    case GET_CREWS: {
      return {
        ...state,
        crews: action.payload,
      };
    }
    case Actions.GET_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case Actions.GET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case Actions.GET_PROJECT_BUDGET: {
      return {
        ...state,
        budget: {
          ...state.budget,
          entity: action.payload,
        },
      };
    }
    case Actions.GET_TASK_ACTIVITY: {
      return {
        ...state,
        taskActivity: action.payload,
      };
    }
    case Actions.GET_TASK_FILES: {
      return {
        ...state,
        taskFiles: action.payload,
      };
    }
    case Actions.UPDATE_TASK: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Actions.OPEN_ADD_TASK_DIALOG: {
      return {
        ...state,
        taskDialog: {
          type: 'add',
          props: {
            open: true,
          },
          data: null,
        },
      };
    }
    case Actions.CLOSE_ADD_TASK_DIALOG: {
      return {
        ...state,
        taskDialog: initialState.taskDialog,
      };
    }
    case Actions.OPEN_EDIT_TASK_DIALOG: {
      return {
        ...state,
        taskDialog: {
          type: 'edit',
          props: {
            open: true,
          },
          data: {
            ...action.payload,
          },
        },
      };
    }
    case Actions.CLOSE_EDIT_TASK_DIALOG: {
      return {
        ...state,
        taskDialog: initialState.taskDialog,
      };
    }
    case Actions.OPEN_ADD_TASK_PRODUCTION_DIALOG: {
      return {
        ...state,
        productionDialog: {
          props: {
            open: true,
          },
          task: action.task,
          production: action.production,
        },
      };
    }
    case Actions.CLOSE_ADD_TASK_PRODUCTION_DIALOG: {
      return {
        ...state,
        productionDialog: {
          ...initialState.productionDialog,
        },
      };
    }
    case Actions.GET_TASK: {
      return {
        ...state,
        task: action.payload,
      };
    }
    case Actions.SET_FILE_UPLOAD_PROGRESS: {
      return {
        ...state,
        fileUploadProgress: action.payload,
      };
    }
    case Actions.CLEANUP_TASK_DIALOG: {
      return {
        ...initialState,
        projects: [
          ...state.projects,
        ],
        entities: state.entities,
      };
    }

    default: {
      return state;
    }
  }
};

export default tasks;
