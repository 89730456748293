import axios from 'axios';
import {openErrorDialog, showSuccessMessage} from "app/store/actions/fuse";
import _ from '@lodash';

export const GET_TYPES = '[COMPANIES APP] GET TASK TYPES';
export const OPEN_NEW_TYPE_DIALOG = '[COMPANIES APP] OPEN NEW TYPE DIALOG';
export const CLOSE_NEW_TYPE_DIALOG = '[COMPANIES APP] CLOSE NEW TYPE DIALOG';
export const OPEN_EDIT_TYPE_DIALOG = '[COMPANIES APP] OPEN EDIT TYPE DIALOG';
export const CLOSE_EDIT_TYPE_DIALOG = '[COMPANIES APP] CLOSE EDIT TYPE DIALOG';
export const ADD_TYPE = '[COMPANIES APP] ADD TYPE';
export const UPDATE_TYPE = '[COMPANIES APP] UPDATE TYPE';
export const REMOVE_TYPE = '[COMPANIES APP] REMOVE TYPE';

export function getTypes(companyId) {
    return (dispatch) => {
        return axios.get(`/companies/${companyId}/task-types`)
            .then((response) =>
                dispatch(setTypes(response.data)),
            ).catch(err => {
                return dispatch(openErrorDialog(err.response));
            });
    };
}

export function setTypes(data) {
    return {
        type: GET_TYPES,
        payload: data
    }
}

export function openNewTypeDialog() {
    return {
        type: OPEN_NEW_TYPE_DIALOG
    }
}

export function closeNewTypeDialog() {
    return {
        type: CLOSE_NEW_TYPE_DIALOG
    }
}

export function openEditTypeDialog(data) {
    return {
        type: OPEN_EDIT_TYPE_DIALOG,
        data
    }
}

export function closeEditTypeDialog() {
    return {
        type: CLOSE_EDIT_TYPE_DIALOG
    }
}

export function addType(companyId, newType) {
    return (dispatch) => {
        const type = _.pick(newType, [
            'name',
            'description',
        ]);
        return axios.post(`/companies/${companyId}/task-types`, type)
            .then(({data}) =>
                Promise.all([
                    dispatch(closeNewTypeDialog()),
                    dispatch(getTypes(companyId)),
                    dispatch(showSuccessMessage({message: 'Task Type Added'}))
                ]).then(() =>
                    dispatch({
                        type: ADD_TYPE,
                        payload: data
                    }),
                )
            ).catch(err => {
                dispatch(openErrorDialog(err.response));
                throw new Error(err.response);
            });
    };
}

export function updateType(companyId, data) {
    const type = _.pick(data, [
        'name',
        'description',
    ]);
    return (dispatch) =>
        axios.patch(`/task-types/${data.id}`, type).then(response =>
            Promise.all([
                dispatch({
                    type: UPDATE_TYPE,
                    payload: data
                }),
                dispatch(closeEditTypeDialog()),
                dispatch(showSuccessMessage({message: 'Task Type Saved'})),
            ]).then(() =>
                dispatch(getTypes(companyId))
            )
        ).catch(err => {
            dispatch(openErrorDialog(err.response));
            throw new Error(err.response);
        });
}

export function removeType(companyId, typeId) {
    return (dispatch) =>
        axios.delete(`/task-types/${typeId}`).then(response =>
            Promise.all([
                dispatch({
                    type: REMOVE_TYPE,
                    payload: typeId
                }),
                dispatch(showSuccessMessage({message: 'Task Type Deleted'}))
            ]).then(() =>
                dispatch(getTypes(companyId))
            )
        ).catch(err => {
            return dispatch(openErrorDialog(err.response));
        });
}
