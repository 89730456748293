import {handleCatch, openErrorDialog} from 'app/store/actions/fuse';
import axios from 'axios';

export const GET_INVOICES = '[INVOICE APP] GET INVOICES';
export const OPEN_NEW_INVOICE_DIALOG = '[INVOICE APP] OPEN NEW INVOICE DIALOG';
export const CLOSE_NEW_INVOICE_DIALOG = '[INVOICE APP] CLOSE NEW INVOICE DIALOG';
export const GET_PROJECTS = '[INVOICE APP] GET PROJECTS';
export const INVOICE_GET_PROJECT = '[INVOICE APP] INVOICE GET PROJECTS';
export const ADD_INVOICE = '[INVOICE APP] ADD INVOICE';
export const INVOICE_GET_PRODUCTIONS = '[INVOICE APP] GET INVOICE PRODUCTIONS';
export const GENERATE_INVOICE_REPORT = '[INVOICE APP] GENERATE INVOICE REPORT';

export function openNewInvoiceDialog() {
  return {
    type: OPEN_NEW_INVOICE_DIALOG,
  };
}

export function closeNewInvoiceDialog() {
  return {
    type: CLOSE_NEW_INVOICE_DIALOG,
  };
}

export function getInvoices(companyId, filterConfig) {
  const defaultFilter = {
    where: {status: {inq: ['submit', 'draft', 'unpaid', 'partially_paid', 'rejected']}},
    order: ['dateCreated DESC'],
  };
  const filter = filterConfig ?? defaultFilter;
  return (dispatch) => {
    return axios
      .get(`/invoices`, {params: {filter}})
      .then((response) =>
        dispatch({
          type: GET_INVOICES,
          payload: response.data,
        }),
      )
      .catch((err) => dispatch(openErrorDialog(err.response)));
  };
}

export const getProjects = () => (dispatch) =>
  axios.get(`/projects`).then(
    (response) =>
      dispatch({
        type: GET_PROJECTS,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getProject = (projectId) => (dispatch) =>
  axios.get(`/projects/${projectId}`).then(
    (response) =>
      dispatch({
        type: INVOICE_GET_PROJECT,
        payload: response.data,
      }),
    handleCatch(dispatch),
  );

export const getProductions = (projectId, invoiceId) => (dispatch) => {
  axios
    .get(`/billable-productions/${projectId}`, {
      params: invoiceId ? {invoiceId: invoiceId} : null,
    })
    .then(
      (response) =>
        dispatch({
          type: INVOICE_GET_PRODUCTIONS,
          payload: response.data,
        }),
      handleCatch(dispatch),
    );
};

export const generateInvoiceReport = (companyId, range, sourceSubcontractor, groupBy, groupByField) => (dispatch) => {
  const data = {
    range,
    sourceSubcontractor,
    groupBy,
    groupByField,
  };

  return axios
    .post(`/invoices/${companyId}/report`, data)
    .then((response) => {
      return dispatch({
        type: GENERATE_INVOICE_REPORT,
        payload: response.data,
      });
    })
    .catch((err) => dispatch(openErrorDialog(err.response)));
};
