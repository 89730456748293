import * as Actions from '../actions';

const initialState = {
  project: null,
  crew: null,
  logs: null,
  projects: [],
  crews: [],
  memberNames: [],
  logDialog: {
    props: {
      open: false,
      log: null,
    },
  },
};

const map = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOG_ADD: {
      return {
        ...state,
      };
    }
    case Actions.LOG_GET_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case Actions.LOG_GET_CREW: {
      return {
        ...state,
        crew: action.payload,
      };
    }
    case Actions.LOG_GET_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case Actions.LOG_GET_CREWS: {
      return {
        ...state,
        crews: action.payload,
      };
    }
    case Actions.LOG_GET_MEMBER: {
      return {
        ...state,
        memberIds: action.payload,
      };
    }
    case Actions.LOG_PROJECT_SETUP_OPEN_DIALOG: {
      return {
        ...state,
        logDialog: {
          props: {
            open: true,
            log: action.payload,
          },
        },
      };
    }
    case Actions.LOG_PROJECT_SETUP_CLOSE_DIALOG: {
      return {
        ...state,
        logDialog: {
          ...initialState.logDialog,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default map;
