import React, {Component} from 'react';
import {FuseSplashScreen} from '@fuse';
import {connect} from 'react-redux';
import {logoutUser, setUserData} from './store/actions';
import {bindActionCreators} from 'redux';
import jwtService from 'app/services/jwtService';
import {showErrorMessage, showMessage} from '../store/actions';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return this.jwtCheck().then(() => {
      this.setState({waitAuthCheck: false});
    });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on('onAutoLogin', () => {
        /**
         * Sign in and retrieve user data from Api
         */
        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.props.showErrorMessage({message: error});
            resolve();
          });
      });
      jwtService.on('onAutoLogout', (message, logout) => {
        if (message) {
          this.props.showMessage({message});
        }
        if (logout) {
          this.props.logout();
        }
        resolve();
      });
      jwtService.on('onNoAccessToken', () => {
        resolve();
      });
      jwtService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <React.Fragment children={this.props.children} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData: setUserData,
      showMessage: showMessage,
      showErrorMessage: showErrorMessage,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Auth);
