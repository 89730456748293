import axios from 'axios';
import {openErrorDialog, showSuccessMessage} from 'app/store/actions/fuse';
import _ from '@lodash';

export const GET_CREWS = '[COMPANIES APP] GET CREWS';
export const OPEN_NEW_CREW_DIALOG = '[COMPANIES APP] OPEN NEW CREW DIALOG';
export const CLOSE_NEW_CREW_DIALOG = '[COMPANIES APP] CLOSE NEW CREW DIALOG';
export const OPEN_EDIT_CREW_DIALOG = '[COMPANIES APP] OPEN EDIT CREW DIALOG';
export const CLOSE_EDIT_CREW_DIALOG = '[COMPANIES APP] CLOSE EDIT CREW DIALOG';
export const ADD_CREW = '[COMPANIES APP] ADD CREW';
export const UPDATE_CREW = '[COMPANIES APP] UPDATE CREW';
export const REMOVE_CREW = '[COMPANIES APP] REMOVE CREW';
export const GET_CREW = '[COMPANIES APP] GET CREW';

export function openNewCrewDialog() {
  return {
    type: OPEN_NEW_CREW_DIALOG,
  };
}

export function closeNewCrewDialog() {
  return {
    type: CLOSE_NEW_CREW_DIALOG,
  };
}

export function openEditCrewDialog(data) {
  return {
    type: OPEN_EDIT_CREW_DIALOG,
    data,
  };
}

export function closeEditCrewDialog() {
  return {
    type: CLOSE_EDIT_CREW_DIALOG,
  };
}

export function getCrews() {
  return (dispatch) => {
    return axios
      .get(`/crews`)
      .then((response) => dispatch(setCrews(response.data)))
      .catch((err) => dispatch(openErrorDialog(err.response)));
  };
}

export function setCrews(data) {
  return {
    type: GET_CREWS,
    payload: data,
  };
}

export function addCrew(newCrew) {
  const crew = _.pick(newCrew, ['name', 'members', 'leaders']);
  return (dispatch) => {
    return axios
      .post(`/crews`, crew)
      .then(() =>
        Promise.all([
          dispatch({
            type: ADD_CREW,
          }),
          dispatch(closeNewCrewDialog()),
          dispatch(showSuccessMessage({message: 'Crew Added'})),
        ]).then(() => dispatch(getCrews())),
      )
      .catch((err) => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
  };
}

export function updateCrew(crewData) {
  const crew = _.pick(crewData, ['name', 'members', 'leaders']);
  return (dispatch) => {
    return axios
      .patch(`/crews/${crewData.id}`, crew)
      .then(() =>
        Promise.all([
          dispatch({
            type: UPDATE_CREW,
          }),
          dispatch(closeEditCrewDialog()),
          dispatch(showSuccessMessage({message: 'Crew Saved'})),
        ]).then(() => dispatch(getCrews())),
      )
      .catch((err) => {
        dispatch(openErrorDialog(err.response));
        throw err;
      });
  };
}

export function removeCrew(crewId) {
  return (dispatch) => {
    return axios
      .delete(`/crews/${crewId}`)
      .then(() =>
        Promise.all([
          dispatch({
            type: REMOVE_CREW,
          }),
          dispatch(showSuccessMessage({message: 'Crew deleted'})),
        ]).then(() => dispatch(getCrews())),
      )
      .catch((err) => dispatch(openErrorDialog(err.response)));
  };
}

export function getCrew(crewId) {
  return (dispatch) => {
    return axios
      .get(`/crews/${crewId}`)
      .then((response) =>
        dispatch({
          type: GET_CREW,
          payload: response.data,
        }),
      )
      .catch((err) => dispatch(openErrorDialog(err.response)));
  };
}
