import React from 'react';

export const ClientsAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            name: 'client',
            path: [
                '/clients/:clientId/:sectionId',
                '/clients/:clientId'
            ],
            component: React.lazy(() => import('./client/ClientApp'))
        },
        {
            name: 'clients',
            path: '/clients',
            component: React.lazy(() => import('./ClientsApp'))
        },
    ]
};
