import React from 'react';

export const MembersAppConfig = {
  routes: [
    {
      path: [
        '/members/:memberId/:sectionId',
        '/members/:memberId',
      ],
      component: React.lazy(() => import('./member/MemberApp')),
    },
    {
      path: '/members',
      component: React.lazy(() => import('./MembersApp')),
    },
  ],
};
