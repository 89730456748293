import {Avatar, Button, Icon, ListItemIcon, ListItemText, MenuItem, Popover, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import * as userActions from 'app/auth/store/actions';
import {updateUserCompany} from 'app/auth/store/actions';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4.25),
    height: theme.spacing(4.25),
  },
}));

function UserMenu() {
  const dispatch = useDispatch();
  const user = useSelector(({auth}) => auth.user);
  const companies = useSelector(({auth}) =>
    auth.user.memberships.filter((m) => m.type === 'company' && m.company).map((m) => m.company),
  );
  const [userMenu, setUserMenu] = useState(null);
  const classes = useStyles();

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const setActiveCompany = (company, ev) => {
    ev.preventDefault();
    dispatch(updateUserCompany(company.id));
    userMenuClose();
  };

  return (
    <React.Fragment>
      <Button onClick={userMenuClick} className="rounded-none">
        {user.avatarId ? (
          <Avatar className={classes.small} alt="user photo" src={user.avatarUrl} />
        ) : (
          <Avatar className={classes.small}>{user.name[0]}</Avatar>
        )}

        <div className="hidden md:flex flex-col ml-12 items-start">
          {user.company ? (
            <Typography component="span" className="normal-case font-600 flex leading-snug">
              {user.company.name}
            </Typography>
          ) : null}
          <Typography className="text-11 capitalize" color="textSecondary">
            {user.name}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!companies || !companies.length ? (
          <React.Fragment>
            <MenuItem component={Link} to="/login">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Login" />
            </MenuItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {companies
              ? companies.map((company) => (
                  <MenuItem key={company.id} onClick={(ev) => setActiveCompany(company, ev)}>
                    <ListItemIcon className="min-w-40">
                      <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <ListItemText className="pl-0" primary={company.name} />
                  </MenuItem>
                ))
              : null}
            <MenuItem component={Link} to="/profile" onClick={userMenuClose}>
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="My Profile" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(userActions.logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Logout" />
            </MenuItem>
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  );
}

export default UserMenu;
