import React from 'react';

export const SetupAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/settings',
            component: React.lazy(() => import('./SettingsApp'))
        },
    ]
};
