import _ from '@lodash';
import {GET_SUBCONTRACTORS} from 'app/modules/company/subcontractors/store/actions';
import {GET_ALL_ROLES, GET_ASSIGNABLE_ROLES} from '../../../roles/store/actions';
import * as Actions from '../actions';

const initialState = {
  entities: null,
  clientProjects: [],
  project: null,
  projectDialog: {
    props: {
      open: false,
    },
    currentStep: 0,
  },
  projectFilesUploadDialog: {
    props: {
      open: false,
    },
  },
  memberships: {
    projectMemberships: [],
    inviteMemberToProjectDialog: {
      props: {
        open: false,
      },
      invitedMembers: [],
    },
  },
  budgets: [],
  budget: {
    entity: null,
    items: [],
    review: {
      invitation: null,
      approve: {
        loading: false,
        success: false,
        error: null,
      },
      reject: {
        loading: false,
        success: false,
        error: null,
      },
      data: null,
      signDialog: {
        props: {
          open: false,
        },
      },
    },
    itemDialog: {
      type: 'none',
      data: null,
    },
    rejectDialog: {
      props: {
        open: false,
      },
      review: null,
    },
  },
  budgetDialog: {
    type: 'new',
    props: {
      open: false,
    },
    data: null,
  },
  members: [],
  roles: {
    all: null,
    assignable: null,
  },
  subcontractors: [],
  fileContractUploadProgress: 0,
  fileWorkOrderUploadProgress: 0,
  fileOtherUploadProgress: 0,
  stations: [],
  changeOrders: {
    changeOrders: [],
    availableItems: [],
    addChangeOrderDialog: {
      open: false,
      data: null,
    },
    changeOrderDialog: {
      type: null,
      data: null,
    },
    sendBackChangeOrderDialog: {
      open: false,
      data: null,
    },
  },
  pdfDialog: {
    props: {
      open: false,
    },
  },
  clientSubcontractorProjects: null,
  allItemsCategories: null,
  budgetDistributionData: null,
  budgetSpentData: null,
  budgetDetailsData: null,
  reportOverview: null,
  reportProduction: null,
  parentProjects: null,
  itemCategories: null,
  crewCompanies: null,
  subProjects: null,
  productions: null,
};

const projects = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PROJECTS: {
      return {
        ...state,
        entities: _.keyBy(action.payload, 'id'),
      };
    }
    case Actions.GET_CLIENT_PROJECTS: {
      return {
        ...state,
        clientProjects: action.payload,
      };
    }
    case Actions.GET_PROJECT: {
      return {
        ...state,
        project: action.payload,
        budget: initialState.budget,
      };
    }
    case Actions.OPEN_PROJECT_WIZARD_DIALOG: {
      return {
        ...state,
        projectDialog: {
          ...initialState.projectDialog,
          props: {
            open: true,
          },
        },
        project: null,
      };
    }
    case Actions.CLOSE_PROJECT_WIZARD_DIALOG: {
      return {
        ...state,
        projectDialog: {
          ...initialState.projectDialog,
        },
        project: null,
      };
    }
    case Actions.OPEN_PROJECT_FILE_UPLOAD_DIALOG: {
      return {
        ...state,
        projectFilesUploadDialog: {
          ...initialState.projectFilesUploadDialog,
          props: {
            open: true,
          },
        },
      };
    }
    case Actions.CLOSE_PROJECT_FILE_UPLOAD_DIALOG: {
      return {
        ...state,
        projectFilesUploadDialog: {
          ...initialState.projectFilesUploadDialog,
        },
      };
    }
    case Actions.ADD_PROJECT: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case Actions.UPLOAD_PROJECT_FILE: {
      return {
        ...state,
        project: {
          ...state.project,
          files: [...(state.project.files ?? []), action.payload],
        },
      };
    }
    case Actions.DELETE_PROJECT_FILE: {
      return {
        ...state,
        project: {
          ...state.project,
          files: [...(state.project.files ?? []).filter((f) => f.id !== action.payload.id)],
        },
      };
    }
    case Actions.REMOVE_PROJECT: {
      return {
        ...state,
        entities: [],
      };
    }
    case Actions.UPDATE_PROJECT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Actions.GET_PROJECT_ROLES: {
      return {
        ...state,
        roles: action.payload.roles,
      };
    }
    case GET_SUBCONTRACTORS: {
      return {
        ...state,
        subcontractors: action.payload.map((subcontractor) => ({
          ...subcontractor,
          id: subcontractor.subcontractorId,
        })),
      };
    }
    case Actions.GET_PROJECT_MEMBERS: {
      return {
        ...state,
        members: action.payload,
      };
    }
    case GET_ALL_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          all: action.payload,
        },
      };
    }
    case GET_ASSIGNABLE_ROLES: {
      return {
        ...state,
        roles: {
          ...state.roles,
          assignable: action.payload,
        },
      };
    }
    case Actions.REMOVE_PROJECT_MEMBER: {
      return {
        ...state,
        members: [],
      };
    }
    case Actions.OPEN_ADD_MEMBER_TO_PROJECT_DIALOG: {
      return {
        ...state,
        memberships: {
          inviteMemberToProjectDialog: {
            ...state.memberships,
            props: {
              open: true,
            },
          },
        },
      };
    }
    case Actions.CLOSE_ADD_MEMBER_TO_PROJECT_DIALOG: {
      return {
        ...state,
        memberships: {
          ...state.memberships,
          inviteMemberToProjectDialog: {
            ...initialState.memberships.inviteMemberToProjectDialog,
          },
        },
      };
    }
    case Actions.SEND_PROJECT_INVITE: {
      return {
        ...state,
        memberships: {
          ...state.memberships,
          inviteMemberToProjectDialog: {
            ...state.memberships.inviteMemberToProjectDialog,
            invitedMembers: action.payload,
          },
        },
      };
    }
    case Actions.GET_PROJECT_BUDGETS: {
      return {
        ...state,
        budgets: action.payload,
      };
    }
    case Actions.GET_PROJECT_BUDGET: {
      return {
        ...state,
        budget: {
          ...state.budget,
          entity: action.payload,
        },
      };
    }
    case Actions.OPEN_NEW_BUDGET_DIALOG: {
      return {
        ...state,
        budgetDialog: {
          type: 'new',
          props: {
            open: true,
          },
          data: null,
        },
      };
    }
    case Actions.CLOSE_NEW_BUDGET_DIALOG: {
      return {
        ...state,
        budgetDialog: {
          type: 'new',
          props: {
            open: false,
          },
          data: null,
        },
      };
    }
    case Actions.OPEN_BUDGET_ITEM_DIALOG: {
      return {
        ...state,
        budget: {
          ...state.budget,
          itemDialog: {
            type: action.itemType,
            data: action.data,
          },
        },
      };
    }
    case Actions.CLOSE_BUDGET_ITEM_DIALOG: {
      return {
        ...state,
        budget: {
          ...state.budget,
          itemDialog: initialState.budget.itemDialog,
        },
      };
    }
    case Actions.GET_BUDGET_ITEMS: {
      return {
        ...state,
        budget: {
          ...state.budget,
          items: action.payload,
        },
      };
    }
    case Actions.GET_BUDGET_REVIEW: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            invitation: action.payload,
          },
        },
      };
    }
    case Actions.BUDGET_REVIEW_APPROVE_START: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            approve: {
              loading: true,
            },
          },
        },
      };
    }
    case Actions.BUDGET_REVIEW_APPROVE_END: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            invitation: action.payload,
            approve: {
              loading: false,
              success: action.success,
              error: action.error,
            },
          },
        },
      };
    }
    case Actions.BUDGET_REVIEW_REJECT_START: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            reject: {
              loading: true,
            },
          },
        },
      };
    }
    case Actions.BUDGET_REVIEW_REJECT_END: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            invitation: action.payload,
            reject: {
              loading: false,
              success: action.success,
              error: action.error,
            },
          },
        },
      };
    }
    case Actions.SUBMIT_BUDGET: {
      return {
        ...state,
      };
    }
    case Actions.OPEN_BUDGET_REVIEW_REJECT_DIALOG: {
      return {
        ...state,
        budget: {
          ...state.budget,
          rejectDialog: {
            props: {
              open: true,
            },
            review: action.review,
          },
        },
      };
    }
    case Actions.CLOSE_BUDGET_REVIEW_REJECT_DIALOG: {
      return {
        ...state,
        budget: {
          ...state.budget,
          rejectDialog: initialState.budget.rejectDialog,
        },
      };
    }
    case Actions.OPEN_SIGN_BUDGET_DIALOG: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            data: action.payload,
            signDialog: {
              ...state.budget.review.signDialog,
              props: {
                open: true,
              },
            },
          },
        },
      };
    }
    case Actions.CLOSE_SIGN_BUDGET_DIALOG: {
      return {
        ...state,
        budget: {
          ...state.budget,
          review: {
            ...state.budget.review,
            data: null,
            signDialog: {
              ...state.budget.review.signDialog,
              props: {
                open: false,
              },
            },
          },
        },
      };
    }
    case Actions.UPLOAD_BUDGET_SIGNATURE: {
      return {
        ...state,
        budget: {
          ...state.budget,
        },
      };
    }
    case Actions.SET_FILE_CONTRACT_UPLOAD_PROGRESS: {
      return {
        ...state,
        fileContractUploadProgress: action.payload,
      };
    }
    case Actions.SET_FILE_WORK_ORDER_UPLOAD_PROGRESS: {
      return {
        ...state,
        fileWorkOrderUploadProgress: action.payload,
      };
    }
    case Actions.SET_FILE_OTHER_UPLOAD_PROGRESS: {
      return {
        ...state,
        fileOtherUploadProgress: action.payload,
      };
    }
    case Actions.GET_PROJECT_STATIONS: {
      return {
        ...state,
        stations: action.payload,
      };
    }
    case Actions.OPEN_BUDGET_CHANGE_ORDER_DIALOG: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          changeOrderDialog: {
            type: action.data.type,
            data: action.data.data,
          },
        },
      };
    }
    case Actions.CLOSE_BUDGET_CHANGE_ORDER_DIALOG: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          changeOrderDialog: initialState.changeOrders.changeOrderDialog,
        },
      };
    }
    case Actions.OPEN_ADD_BUDGET_CHANGE_ORDER_DIALOG: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          addChangeOrderDialog: {
            open: true,
          },
        },
      };
    }
    case Actions.CLOSE_ADD_BUDGET_CHANGE_ORDER_DIALOG: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          addChangeOrderDialog: initialState.changeOrders.addChangeOrderDialog,
        },
      };
    }
    case Actions.GET_BUDGET_CHANGE_ORDER_AVAILABLE_ITEMS: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          availableItems: action.payload,
        },
      };
    }
    case Actions.OPEN_SEND_BACK_CHANGE_ORDER_DIALOG: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          sendBackChangeOrderDialog: {
            open: true,
            data: action.data,
          },
        },
      };
    }
    case Actions.CLOSE_SEND_BACK_CHANGE_ORDER_DIALOG: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          sendBackChangeOrderDialog: initialState.changeOrders.sendBackChangeOrderDialog,
        },
      };
    }
    case Actions.GET_BUDGET_CHANGE_ORDERS: {
      return {
        ...state,
        changeOrders: {
          ...state.changeOrders,
          changeOrders: action.payload,
        },
      };
    }
    case Actions.OPEN_ADD_PDF_DIALOG: {
      return {
        ...state,
        pdfDialog: {
          ...initialState.pdfDialog,
          props: {
            open: true,
          },
        },
      };
    }
    case Actions.CLOSE_ADD_PDF_DIALOG: {
      return {
        ...state,
        pdfDialog: {
          ...initialState.pdfDialog,
        },
      };
    }
    case Actions.GET_CLIENT_SUBCONTRACTOR_PROJECTS: {
      return {
        ...state,
        clientSubcontractorProjects: action.payload ? [{id: 'all', code: 'All'}, ...action.payload] : [],
      };
    }
    case Actions.GET_ALL_PROJECT_ITEMS_CATEGORIES: {
      return {
        ...state,
        allItemsCategories: action.payload ? [{id: 'all', name: 'All'}, ...action.payload] : [],
      };
    }
    case Actions.GET_BUDGET_DISTRIBUTION_DATA: {
      return {
        ...state,
        budgetDistributionData: action.payload,
      };
    }
    case Actions.CLEANUP_BUDGET_DISTRIBUTION_DATA: {
      return {
        ...state,
        budgetDistributionData: null,
      };
    }
    case Actions.GET_BUDGET_SPENT_DATA: {
      return {
        ...state,
        budgetSpentData: action.payload,
      };
    }
    case Actions.CLEANUP_BUDGET_SPENT_DATA: {
      return {
        ...state,
        budgetSpentData: null,
      };
    }
    case Actions.GET_BUDGET_DETAILS_DATA: {
      return {
        ...state,
        budgetDetailsData: action.payload,
      };
    }
    case Actions.CLEANUP_BUDGET_DETAILS_DATA: {
      return {
        ...state,
        budgetDetailsData: null,
      };
    }
    case Actions.GET_PROJECT_REPORT_OVERVIEW: {
      return {
        ...state,
        reportOverview: action.payload,
      };
    }
    case Actions.GET_PROJECT_REPORT_PRODUCTION: {
      return {
        ...state,
        reportProduction: action.payload,
      };
    }
    case Actions.CLEANUP_PROJECT_REPORT_OVERVIEW: {
      return {
        ...state,
        reportOverview: null,
      };
    }
    case Actions.CLEANUP_PROJECT_REPORT_PRODUCTION: {
      return {
        ...state,
        reportProduction: null,
      };
    }
    case Actions.GET_COMPANY_PARENT_PROJECTS: {
      return {
        ...state,
        parentProjects: action.payload,
      };
    }
    case Actions.CLEANUP_COMPANY_PARENT_PROJECTS: {
      return {
        ...state,
        parentProjects: null,
      };
    }
    case Actions.GET_PROJECT_ITEM_CATEGORIES: {
      return {
        ...state,
        itemCategories: action.payload,
      };
    }
    case Actions.CLEANUP_PROJECT_ITEM_CATEGORIES: {
      return {
        ...state,
        itemCategories: null,
      };
    }
    case Actions.GET_PROJECT_CREW_COMPANIES: {
      return {
        ...state,
        crewCompanies: action.payload,
      };
    }
    case Actions.CLEANUP_PROJECT_CREW_COMPANIES: {
      return {
        ...state,
        crewCompanies: null,
      };
    }
    case Actions.GET_PROJECT_SUBPROJECTS: {
      return {
        ...state,
        subProjects: action.payload,
      };
    }
    case Actions.CLEANUP_PROJECT_SUBPROJECTS: {
      return {
        ...state,
        subProjects: null,
      };
    }
    case Actions.GET_PROJECT_PRODUCTIONS: {
      return {
        ...state,
        productions: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default projects;
