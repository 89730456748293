import _ from '@lodash';
import axios from 'axios';
import {User} from '../../../../@rubix/User';
import jwtService from '../../../services/jwtService';
import {openErrorDialog, setInitialSettings, showSuccessMessage, showErrorMessage} from '../../../store/actions';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const SET_DEFAULT_COMPANY = '[USER] SET DEFAULT COMPANY';
export const UPDATE_PROFILE = '[USER] UPDATE PROFILE';
export const CHANGE_PASSWORD = '[USER] CHANGE PASSWORD';
export const UPLOAD_PROFILE_PICTURE = '[USER] UPLOAD PROFILE PICTURE';

/**
 * Set User Data
 */
export function setUserData(user) {
  return (dispatch) => {
    /*
    Set User Settings
     */
    // dispatch(setDefaultSettings(user.settings));
    /*
    Set User Data
     */
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Reload user data
 * @return {function(...[*]=)}
 */
export function reloadUserData() {
  return (dispatch) => {
    return axios
      .get('/users/me')
      .then((response) => {
        dispatch(setUserData(response.data.user));
      })
      .catch((error) => {
        dispatch(showErrorMessage({message: error.message}));
      });
  };
}

/**
 * Update User Settings
 */
export function updateUserCompany(companyId) {
  return (dispatch) => {
    return axios
      .patch(`/users/me/company/${companyId}`)
      .then((response) => {
        dispatch(setUserData(response.data));
      })
      .catch((error) => {
        dispatch(showErrorMessage({message: error.message}));
      });
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch) => {
    return axios
      .patch(`/users/me/shortcuts`, shortcuts)
      .then((response) => {
        dispatch(setUserData(response.data));
        dispatch(showSuccessMessage({message: 'Settings saved'}));
      })
      .catch((error) => {
        dispatch(showErrorMessage({message: error.message}));
      });
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser(redirect = true) {
  return (dispatch, getState) => {
    // guest
    if (!User.instance) {
      return null;
    }
    User.setInstance(null);

    jwtService.logout();

    dispatch(setInitialSettings());

    dispatch({
      type: USER_LOGGED_OUT,
    });

    if (redirect) {
      window.location.replace('/login');
    }
  };
}

export function setCompany(company) {
  return (dispatch, getState) => {
    const userSettings = _.omit(getState().auth.user.settings, ['images']);
    const defaultCompanyId = userSettings ? userSettings.defaultCompanyId : null;
    if (defaultCompanyId !== company.id) {
      return Promise.all([
        dispatch({
          type: SET_DEFAULT_COMPANY,
          payload: company,
        }),
        dispatch(updateUserCompany(company.id)),
      ]).then(() => {
        dispatch(reloadUserData());
      });
    } else {
      return dispatch({
        type: SET_DEFAULT_COMPANY,
        payload: company,
      });
    }
  };
}
export function updateUserProfile(profile) {
  const userProfile = _.pick(profile, [
    'name',
    'birthday',
    'phone',
    'address',
    'country',
    'state',
    'city',
    'zip',
    'settings',
    'ssn',
    'driversLicense',
    'cdl',
    'emergencyContactName',
    'emergencyContactPhone',
  ]);

  return (dispatch) =>
    axios
      .patch(`/users/me`, userProfile)
      .then(({data}) => {
        dispatch(showSuccessMessage({message: 'Profile Saved'}));
        return dispatch({
          type: UPDATE_PROFILE,
          payload: data,
        });
      })
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function changePassword(passwordData) {
  const request = axios.patch(`/users/me/change-password`, passwordData);

  return (dispatch) =>
    request
      .then(({data}) => {
        dispatch(showSuccessMessage({message: 'Password Changed'}));
        return dispatch({
          type: CHANGE_PASSWORD,
          payload: data,
        });
      })
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}

export function uploadProfilePicture(user, file) {
  const form = new FormData();
  form.append('file', file);
  form.append('type', 'profilePicture');

  const request = axios.post(`/users/me/files`, form);

  return (dispatch) =>
    request
      .then((response) => {
        dispatch(showSuccessMessage({message: 'Profile Picture Saved'}));
        return dispatch({
          type: UPLOAD_PROFILE_PICTURE,
          payload: response.data,
          user,
        });
      })
      .catch((err) => {
        return dispatch(openErrorDialog(err.response));
      });
}
