import React from 'react';
import {Redirect} from 'react-router-dom';
import {default as FuseUtils} from '../../@fuse/FuseUtils';
import {User} from '../../@rubix/User';
import Utils from '../../@rubix/Utils';
import * as ModuleConfigs from '../modules';
import * as PageConfigs from '../pages';

const routeConfigs = [...Object.values(ModuleConfigs), ...Object.values(PageConfigs)];

const defaultAuthentication = [
  {
    require: () => User.hasRole('Platform/Member'),
    denied: '/login',
  },
  {
    require: () => User.instance.memberships.length > 0,
    denied: '/register/company',
  },
];

const routes = [
  // The individual route configs which have auth options won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, defaultAuthentication),
  {
    path: '/',
    exact: true,
    auth: defaultAuthentication,
    component: () => Utils.returnFromMiddleware('/dashboard'),
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />,
  },
];

export default routes;
