import React from 'react';

export const SubcontractorsAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            name: 'subcontractor',
            path: [
                '/subcontractors/:subcontractorId/:sectionId',
                '/subcontractors/:subcontractorId'
            ],
            component: React.lazy(() => import('./subcontractor/SubcontractorApp'))
        },
        {
            name: 'subcontractors',
            path: '/subcontractors',
            component: React.lazy(() => import('./SubcontractorsApp'))
        },
    ]
};
