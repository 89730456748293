import {combineReducers} from 'redux';
import productions from './productions.reducer';
import production from './production.reducer';
import map from './map.reducer';

const reducer = combineReducers({
    productions,
    production,
    map,
});

export default reducer;
