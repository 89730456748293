import {FuseAnimate} from '@fuse';
import FuseSplashScreen from '@fuse/components/FuseSplashScreen/FuseSplashScreen';
import {useForm} from '@fuse/hooks';
import {Button, Card, CardContent, TextField, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {darken} from '@material-ui/core/styles/colorManipulator';
import {makeStyles} from '@material-ui/styles';
import Dropdown from '@rubix/form/Dropdown';
import {EinInput} from '@rubix/form/EinInput';
import GoogleAddressAutocomplete from '@rubix/form/GoogleAddressAutocomplete';
import {PhoneInput} from '@rubix/form/PhoneInput';
import {ZipInput} from '@rubix/form/ZipInput';
import * as i18n from '@rubix/i18n';
import * as Actions from 'app/auth/store/actions';
import {getPartnershipInvitation} from 'app/modules/company/invitations/store/actions';
import clsx from 'clsx';
import queryString from 'query-string';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'radial-gradient(' + darken(theme.palette.primary.dark, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
    color: theme.palette.primary.contrastText,
  },
}));

const defaultFormState = {
  ein: '',
  name: '',
  address: '',
  country: 'US',
  state: '',
  city: '',
  zip: '',
  contactName: '',
  contactPhone: '1',
  contactEmail: '',
};

function RegisterCompany() {

  const dispatch = useDispatch();
  const classes = useStyles();
  const company = useSelector(({auth}) => auth.register.company);
  const user = useSelector(({auth}) => auth.user);
  const invitation = useSelector(({invitationApp}) => invitationApp.partnership.invitation);
  const {form, handleChange, setForm, handleCapitalizeChange} = useForm(defaultFormState);
  const [invited, setInvited] = useState(null);

  // parse returnTo path to find out if we're dealing with an invitation
  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.returnTo) {
      const parts = query.returnTo.split('/');
      switch (parts[1]) {
        case 'partner-invitation':
          setInvited(parts[3]);
          dispatch(getPartnershipInvitation(parts[2]));
          break;
        default:
          setInvited(null);
          break;
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (invitation) {
      if (invited === 'client') {
        setForm({
          ...defaultFormState,
          name: invitation.clientName,
          contactName: invitation.clientContactName,
          contactPhone: user.phone ?? '',
          contactEmail: invitation.clientContactEmail,
        });
      }
      if (invited === 'subcontractor') {
        setForm({
          ...defaultFormState,
          name: invitation.subcontractorName,
          contactName: invitation.subcontractorContactName,
          contactPhone: user.phone ?? '',
          contactEmail: invitation.subcontractorContactEmail,
        });
      }
    }
  }, [invitation, invited, user, setForm]);

  function isFormValid() {
    return (
      form.name.length > 5 &&
      form.country.length > 0 &&
      form.state.length > 0 &&
      form.city.length > 0 &&
      form.address.length > 3 &&
      form.zip.length === 5 &&
      form.contactName.length > 0 &&
      form.contactPhone.length > 9 &&
      form.contactPhone.indexOf('_') < 0 &&
      form.contactEmail.length > 0
    );
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    dispatch(Actions.registerCompany(form, invited === null));
  }

  const handlePlace = place => {
    setForm({
      ...form,
      country: place.country,
      state: place.state,
      city: place.city,
      address: place.address,
      zip: place.zip,
    });
  };

  if ((company.loading && !company.error) || (invited && !invitation)) {
    return <FuseSplashScreen />;
  }

  return (
    <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>

      <div className="flex flex-col items-center justify-center w-full">

        <FuseAnimate animation="transition.expandIn">

          <Card className="w-full max-w-640">

            <CardContent className="flex flex-col items-center justify-center p-32">

              <img className="w-128 m-16" src="assets/images/logos/logo-plain.png" alt="logo" />

              <Typography variant="overline" className="mb-16 font-light">Almost there...</Typography>

              <Typography variant="h6" className="mb-32">SETUP YOUR COMPANY</Typography>

              <form
                name="registerForm"
                noValidate
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit}
              >

                <Grid container spacing={3}>

                  <Grid item sm={5} xs={12}>
                    <EinInput
                      value={form.ein}
                      onChange={handleChange}
                      autoFocus
                      renderInput={props =>
                        <TextField
                          label="Employer Identification No. (optional)"
                          name="ein"
                          variant="outlined"
                          fullWidth
                          autoFocus
                        />
                      }
                    />
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <TextField
                      label="Company Name"
                      name="name"
                      value={form.name}
                      onChange={handleCapitalizeChange}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <GoogleAddressAutocomplete onSelectLocation={handlePlace} />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <Dropdown
                      id="country"
                      name="country"
                      options={i18n.countries}
                      value={form.country}
                      getOptionLabel={o => o.name}
                      getOptionSelected={o => o.id === form.country}
                      label="Country"
                      required
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <Dropdown
                      id="state"
                      name="state"
                      options={i18n.states(form.country)}
                      disabled={!form.country}
                      value={form.state}
                      getOptionLabel={o => o.name}
                      getOptionSelected={o => o.id === form.state}
                      label="State"
                      required
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <TextField
                      label="City"
                      name="city"
                      value={form.city}
                      onChange={handleCapitalizeChange}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={7} xs={12}>
                    <TextField
                      label="Address"
                      name="address"
                      value={form.address}
                      onChange={handleCapitalizeChange}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <ZipInput
                      value={form.zip}
                      onChange={handleChange}
                      renderInput={props =>
                        <TextField
                          label="Zip"
                          name="zip"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      }
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      label="Contact Name"
                      name="contactName"
                      value={form.contactName}
                      onChange={handleCapitalizeChange}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <PhoneInput
                      value={form.contactPhone}
                      onChange={handleChange}
                      renderInput={props =>
                        <TextField
                          label="Phone"
                          name="contactPhone"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Email"
                      type="email"
                      name="contactEmail"
                      value={form.contactEmail}
                      onChange={handleChange}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  className="w-224 mx-auto mt-32"
                  aria-label="Register"
                  disabled={!isFormValid()}
                  type="submit"
                >
                  CREATE COMPANY
                </Button>

              </form>

              {company.error ? (
                <Typography variant="subtitle2" color="error" className="mt-16 text-center">
                  Error: {company.error.message}
                </Typography>
              ) : null}

            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default RegisterCompany;
