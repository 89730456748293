import axios from "axios";
import {openErrorDialog} from 'app/store/actions/fuse';

export const INVITATION_ACCEPT_START = '[INVITATION APP] INVITATION ACCEPT START';
export const INVITATION_ACCEPT_END = '[INVITATION APP] INVITATION ACCEPT END';
export const INVITATION_DECLINE_START = '[INVITATION APP] INVITATION DECLINE START';
export const INVITATION_DECLINE_END = '[INVITATION APP] INVITATION DECLINE END';
export const INVITATION_GET = '[INVITATION APP] INVITATION GET';

export function getPartnershipInvitation(token) {
    return (dispatch) => {
        return axios.get(`/partnerships/invitations/${token}`)
            .then(response => {
                return dispatch({
                    type: INVITATION_GET,
                    payload: response.data,
                });
            })
            .catch(err => {
                return dispatch(openErrorDialog(err.response));
            });
    }
}

export function acceptPartnershipInvitation(token) {
    return (dispatch) => {
        dispatch({
            type: INVITATION_ACCEPT_START
        });
        return axios.patch(`/partnerships/invitations/${token}/accept`, {})
            .then(response => {
                return dispatch({
                    type: INVITATION_ACCEPT_END,
                    payload: response.data,
                    success: true,
                });
            })
            .catch(error => {
                return dispatch({
                    type: INVITATION_ACCEPT_END,
                    payload: {},
                    error: error.response.data.error
                });
            });
    }
}

export function declinePartnershipInvitation(token, reason) {
    return (dispatch) => {
        dispatch({
            type: INVITATION_DECLINE_START
        });
        return axios.patch(`/partnerships/invitations/${token}/decline`, {reason})
            .then(response => {
                return dispatch({
                    type: INVITATION_DECLINE_END,
                    payload: response.data,
                    success: true,
                });
            })
            .catch(error => {
                return dispatch({
                    type: INVITATION_DECLINE_END,
                    error: error.response.data.error
                });
            });
    }
}
