import {User} from '../../@rubix/User';

const navigationConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard',
    auth: [{
      require: () =>
        !User.isPlainMember(User.instance)
    }],
  },
  {
    id: 'calendar',
    title: 'Calendar',
    type: 'item',
    icon: 'today',
    url: '/calendar',
  },
  {
    id: 'production-group',
    title: 'Production',
    type: 'group',
    icon: 'security',
    auth: [{
      require: () =>
        User.can('projects:view')
        || User.can('tasks:view')
        || User.can('productions:view')
        || User.can('files:view')
        || User.can('invoices:view')
        || User.can('damages:view'),
    }],
    children: [
      {
        id: 'projects',
        title: 'Projects',
        type: 'item',
        icon: 'build',
        url: '/projects',
        auth: [{require: () => User.can('projects:view')}],
      },
      {
        id: 'tasks',
        title: 'Tasks',
        type: 'item',
        icon: 'assignment',
        url: '/tasks',
        auth: [{require: () => User.can('tasks:view')}],
      },
      {
        id: 'production',
        title: 'Productions',
        type: 'item',
        icon: 'assignment_turned_in',
        url: '/productions',
        auth: [{require: () => User.can('productions:view')}],
      },
      {
        id: 'files',
        title: 'Files',
        type: 'item',
        icon: 'perm_media',
        url: '/files',
        auth: [{require: () => User.can('files:view')}],
      },
      {
        id: 'invoices',
        title: 'Invoices',
        type: 'item',
        icon: 'receipt',
        url: '/invoices',
        auth: [{require: () => User.can('invoices:view')}],
      },
      {
        id: 'damages',
        title: 'Damages',
        type: 'item',
        icon: 'gavel',
        url: '/damages',
        auth: [{require: () => User.can('damages:view')}],
      },
      // {
      //   id: 'reports',
      //   title: 'Reports',
      //   type: 'collapse',
      //   icon: 'assessment_outlined',
      //   url: '/reports/flight-board',
      //   url: '/reports/project-inspection',
      //   auth: [{ require: () => User.can('projects:view') }],
      //   children: [
      //     {
      //       id: 'flight-board',
      //       title: 'Flight Board',
      //       type: 'item',
      //       icon: 'developer_board',
      //       url: '/reports/flight-board',
      //       auth: [{require: () => User.can('reports:view')}],
      //     },
      //     {
      //       id: 'project-inspection',
      //       title: 'Project Inspection',
      //       type: 'item',
      //       icon: 'bubble_chart',
      //       url: '/reports/project-inspection',
      //       auth: [{require: () => User.can('projects:view')}],
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 'management',
    title: 'Manage',
    type: 'group',
    icon: 'security',
    auth: [{
      require: () =>
        User.can('self:companies:view')
        || User.can('partnerships:manage')
        || User.can('roles:manage')
        || User.can('memberships:manage')
        || User.can('crews:manage')
        || User.can('items:manage')
        || User.can('logs:view'),
    }],
    children: [
      {
        id: 'companies',
        title: 'Companies',
        type: 'item',
        icon: 'domain',
        url: '/companies',
        auth: [{require: () => User.can('self:companies:view')}],
      },
      {
        id: 'clients',
        title: 'Clients',
        type: 'item',
        icon: 'face',
        url: '/clients',
        auth: [{require: () => User.can('partnerships:view')}],
      },
      {
        id: 'subcontractors',
        title: 'Subcontractors',
        type: 'item',
        icon: 'format_paint',
        url: '/subcontractors',
        auth: [{require: () => User.can('partnerships:view')}],
      },
      // {
      //   id: 'roles',
      //   title: 'Roles',
      //   type: 'item',
      //   icon: 'security',
      //   url: '/roles',
      //   auth: [{require: () => User.can('roles:view')}],
      // },
      {
        id: 'members',
        title: 'Members',
        type: 'item',
        icon: 'person',
        url: '/members',
        auth: [{require: () => User.can('memberships:manage')}],
      },
      {
        id: 'crews',
        title: 'Crews',
        type: 'item',
        icon: 'supervisor_account',
        url: '/crews',
        auth: [{require: () => User.can('crews:manage')}],
      },
      {
        id: 'items',
        title: 'Items',
        type: 'item',
        icon: 'timeline',
        url: '/catalog/items',
        auth: [{require: () => User.can('items:manage')}],
      },
      {
        id: 'logs',
        title: 'Daily Log',
        type: 'item',
        icon: 'library_books',
        url: '/logs',
        auth: [{require: () => User.can('logs:view')}],
      },
    ],
  },
  {
    id: 'reports',
    title: 'Reports',
    type: 'group',
    icon: 'assessment_outlined',
    auth: [{
      require: () =>
        !User.isPlainMember(User.instance) &&
        (User.can('projects:view')
        || User.can('invoices:view')
        || User.can('reports:view')),
    }],
    children: [
      {
        id: 'project-production',
        title: 'Project Production',
        type: 'item',
        icon: 'bubble_chart',
        url: '/reports/project-production',
        auth: [{require: () => User.can('projects:view')}],
      },
      {
        id: 'invoices-report',
        title: 'Invoices',
        type: 'item',
        icon: 'receipt',
        url: '/reports/invoices',
        auth: [{require: () => User.can('invoices:view')}],
      },
      {
        id: 'budget-report',
        title: 'Budget',
        type: 'item',
        icon: 'account_balance',
        url: '/reports/budget',
        auth: [{require: () => User.can('invoices:view')}],
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'group',
    icon: 'cog',
    auth: [{
      require: () =>
        User.can('item-categories:manage')
        || User.can('task-types:manage')
        || User.can('damage-types:manage')
        || User.can('safety-authorities:manage'),
    }],
    children: [
      {
        id: 'item_categories',
        title: 'Item Categories',
        type: 'item',
        icon: 'category_outlined',
        url: '/catalog/categories',
        auth: [{require: () => User.can('item-categories:view')}],
      },
      {
        id: 'task_types',
        title: 'Task Types',
        type: 'item',
        icon: 'category_outlined',
        url: '/task-types',
        auth: [{require: () => User.can('task-types:view')}],
      },
      {
        id: 'damage_types',
        title: 'Damage Types',
        type: 'item',
        icon: 'category_outlined',
        url: '/damage-types',
        auth: [{require: () => User.can('damage-types:view')}],
      },
      {
        id: 'safety_authorities',
        title: 'Safety Authorities',
        type: 'item',
        icon: 'category_outlined',
        url: '/safety-authorities',
        auth: [{require: () => User.can('safety-authorities:view')}],
      },
    ],
  },
];

export default navigationConfig;
